@charset "utf-8";

.m_visual_box.mt50{margin-top: 45px;}
.aside_logo img {width:230px; height:auto;}
.header_wrap {width:100%;}
.top_pop_con {width:100%;}
.header_wrap ul.sf-menu {margin-right:39px;}
.mheader_wrap ul.sf-menu {margin-right:39px;}
.pop-layer {width:100%;}
.allmenu1 {padding:5%;}
.m_visual_box {position:relative; height:auto;}
.ch_up{color:#F42222}
.ch_dw{color:#2282F4}
.m_more_btn a {color: #333; font-weight: 400;display: flex;align-items: center;}
.m_more_btn a img{display: inline-block; height: 12px;  margin-left: 5px}
.m_mtit{margin-bottom: 15px; display: flex; justify-content: space-between; align-items: flex-end;}

.m_ldnfix {overflow:hidden;}
.m_ldnfix .m_ldn_m {float:left; margin-left:10px; line-height:22px; letter-spacing:-1px;}
.m_mcontainer5 {background:#f9f9f4; padding:50px 0px 50px 0px;}
.m_mmiddle_area5 {}

/*live spot prices*/
.m_gold_prices_wrap {position:relative; margin:0 auto; z-index:98;}
.m_gold_prices_bg {height:auto;background:#5d5d5d;padding: 20px 25px;}
.m_gold_prices_bg h3 { font-size:16px; color:#fff;font-weight: 700;display: flex;justify-content: space-between; align-items: flex-end;line-height: 30px;}
.m_goldbox_bg {padding:1% 0; background: -webkit-linear-gradient(90deg, rgba(25, 27, 31, 0.95), rgba(64, 65, 67, 0.95)); background: linear-gradient(90deg, rgba(25, 27, 31, 0.95), rgba(64, 65, 67, 0.95));}
.m_gold_prices_box {overflow:hidden;border-radius: 10px;width: 100%; margin: 0 auto;}
.m_gold_prices_box table{background-color: #fff; width: 100%;}
.m_gold_prices_box table tr{border-bottom: 1px solid #ddd;}
.m_gold_prices_box table thead tr{border-bottom: 1px solid #ddd !important; text-align: center;}
.m_gold_prices_box table thead tr:first-child{border-bottom: 1px solid #ccc !important; text-align: center;}
.m_gold_prices_box table tr td{padding: 8px 3px;}
.m_gold_prices_box table tr:last-child{border-bottom: none;}
.m_gold_prices_box table thead tr td{ font-size: 14px; font-weight: 700;box-sizing: content-box;}
.m_gold_prices_box table tr td:first-child{width: 70px; font-size: 13px; font-weight: 700;padding: 8px 10px;}



/*환율*/
.m_usd_area1 ul.m_usd_box {background:#fff;overflow:hidden; border: 1px solid #ccc; border-radius: 10px; padding: 10px 20px; display: flex; justify-content: space-between;}
/* ul.m_usd_box {overflow:hidden;}
ul.m_usd_box li {float:left; margin-left:40px;}
ul.m_usd_box li dl dt {float:left; width:100px;}
ul.m_usd_box li dl dd {float:left; margin-left:40px; width:100px}
ul.m_usd_box li dl dd:nth-child(3) {width:125px;}
ul.m_usd_box li dl dd:last-child {width:270px} */
/*실시간시세*/
.m_tbl_table1 table{width: 100%;}
.m_tbl_table1 thead tr{border-bottom: none}
.m_tbl_table1 thead tr.tit_tr{background-color: #ffd966;}
.m_tbl_table1 thead tr.cot_tr{background-color: #ffc000;}
.m_tbl_table1 thead tr.tit_tr td{border-right: 2px solid #fff; border-bottom: 2px solid #fff;font-size: 12px;font-weight: 700;}
.m_tbl_table1 thead tr.cot_tr td{border: 2px solid #fff; padding:4px 0; font-size: 10px;}
.m_tbl_table1 thead tr td{ padding:7px 0;}
.m_tbl_table1 tr{width: 100%;background-color: #fff; text-align:center; border-bottom:solid 1px #d7d7d7;}
.m_tbl_table1 tr td{font-size: 10px; padding:12px 0; line-height: 15px; }
.m_tbl_table1 tr td li{font-size: 10px;}
.m_even_tr .td1, .odd_tr .td1 {border-right: 1px dashed #ddd;}  
.m_odd_tr .td1 {background-color: #fdfdfd; border-right: 1px dashed #ddd;}
.m_even_tr .td2, .odd_tr .td2 {border-right: 1px dashed #ddd;}
.m_odd_tr .td2 {background-color: #fdfdfd; border-right: 1px dashed #ddd;}
.m_even_tr .td2.lst, .odd_tr .td2.lst {border-right: 1px dashed #ddd;}
.m_even_tr .td3, .odd_tr .td3 {border-right: 1px dashed #ddd;}
.m_odd_tr .td3 {background-color: #f7f6f9; border-right: 1px dashed #ddd;}
.m_even_tr .td1 {background-color: #f4f4f4; border-right: 1px dashed #ddd;}
.m_even_tr .td2 {background-color: #f4f4f4; border-right: 1px dashed #ddd;}
.m_even_tr .td3 {background-color: #eee;}
.m_even_tr .td3:last-child {background-color: #eee;border-right: 0 dashed #ddd;}
.m_odd_tr .td3:last-child {background-color: #f7f6f9;border-right: 0 dashed #ddd;}

/*상품차트 & 시세표들*/
.m_mmiddle_area2 {width:100%; margin:0 auto;}
ul.m_inter_chart1 {overflow:hidden;}
ul.m_inter_chart1 > li {float:left; width:100%;}
.m_prod_list1{ margin-bottom: 10px;width: 100%;}
.m_prod_list1 button {padding:7px 2px;width: 19%; background-color: #ddd;color: #fff; border:none; border-radius: 7px;font-weight: 700;font-size: 12px;}
.m_prod_list1 button:last-child{margin-right: 0;}
.m_prod_list1 button.on{background-color: #ff5f00;}

.m_prod_list2{ margin-bottom: 10px;width: 100%;}
.m_prod_list2 button {padding:7px 2px;width: 19%; background-color: #ddd;color: #fff; border:none; border-radius: 7px;font-weight: 700;font-size: 12px; margin: 2px 2px 2px 2px;}
.m_prod_list2 button:last-child{margin-right: 0;}
.m_prod_list2 button.on{background-color: #ff5f00;}


.m_mcontainer1 table{width: 100%;}
.m_mcontainer1 table.tit_tabl3{ margin-bottom: 5px;}
.m_mcontainer1 table thead tr{background-color: #ffe675 !important; border-bottom: none; font-weight: bold;}
.m_mcontainer1 table tr{ text-align: center;background-color: #fff;border-bottom: 1px solid #ddd;}
.m_mcontainer1 table tr td{padding: 8px 0;}
.m_banner_img1 img{padding-top: 10px;}

.m_mmiddle_area1 {width:100%; margin:0 auto;}
.m_mmiddle_area1 ul.m_pro_money1  {overflow:hidden;width:100%;}
.m_mmiddle_area1 ul.m_pro_money1 > li {width:100%; position:relative; }
.m_mmiddle_area1 ul.m_pro_money1 > li:last-child {margin-bottom:0%;}


.money_box1 {position:relative;}
.m_mmiddle_area1 ul.m_pro_money1 > li .more_btn1 {position:absolute; top:5px; right:5px;}
.m_mmiddle_area1 ul.m_pro_money1 > li .more_btn1 a {color:#666666; font-size:1.143rem; line-height:16px;}
.money_box1 .more_btn1 {position:absolute; top:5px; right:5px;}
.money_box1 .more_btn1 a {color:#666666; line-height:16px;}
.m_gtime {color:#666666; float: right;margin-top: 5px;}

/*주요지수*/
.kindex{background-color: #fff;width: 100%;border-top:2px solid #707070;border-bottom: 2px solid #707070;}
.kindex tr{border-bottom: 1px solid #DDDDDD;}
.kindex tr:last-child{border-bottom: 0px solid #DDDDDD;}
.kindex tr td{padding: 15px 3px;}
.kindex tr td:first-child{width: 30%; padding-left: 10px;}


/*뉴스센터& 공지사항*/
.news-table{background-color: #fff; width: 100%;border-top:2px solid #707070;border-bottom: 2px solid #707070;}
.news-table ul{border-bottom: 1px solid #DDDDDD;}
.news-table ul:last-child{border-bottom: 0px solid #DDDDDD;}
.news-table ul li{padding: 5px 10px;}

.notice-table{background-color: #fff; width: 100%;border-top:2px solid #707070;border-bottom: 2px solid #707070;}
.notice-table ul{border-bottom: 1px solid #DDDDDD;}
.notice-table ul:last-child{border-bottom: 0px solid #DDDDDD;}
.notice-table ul li{padding: 5px 10px;}



.area_layer.layer_install {transition:bottom 0.5s; top:auto;bottom:-100%; display:none;}
.area_layer.layer_install.opened {top:auto;bottom:0; display:block; box-shadow:-6px -3px 6px 0px #929292;
    -webkit-animation: layOpenAni2 .5s cubic-bezier(0.645,0.045,0.355,1.000);
     animation: layOpenAni2 .5s cubic-bezier(0.645,0.045,0.355,1.000); opacity:1;
     -webkit-overflow-scrolling:touch;
}
@-webkit-keyframes layOpenAni2{
    0%{opacity:0; bottom:-100%;}
    100%{opacity:1; bottom:0;}
}
@-moz-keyframes layOpenAni2{
    0%{opacity:0; bottom:-100%;}
    100%{opacity:1; bottom:0;}
}
@keyframes layOpenAni2{
    0%{opacity:0; bottom:-100%;}
    100%{opacity:1; bottom:0;}
}
