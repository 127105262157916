@charset "UTF-8";
.m_clear {
  padding-bottom: 25px;
}
.mheader_wrap {
  background: #2a2a2a;
  padding: 3% 0px;
  position: relative;
}
.mheader_wrap .logo {
  text-align: center;
}
.mheader_wrap .all_box1 {
  position: absolute;
  top: 18px;
  right: 8px;
}
.mheader_wrap .login_box1 {
  position: absolute;
  top: 18px;
  left: 8px;
}
.m_header_wrap {
  background: #2a2a2a;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}
.m_header_wrap .logo {
  text-align: center;
  height: 25px;
}
.m_header_wrap .logo img {
  height: 90%;
}
.m_header_wrap .all_box1 {
  position: absolute;
  top: 18px;
  right: 20px;
}
.m_header_wrap .login_box1 {
  position: absolute;
  top: 7px;
  left: 20px;
}
.m_location_wrap.jbFixed {
  position: fixed;
  top: 94px;
  z-index: 99999;
}
.m_sub_header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999999;
}
.m_gnb_box {
  background: #fff;
}
.m_header_wrap {
  position: fixed;
  top: 0px;
}
.m_header_wrap .logo a {
  height: 25px;
  vertical-align: middle;
}
.m_header_wrap.subh .logo a {
  vertical-align: middle;
  height: 25px;
}
.m_header_wrap.subh .re_btn {
  float: left;
}
.m_header_wrap.subh .re_btn img {
  height: 25px;
  margin-left: 10px;
}
.m_top_pop_con .nbanner {
  background: #565053;
  display: block;
}
.m_top_pop_con .nbanner img {
  width: 80%;
  padding: 0 10%;
}
.m_top_pop h2 {
  display: none;
}
.m_go_btn1 {
  display: none;
}
.m_close1 {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
}
.m_swiper-slide img {
  width: 100%;
  max-height: 185px !important;
}
.m_scontainer {
  padding: 2rem;
}
.m_close_btn img {
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
}

/* gnb */
ul.m_mgnb_wrap {
  overflow: hidden;
  margin: 20px 0px;
}
ul.m_mgnb_wrap li {
  float: left;
  width: 20%;
}
ul.m_mgnb_wrap li a {
  display: block;
  text-align: center;
  font-size: 1.2rem;
}
.m_gnb_box {
  padding: 15px 7px;
  overflow-y: hidden;
  overflow-x: auto;
}
ul.m_gnb_wrap {
  display: table;
  white-space: nowrap;
}
ul.m_gnb_wrap li {
  padding: 0 20px;
  display: table-cell;
}
ul.m_gnb_wrap li a {
  display: block;
  text-align: center;
  font-size: 1.2rem;
}
.m_svisual_box {
  margin-top: 95px;
}
.m_visual_box {
  margin-top: 110px;
}
/* side */

/* 아코디언 */
/* .lnb_list.lngb1 {width:100%; position:relative;}
.lnb_list.lngb1 ul > li {}
.lnb_list.lngb1 ul > li > a {font-size:1.143rem;border-bottom:solid 1px #e6e6e6;padding:10px 10px; color:#232323;}
.lnb_list.lngb1 ul > li a.minus {color:#de772f;background:url(../img/common/close.png) 90% 50% no-repeat;border-bottom:solid 1px #e6e6e6;}
.lnb_list.lngb1 ul > li a.plus {background:url(../img/common/open.png) 90% 50% no-repeat;}
.lnb_list.lngb1 ul > li ul.dep2 {background:url(../img/common/mgray.png) left top repeat-y; margin:0.7143rem 0 0.7143rem 0.7143rem;}
.lnb_list.lngb1 ul > li ul.dep2 li a {border-bottom:0px; display:block; padding:0 0 0.7143rem 0.7143rem; color:#888888;}
.lnb_list.lngb1 ul > li ul.dep2 li a.on { background:url(../img/common/mblack.png) left top no-repeat; font-weight:bold; color:#232323}
.lnb_list.lngb1 ul > li ul.dep2 li a:hover { background:url(../img/common/mblack.png) left top no-repeat; font-weight:bold; color:#232323}
.lnb_list.lngb1 ul > li ul.dep2:last-child {margin-bottom:0px;}
.lnb_list.lngb1 ul > li ul.dep2 li:last-child a {padding-bottom:0px;} */
.m_side_banner {
  position: absolute;
  bottom: 7%;
  right: 0px;
  background: #565053;
  padding: 20px 10%;
  width: 80%;
  text-align: center;
}
.m_side_banner a img {
  width: 280px;
}
.m_infor_wrap1 {
  background: #242424;
  padding: 30px 25px;
}
a.depth2 {
  display: block;
}

/* 서브 레이아웃 */
/* .sub_mvisual1_bg {width:90%; height:180px; display:flex; align-items: center; padding-left:10%; background-size:cover; background:#f0f0f1 url(/img/sub/mvisual1_1.png) center top no-repeat; }
.sub_visual1_bg {width:90%; height:180px; display:flex; align-items: center; padding-left:10%; background-size:cover; background:#f0f0f1 url(/img/sub/visual1_1.png) center top no-repeat; }
.sub_visual2_bg {width:90%; height:180px; display:flex; align-items: center; padding-left:10%; background-size:cover; background:#f0f0f1 url(/img/sub/visual2_1.png) center top no-repeat; }
.sub_visual3_bg {width:90%; height:180px; display:flex; align-items: center; padding-left:10%; background-size:cover; background:#f0f0f1 url(/img/sub/visual3_1.png) center top no-repeat; }
.sub_visual4_bg {width:90%; height:180px; display:flex; align-items: center; padding-left:10%; background-size:cover; background:#f0f0f1 url(/img/sub/visual4_1.png) center top no-repeat; }
.sub_visual5_bg {width:90%; height:180px; display:flex; align-items: center; padding-left:10%; background-size:cover; background:#f0f0f1 url(/img/sub/visual5_1.png) center top no-repeat; } */
.m_price_titl1 dl dt {
  font-size: 2.857rem;
  line-height: 2.857rem;
  margin-bottom: 5%;
  font-weight: bold;
}
.m_price_titl1 dl dd {
  font-size: 2rem;
  line-height: 2rem;
}
.m_location_wrap {
  width: 100%;
}
.m_location_box {
  background: #ff5f00;
  height: 50px;
  line-height: 50px;
  box-shadow: 5px 5px 10px #f4ceb8;
}
.m_location_box dl dt.home {
  width: 10%;
  border-right: solid 1px #e97d3c;
  text-align: center;
}
.m_location_box dl dt.home a {
  width: 100%;
  height: 35px;
  padding-top: 15px;
  display: block;
}
.m_location_box dl dt,
.location_box dl dd {
  float: left;
}
.m_location_box dl dd {
  width: 40%;
  height: 50px;
  border-right: solid 1px #e97d3c;
  text-align: center;
  position: relative;
}
.m_location_box dl dd button.dropbtn {
  border: 0px;
  background: none;
  display: block;
  text-align: left;
  padding-left: 20px;
  cursor: pointer;
  color: #fff;
  height: 50px;
  line-height: 50px;
}
.m_location_box dl dd {
  background: url(../img/sub/sel_arrow1.png) 90% 50% no-repeat;
}
.m_dropdown-content {
  display: none;
  position: absolute;
}
.m_dropdown-content.show {
  display: block;
  background: #fff;
  width: 180px;
  box-shadow: 3px 3px 3px #bababa;
  border: solid 1px #f0f0f1;
  z-index: 99;
}
.m_dropdown-content.show a {
  width: 180px;
  text-align: left;
  display: block;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  color: #242424;
}

.m_sub_visual4_bg dl.price_titl1 dt {
  color: #fff;
}
.m_sub_visual4_bg dl.price_titl1 dd {
  color: #fff;
}

.m_subvisual_wrap {
  width: 100%;
  height: 200px;
  margin: 0 auto;
}
dl.m_sub_vtitl1 {
  padding-top: 110px;
  padding-bottom: 80px;
}
dl.m_sub_vtitl1 dt {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: bold;
}
dl.m_sub_vtitl1 dd {
  font-size: 30px;
  line-height: 30px;
}
dl.m_sub_vtitl2 {
  padding-top: 110px;
  padding-bottom: 80px;
}
dl.m_sub_vtitl2 dt {
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: bold;
}
dl.m_sub_vtitl2 dd {
  font-size: 30px;
  line-height: 30px;
  color: #fff;
}

.m_scontainer1 {
  background: #f5f5f5;
  width: 100%;
  padding: 2% 0px 2% 0px;
}
.m_scontent_area1 {
  width: 98%;
  padding: 0 1%;
  margin: 0 auto;
}
.m_scontent_area2 {
  width: 98%;
  padding: 0 1%;
  margin: 120px auto 0 auto;
}

.m_stable_type1 {
  border-top: solid 2px #eeeeee;
  overflow: hidden;
}
.m_stable_type1 table {
  width: 100%;
}
.m_stable_type1 table thead tr th {
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  padding: 20px 0px;
  background: #fffbf9;
}
.m_stable_type1 table tbody tr th {
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  color: #000000;
  padding: 20px 0px;
}
.m_stable_type1 table tbody tr th.tal {
  padding: 0px 10px;
  text-align: left;
}
.m_stable_type1 table tbody tr th.tar {
  padding: 0px 10px;
  text-align: right;
}
.m_stable_type1 table tbody tr td {
  padding: 20px 0px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.m_stable_type1 table tbody tr td.tal {
  padding: 0px 10px;
  text-align: left;
}
.m_stable_type1 table tbody tr td.tar {
  padding: 0px 10px;
  text-align: right;
}
.m_stable_type1.bth1 table thead th.bdline {
  border-left: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
}
.m_stable_type1.bth1 table tr th {
  background: #fafafa;
}

.m_stb_typ {
  width: 100%;
  margin-bottom: 1.2rem;
}
.m_cltable_type1 {
  border-top: solid 2px #eeeeee;
  width: 100%;
}
.m_cltable_type1 table {
  width: 100%;
}
.m_cltable_type1 table thead tr th {
  font-size: 16px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  padding: 20px 0px;
  background: #fffbf9;
}
.m_cltable_type1 table tbody tr th {
  background: #fafafa;
  font-size: 16px;
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  color: #000000;
  padding: 20px 0px;
}
.m_cltable_type1 table tbody tr td {
  font-size: 16px;
  padding: 20px 0px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}

.m_ctable_type1 {
  border-top: solid 2px #eeeeee;
  overflow: hidden;
  box-shadow: 1px 2px 10px #bebebe;
}
.m_ctable_type1 table {
  width: 100%;
}
.m_ctable_type1 table thead tr th {
  font-size: 16px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  padding: 20px 0px;
  background: #fffbf9;
}
.m_ctable_type1 table tbody tr th {
  background: #fafafa;
}
.m_ctable_type1 table tbody tr td.hd1 {
  height: 104px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.m_ctable_type1 table tbody tr td.hd2 {
  height: 88px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.m_ctable_type1 table tbody tr td.hd3 {
  height: 61px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.m_ctable_type1 table tbody tr td.hd4 {
  height: 72px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.m_ctable_type1 table tbody tr td.hd5 {
  height: 104px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.m_ctable_type1 table tbody tr td.hd6 {
  height: 60px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}

/* 매장 전화번호 하단 공통 */
.m_main_cs_cont {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 98%;
  padding: 0 1%;
}
.m_main_cs_cont .main_title {
  margin-bottom: 15px;
}
.m_main_cs_cont .main_cont_inner {
  padding: 0 15px;
}
.m_main_cs_list {
  margin: 0 -2px;
}
.m_main_cs_list .item {
  float: left;
  width: 50%;
}
.m_main_cs_list .item_inner {
  padding: 15px;
  margin: 0 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* .main_cs_list .item_inner {line-height: 1; position: relative; background-image: url(../img/common/ico_tel.png); background-repeat: no-repeat; background-position: right 15px top 15px; background-size: 17px;} */
.m_main_cs_list .item_inner .tit {
  font-size: 0.8rem;
  color: #333;
}
.m_main_cs_list .item_inner .tel {
  margin: 6px 0 10px;
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}
.m_main_cs_list .item_inner .info {
  font-size: 0.7rem;
  color: #999;
}
.m_main_cs_list .item_inner .link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.m_main_cs_list .item_inner .link a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
}

.m_dia_pic {
  margin-bottom: 1rem;
}
.m_ftTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
}
.m_ftTop img {
  width: 42px;
  height: 42px;
}

.m_mypage_table1 {
  border-top: solid 2px #2a2a2a;
  overflow: hidden;
  margin-bottom: 1.2rem;
}
.m_mypage_table1 table {
  width: 100%;
}
.m_mypage_table1 table tbody tr th {
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  color: #000000;
  padding: 20px 0px;
  font-size: 16px;
}
.m_mypage_table1 table tbody tr th.tal {
  padding: 0px 10px;
  text-align: left;
}
.m_mypage_table1 table tbody tr th.tar {
  padding: 0px 10px;
  text-align: right;
}
.m_mypage_table1 table tbody tr td {
  padding: 20px 0px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.m_mypage_table1 table tbody tr td.tal {
  padding: 0px 10px;
  text-align: left;
}
.m_mypage_table1 table tbody tr td.tar {
  padding: 0px 10px;
  text-align: right;
}
.m_mypage_table1 table tbody tr td input.input_mtype {
  width: 100%;
  height: 26px;
  border: solid 1px #eeeeee;
  width: 88%;
  border-radius: 5px;
}

.m_floatingBtnWrap {
  position: fixed;
  top: 0px;
  left: 0px;
  display: none;
  font-size: 0;
  z-index: 99999;
  width: 100%;
}
.m_floatingBtnWrap div.flat_wrap {
  display: block;
  width: 96%;
  padding: 10px 2%;
  height: 45px;
  background-color: #2b2b2b;
  color: #fff;
  z-index: 99999;
  background: #fff;
  box-shadow: 1px 1px 5px #333;
  position: relative;
}
.m_floatingBtnWrap div.flat_wrap p {
  color: #151b21;
  font-size: 1rem;
  text-align: center;
}
.m_floatingBtnWrap div.flat_wrap p.first {
  margin-bottom: 5px;
}
.m_all_box2 {
  position: absolute;
  top: 15px;
  right: 10px;
}
.m_login_box2 {
  position: absolute;
  top: 13px;
  left: 10px;
}
.m_floatingBtnWrap div.flat_wrap span.rd3 {
  color: #ff0000;
  font-weight: bold;
}

/*푸터*/

.m_infor_box1 {
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
}
.m_infor_box1 ul {
  overflow: hidden;
  width: 100%;
}
.m_infor_box1 ul li {
  float: left;
  width: auto;
  text-align: left;
}
.m_infor_box1 ul li a {
  font-size: 12px;
  color: #ffffff;
  margin-right: 15px;
}
.m_footer_info span {
  font-size: 15px;
  font-weight: 700;
}
.m_footer_info img {
  margin-right: 3px;
}

#footer {
  background: #000000;
}
.m_footer_wrap {
  padding: 30px 25px 90px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.m_footer_logo {
  float: left;
}
.m_footer_info {
  float: left;
}
.m_footer_info address {
  color: #fff;
}
.m_footer_info p {
  color: #fff;
  line-height: 20px;
}
.m_footer_info p.mb20 {
  margin-bottom: 20px;
}

/* 하단 pc화면 바로가기 */
.m_pc_go {
  background: #000;
  padding: 10px 0px;
  width: 100%;
  text-align: center;
}
.m_pc_go a {
  color: #fff;
  display: block;
  text-align: center;
  line-height: 28px;
}

/* 딤드 팝업 */
.m_layer-wrap {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.m_layer-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
.m_pop-layer {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: auto;
  background-color: #fff;
  z-index: 10;
}
.m_pop-layer .pop-container {
  position: relative;
}
a.m_btn-layerClose {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
}

.m_pop-layer2 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
  vertical-align: middle;
  height: auto;
  background-color: #fff;
  z-index: 10;
}
.m_pop-layer2 .m_pop_titl1 {
  position: relative;
  height: 60px;
  line-height: 60px;
  height: 60px;
  background: #2a2a2a;
  color: #fff;
}
.m_pop-layer2 .m_pop_titl1 h4 {
  font-size: 1, 5rem;
  line-height: 60px;
  margin-left: 20px;
}

.m_layer-wrap1 {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.m_layer-wrap1:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
a.m_btn-layerClose {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
}

/*하단 메뉴 바*/
.m_common_bt {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 9999;
}
.m_common_bt ul {
  font-size: 14px;
  display: flex;
  background-color: #52555c;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  text-align: center;
}
.m_common_bt ul li {
  width: 28%;
}
.m_common_bt ul li a {
  color: #fff;
}
.m_common_bt ul li:first-child::after {
  background-color: #acacac;
  width: 1px;
  height: 20px;
  line-height: 10px;
  content: "";
  float: right;
}
.m_common_bt ul li:nth-child(3)::before {
  background-color: #acacac;
  width: 1px;
  height: 20px;
  line-height: 10px;
  content: "";
  float: left;
}
.m_common_bt ul li.menu {
  width: 16% !important;
  background-color: #3c3f46;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m_common_bt ul li img {
  height: 14px;
}
.m_common_bt .on a {
  color: #ff5f00;
  font-weight: 700;
}

/*메뉴탭페이지*/
.m_menu_wrap {
  display: flex;
  height: 100%;
  font-size: 14px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.m_menu_wrap .rt_menu ul {
  padding-bottom: 10px;
}
.m_lf_menu {
  background-color: #ff5f00;
  padding: 15px 20px;
  color: #fff;
  font-weight: 700;
}
.m_rt_menu {
  background-color: #fff;
  padding: 15px 20px;
  width: calc(100% - 120.46px);
}
.m_menu_tit {
  color: #ff5f00;
  font-weight: 700;
}
li.m_menu_tit::after {
  background-color: #ddd;
  height: 1px;
  width: 100%;
  margin: 5px 0;
  content: "";
  display: block;
}
.m_rt_menu li {
  padding-bottom: 10px;
  font-size: 14px;
}
.m_lf_menu li {
  font-size: 14px;
}
li.m_menu_tit {
  padding-bottom: 0;
}
.m_lf_menu ul:first-child {
  height: 191px;
}
.m_lf_menu ul:nth-child(2) {
  height: 71px;
}

/*매매프리미엄*/
.m_visual_sub {
}
/* .visual_sub .visual_header{padding: 45px 30px 0; background: url(/img/sub_visual1.png) 100% 100% no-repeat; background-size: contain; height: 100px;} */
.m_isual_sub .m_visual_header h3 {
  font-size: 16px;
  font-weight: 700;
  padding-top: 25px;
}
.m_visual_sub .m_visual_header p {
  font-size: 16px;
  font-weight: 500;
}
.m_visual_sub .m_visual_tab {
  background-color: #ff5f00;
  color: #fff;
  margin-top: 45px;
}
.m_visual_sub .m_visual_tab ul {
  display: flex;
}
.m_visual_tab ul li {
  width: 50%;
  height: 50px;
  border-right: solid 1px #e97d3c;
  text-align: center;
  position: relative;
}
.m_visual_tab ul li button.m_dropbtn {
  border: 0px;
  background: none;
  display: block;
  text-align: left;
  width: 100%;
  padding-left: 20px;
  cursor: pointer;
  color: #fff;
  height: 50px;
  line-height: 50px;
}
.m_visual_tab ul li:after {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 23px;
  height: 23px;
  background: url(../img/sub/sel_arrow1.png) center 50% no-repeat;
}
.m_visual_tab ul li.sale:after {
  display: none;
}
/*.visual_sub .visual_tab ul li {padding: 10px 30px; font-size: 14px;width: 50%;border-right: 1px solid #FFC8A7;line-height: 20px;height: 20px;display: flex; justify-content: space-between;}
.visual_sub .visual_tab ul li::after{content: "";background:url(/img/drop_dw.png); background-size: 20px 20px; display: inline-block;height: 20px; width: 20px;}
*/

.m_pre_wrap {
  padding: 25px 25px 40px;
  background: #f9f9f4;
}
.m_pre_wrap .pre_tit {
  margin-bottom: 20px;
}
.m_pre_wrap .pre_tit h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.m_pre_wrap .pre_tit p {
  text-align: center;
  color: #ff5f00;
  font-size: 14px;
  font-weight: 500;
}
.m_tbl_table2 ul {
  display: flex;
  text-align: center;
}
.m_tbl_bs {
  padding: 10px 10px;
  width: 50%;
  border: 1px solid #dbdada;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
}
.m_tbl_bs.active {
  background-color: #ffe675;
  border: none;
}
.m_tbl_pro {
  padding: 5px 2px;
  width: 25%;
  border: 1px solid #dbdada;
  border-radius: 5px;
  margin: 10px 5px 10px;
  color: #fff;
  background-color: #dddddd;
  font-weight: 700;
  cursor: pointer;
}
.m_tbl_pro.active {
  background-color: #ff5f00;
  border-color: #ff5f00;
}
.m_tbl_tit {
  padding: 5px 0px;
  width: 25%;
  font-weight: 700;
  letter-spacing: -0.5px;
}
.m_tbl_cot {
  padding: 5px 0px;
  width: 25%;
}
.m_tbl_cot_ul {
  border-bottom: 1px solid #dbdada;
}
.m_tbl_cot_ul:nth-child(even) {
  background-color: #fff;
}
/*매매프리미엄 하단표 (국제가,환율,기준가.기준가3.75)*/
table.m_tbl_tb2 {
  background-color: #dbdada;
  height: 50px;
  border-collapse: inherit;
  width: 100%;
  text-align: center;
}
table.m_tbl_tb2 tr {
  border: none;
  height: 25px;
}
table.m_tbl_tb2 tr td:nth-child(even) {
  background: #fff;
  box-sizing: border-box;
  border: 3px solid #dbdada;
  border-radius: 7px;
}

/*시세조회*/
/*국내시세*/
.m_price_wrap {
  padding: 25px 25px 40px;
}
.m_price_wrap .price_tit::after {
  content: "";
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin: 25px 0;
  display: block;
}
.m_price_wrap .price_tit h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.m_price_wrap .price_tit p {
  text-align: center;
  color: #ff5f00;
  font-size: 14px;
  font-weight: 500;
}

.m_tab_buttons.bhistory .dptab.active {
  border: solid 1px #ff5f00;
  background-color: #fff;
  color: #ff5f00;
}
.m_tab_buttons.bhistory .dptab {
  border: 1px solid #eee;
  background-color: #f5f5f5;
}
.m_tab_buttons.bhistory button {
  width: 25%;
  padding: 10px 0;
  float: left;
}

.m_tabs.price {
  border: none;
  background: #fff;
}
.m_tabs.price .m_term {
  display: flex;
}
.m_tabs.price .m_term li {
  margin-right: 10px;
}
.m_tabs.price .donYN {
  display: flex;
}
.m_tabs.price .donYN li {
  margin-left: 10px;
}
.m_tab_top {
  display: flex;
  justify-content: space-between;
}
.m_date_in {
  width: 100%;
}
.m_data_day {
  border: 1px solid #ddd;
  width: 40%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.m_last {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.m_search_btn1 {
  border: 1px solid #ddd;
  padding: 5px 0;
  width: 10%;
  text-align: center;
  border-radius: 5px;
  float: right;
  color: #333;
}
.m_inter_chart {
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
}
.m_mtit .qu_day {
  font-weight: 400;
}
.m_quo_list {
  border: 1px solid #eee;
  background-color: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-flow: wrap row;
}
.m_quo_list li {
  width: 50%;
  border-right: 1px solid #eee;
  border-bottom: 1px solid#ddd;
  padding: 3%;
}
.m_quo_list li:nth-child(even) {
  border-right: 0px;
}
.m_quo_list li:nth-child(3) {
  border-bottom: 0px;
}
.m_quo_list li:nth-child(4) {
  border-bottom: 0px;
}
.m_quo_list li dt {
  color: #ff5f00;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
}
.m_quo_list li dd {
  display: flex;
  justify-content: space-between;
}
.m_date_btn {
  display: flex;
  justify-content: flex-end;
}
.m_data_day2 {
  border: 1px solid#ddd;
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 3px;
}
.m_search_btn2 {
  border: 1px solid #ddd;
  padding: 3px 0;
  width: 20%;
  text-align: center;
  border-radius: 5px;
  float: right;
  color: #333;
}
.m_tal40 {
  text-align: center;
}
.m_unit10 {
  font-size: 10px;
}

/*국제시세*/
.m_tab_buttons.internalprice .dptab.active {
  border: solid 1px #ff5f00;
  background-color: #fff;
  color: #ff5f00;
}
.m_tab_buttons.internalprice .dptab {
  border: 1px solid #eee;
  background-color: #f5f5f5;
  padding: 0;
}
.m_tab_buttons.internalprice button {
  width: 20%;
  height: 35px;
  float: left;
}
.m_price_list {
  border: 1px solid #eee;
  background-color: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-flow: wrap row;
  margin-bottom: 10px;
}
.m_price_list li {
  width: 50%;
  border-right: 1px solid #eee;
  border-bottom: 1px solid#ddd;
  padding: 10px;
}
.m_price_list li:nth-child(even) {
  border-right: 0px;
}
.m_price_list li:nth-child(3) {
  border-bottom: 0px;
}
.m_price_list li:nth-child(4) {
  border-bottom: 0px;
}
.m_price_list li dt {
  color: #ff5f00;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
}
.m_price_list li dd {
  display: flex;
  justify-content: space-between;
}

/*런던픽스*/
.m_lf_table {
  text-align: center;
  border-top: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
.m_lf_table:last-child {
  margin-bottom: 0;
}
.m_lf_table.top {
  text-align: center;
  border-top: 2px solid #333;
  margin-bottom: 10px;
  border-bottom: 1px solid #828282;
}
.m_lf_table thead {
  border-top: 2px solid;
  border-bottom: #828282;
}
.m_lf_table thead tr:first-child td {
  padding: 10px 0 0 0;
  font-size: 17px;
  font-weight: 700;
}
.m_lf_table thead tr:first-child td:first-child {
  padding: 10px;
}
.m_lf_table thead tr:last-child td {
  padding: 3px 0 10px 0;
  font-size: 17px;
  font-weight: 500;
}
.m_lf_table tr td {
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.m_lf_table tr td.lf_pk {
  background-color: #fff8f4;
}
.m_lf_table tr td.lf_date {
  background-color: #efefef;
}

.m_londonfix_cont select {
  border: 1px solid #d5d5d5;
  padding: 3px 0px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  float: right;
  width: 30%;
}

/*다이아몬드시세*/
/* 다이아몬드 시세 */

.m_diamaond_top p {
  text-align: left;
  font-size: 14px;
  color: #999;
}
.m_diamond_tb {
  width: 100%;
  text-align: center;
  border-top: 2px solid #333;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}
.m_diamond_tb thead {
  border-top: 2px solid #333;
  border-bottom: 1px solid #ddd;
}
.m_diamond_tb thead td span {
  color: #999;
}
.m_diamond_tb td {
  padding: 10px 0;
}
.m_diamond_tb tbody tr td:first-child {
  text-align: right;
  color: #999;
}
td.m_soldout span {
  background-color: #ff5f00;
  padding: 3px 5px;
  color: #fff;
}
tr.m_soldout {
  position: relative;
}
tr.m_soldout td::after {
  content: "";
  display: block;
  border-bottom: 1px solid #ff5f00;
  width: 100%;
  position: absolute;
  left: 0;
  top: 65%;
}

.m_diamaond_process_box {
  border-top: 2px solid #333;
}
.m_diamaond_process_box li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.m_diamaond_process_box .item strong {
  font-size: 14px;
}
.m_diamaond_process_box .item span {
  font-size: 14px;
}
.m_diamaond_process_box .item p {
  color: #999;
}

/*유가증권시세*/
.m_scprice {
  display: flex;
  flex-direction: column;
}
.m_graph_dt_check {
  align-items: center;
  display: flex;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.m_graph_dt_check li {
  align-items: center;
  display: flex;
  margin-right: 5px;
}
.m_nptable {
  width: 100%;
}
.m_nptable td {
  color: #666;
}
.m_nptable td,
.nptable th {
  border: 1px solid #ddd;
  padding: 10px 0;
  text-align: center;
}
.m_nptable .color_price td {
  background-color: #ffe9da;
}
.m_sgtable_top {
  margin-bottom: 10px;
}
.m_sgtable_top b {
  color: #ff5f00;
}
.m_sgtable {
  width: 100%;
}
.m_sgtable thead {
  background-color: #f5f5f5;
}
.m_sgtable tr {
  border-bottom: 1px solid #efefef;
}
.m_sgtable td {
  text-align: center;
  padding: 10px 0;
}

/*회사소개*/
.m_company_wrap {
  padding: 25px 25px 40px;
}
.m_company_wrap .company_tit::after {
  content: "";
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin: 20px 0;
  display: block;
}
.m_company_wrap .company_tit h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.m_company_wrap .company_tit p {
  text-align: center;
  color: #ff5f00;
  font-size: 14px;
  font-weight: 500;
}
.m_ceo_name {
  text-align: end;
  font-size: 15px;
  margin-top: 5px;
}
