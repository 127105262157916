@charset "UTF-8";
/* 웹폰트 */


@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff')
  ,url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');}

  body, h1, h2, h3, h4, h5, h6, input, textarea, select { font-family: 'Noto Sans KR', sans-serif; }

/* html5 reset add */
html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {margin: 0; padding: 0; border: 0; outline: 0; font-size:12px;}
a{margin: 0; padding: 0; color: #000;}

/* default css */
html, body {width:100%;height:100%;margin:0;padding:0; font-family:"Noto Sans KR"; font-weight:400; color: #333;}
h1, h2, h3, h4, h5, h6 {text-align:left;font-weight:normal;margin:0; padding:0; border:0; font-smoothing:antialiased; -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
h4{font-size: 16px; font-weight: 700;}
table {table-layout:fixed; margin:0; padding:0; border:0; border-collapse:collapse;}
td, th {empty-cells:show;}
form, img, fieldset, legend, input, select, textarea, div, p, dl, dt, dd, ul, ol, li, fieldset, blockquote, th, td, iframe {margin:0;padding:0;border:0;}
ul, ol, li {list-style:none;}
a {color:#333;}
a:link, a:visited {text-decoration:none;}
a:hover, a:active {box-sizing:border-box;}
em, address {font-style:normal;}
textarea {overflow:auto;}
hr, legend, caption, .hide {position:absolute; top:0; left:0; width:0; height:0; overflow:hidden; font-size:0; line-height:0; margin-left:-55555px;}
caption, th {text-align:left;}
td {vertical-align:middle;}
img {vertical-align:top;}
.m_container_wrap{padding:25px;}
.ft8{font-size: 8px;}
.hidden {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0, 0, 0, 0);
}
.skip_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.skip_link {
    position: absolute;
    top: -100px;
    left: 0;
    z-index: 5000;
    width: 100%;
    padding: 10px 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 15px;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    text-decoration: none;
}
button:focus {border:0px;outline:0px;}

.skip_link:focus, .skip_link:hover, .skip_link:active {top: 0;}

select.select_type1 {
  min-width: 125px;
  padding:.3em .5em;
  font-family: inherit;
  /* background: url(../img/common/sel_arrow.png) no-repeat 95% 50%; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #dcdcde;
  border-radius: 0px;
}
select.m_select_type2 {
  padding:5px 30px;
  font-family: inherit;
  /* background:#fff url(../img/common/sel_arrow3.png) no-repeat 90% 50%; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #dcdcde;
  border-radius: 0px;
  color:#000;
  font-size:12px;
  float: right;
}
select.select_type3 {
  min-width:80px;
  padding:.3em 1.2em;
  font-family: inherit;
  /* background:#fff url(../img/common/sel_arrow1.png) no-repeat 90% 50%; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #dcdcde;
  border-radius: 0px;
  color:#000;
  font-size:1rem;
}
select.select_type3 {
  min-width:50px;
  padding:.3em 1.4em;
  font-family: inherit;
  /* background:#fff url(../img/common/sel_arrow1.png) no-repeat 90% 50%; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #dcdcde;
  border-radius: 0px;
  color:#000;
  font-size:1rem;
}

select::-ms-expand {/* for IE 11 */  display: none;}

input.input_type01 {height:42px; line-height:42px; padding:0 10px; background:#fff; border:1px solid #d7d7d7; color:#201f2d; font-size:22px; }
input.input_type02 {height:37px; line-height:37px; padding:0 10px; background:#fff; border:1px solid #d7d7d7; color:#201f2d; font-size:22px; }
input.input_type03 {height:50px; line-height:50px; padding:0 10px; background:#fff; border:1px solid #d7d7d7; color:#201f2d; font-size:16px; }
input.input_type04 {height:37px; line-height:37px; padding:0 10px; width:70%; background:#fff; border:1px solid #d7d7d7; color:#201f2d; font-size:22px; }
textarea.txt_box1 {width:90%; height:100px; overflow-y:auto; padding:0 5%; margin:10px 0px; border:1px solid #d7d7d7;}
textarea.txt_box2 {width:80%; height:100px; overflow-y:auto; padding:0 5%; margin:10px 0px; border:1px solid #d7d7d7;}
/* 손가락 표시 */
.click {cursor:pointer;}


/* -- margin -- */
/* top */
.mt5  {margin-top:5px;}
.mt10 {margin-top:10px;}
.mt15 {margin-top:15px;}
.mt20 {margin-top:20px;}
.mt25 {margin-top:25px;}
.mt30 {margin-top:30px;}
.mt40 {margin-top:40px;}
.mt50 {margin-top:50px;}
.mt60 {margin-top:60px;}
.mt70 {margin-top:70px;}
.mt80 {margin-top:80px;}
.mt90 {margin-top:90px;}
.mt100 {margin-top:100px;}

/* bottom */
.mb5  {margin-bottom:5px;}
.mb10 {margin-bottom:10px;}
.mb15 {margin-bottom:15px;}
.mb20 {margin-bottom:20px;}
.mb30 {margin-bottom:30px;}
.mb40 {margin-bottom:40px;}
.mb50 {margin-bottom:50px;}
.mb60 {margin-bottom:60px;}
.mb70 {margin-bottom:70px;}
.mb80 {margin-bottom:80px;}
.mb100 {margin-bottom:100px;}
.mb150 {margin-bottom:150px;}

/* left */
.ml0 {margin-left:0px;}
.ml5  {margin-left:5px;}
.ml10 {margin-left:10px;}
.ml15 {margin-left:15px;}
.ml20 {margin-left:20px;}
.ml25 {margin-left:25px;}
.ml30 {margin-left:30px;}
.ml35 {margin-left:35px;}
.ml40 {margin-left:40px;}
.ml50 {margin-left:50px;}
.ml70 {margin-left:70px;}
.ml80 {margin-left:80px;}
.ml100 {margin-left:100px;}

/* right */
.mr5  {margin-right:5px;}
.mr10 {margin-right:10px;}
.mr15 {margin-right:15px;}
.mr20 {margin-right:20px;}
.mr40 {margin-right:40px;}

.ms10 {margin-left:10px; margin-right:10px; font-size:14px;}

/* -- padding -- */
/* top */
.pt5  {padding-top:5px;}
.pt10 {padding-top:10px;}
.pt15 {padding-top:15px;}
.pt20 {padding-top:20px;}
.pt25 {padding-top:25px;}

/* bottom */
.pb5  {padding-bottom:5px;}
.pb10 {padding-bottom:10px;}
.pb15 {padding-bottom:15px;}
.pb20 {padding-bottom:20px;}
.pb25 {padding-bottom:25px;}

/* left */
.pl5  {padding-left:5px;}
.pl10 {padding-left:10px;}
.pl15 {padding-left:15px;}
.pl20 {padding-left:20px;}
.pl25 {padding-left:25px;}
.pl30 {padding-left:30px;}
.pl40 {padding-left:40px;}
.pl50 {padding-left:50px;}
.pl70 {padding-left:70px;}
.pl100 {padding-left:100px;}
.pl120 {padding-left:120px;}
.pl140 {padding-left:140px;}
.pl160 {padding-left:160px;}
.pl180 {padding-left:180px;}

/* right */
.pr5  {padding-right:5px;}
.pr10 {padding-right:10px;}
.pr15 {padding-right:15px;}
.pr20 {padding-right:20px;}
.pr25 {padding-right:25px;}
.pr30 {padding-right:30px;}
.pr40 {padding-right:40px;}
.pr60 {padding-right:60px;}


/* -- vertical-align -- */
.vtaT {vertical-align:top;}
.vtaM {vertical-align:middle;}
.vtaB {vertical-align:bottom;}
.mg_a {margin:0 auto; }

/* -- text-align  -- */
.tar {text-align:right;}
.tac {text-align:center;}
.tal {text-align:left;}


/* -- line-height -- */
.lh18 {line-height:18px;}
.lh20 {line-height:20px;}
.lh22 {line-height:22px;}
.lh24 {line-height:24px;}
.lh26 {line-height:26px;}
.lh28 {line-height:28px;}
.lh30 {line-height:30px;}
.lh44 {line-height:44px;}

/* -- font -- */
.fs8 {font-size:8px;}
.fs11 {font-size:11px;}
.fs12 {font-size:12px;}
.fs14 {font-size:14px; line-height:16px;}
.fs14_b {font-size:14px; line-height:16px; font-weight:bold;}
.fs15 {font-size:15px; line-height:16px;}
.fs15_b {font-size:15px; line-height:16px; font-weight:bold;}
.fs16 {font-size:16px; line-height:16px;}
.fs16_b {font-size:16px; line-height:16px; font-weight:bold;}
.fs18 {font-size:18px; line-height:18px;}
.fs18_b {font-size:18px; line-height:18px; font-weight:bold;}

.fwn {font-weight:normal;}
.fwb {font-weight:bold;}


/* -- letter-spacing --*/
.letspa {letter-spacing:-1px;}


/* -- border -- */
.bdrT {border-top:0px; }
.bdrL {border-left:0px; }
.bdrR {border-right:0px; }
.bdrB {border-bottom:0px; }

.b_none {background:none }

/* -- clear -- */
.m_clear:after {content:"."; display:block; height:0; font-size:0; clear:both; visibility:hidden;}
.m_clear {clear:both;}

/* -- float -- */
.fl_l {float:left;}
.fl_r {float:right;}
.ov_h {overflow:hidden;}

/* -- display -- */
.dpib {display:inline-block;}
.dpb {display:block;}
.dpn {display:none;}

.tdu {text-decoration:underline;}

.swiper-pagination{
  bottom: 0px !important;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.swiper-pagination-bullet{
  margin: 3px 3px 3px 3px;
}




/*  ===================================================================================  */




.checks {position: relative;}

.checks input[type="checkbox"] {  /* 실제 체크박스는 화면에서 숨김 */
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip:rect(0,0,0,0);
  border: 0
}
.checks input[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.checks input[type="checkbox"] + label:before {  /* 가짜 체크박스 */
  content: ' ';
  display: inline-block;
  width: 21px;  /* 체크박스의 너비를 지정 */
  height: 21px;  /* 체크박스의 높이를 지정 */
  line-height: 21px; /* 세로정렬을 위해 높이값과 일치 */
  margin: -2px 8px 0 0;
  text-align: center;
  vertical-align: middle;
  background: #fafafa;
  border: 1px solid #cacece;
  border-radius : 3px;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
}
.checks input[type="checkbox"] + label:active:before,
.checks input[type="checkbox"]:checked + label:active:before {
  box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.checks input[type="checkbox"]:checked + label:before {  /* 체크박스를 체크했을때 */
  content: '\2714';  /* 체크표시 유니코드 사용 */
  color: #fff;
  background: #0261ff;
  border-color: #fff;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
}

.up_red {color:#f42222; font-weight:normal}
.dw_blue {color:#2282f4; font-weight:normal}
.midd_gray {color:#2282f4; font-weight:normal}

.qa_btn {display:inline-block; padding:.75rem 2rem; background:#333333; border-radius:3px; color:#fff; font-size:1.14rem;}
.cancel_btn {display:inline-block; padding:.7rem 2rem; background:#fff; border-radius:3px; color:#2a2a2a; border:solid 1px #2a2a2a; font-size:1.14rem;}
.cancel1_btn {display:inline-block; padding:.7rem 2rem; background:#fff; border-radius:3px; color:#2a2a2a; border:solid 1px #2a2a2a; font-size:1.14rem;}
.save_btn {display:inline-block; padding:.75rem 2rem; background:#333333; border-radius:3px; color:#fff; font-size:1.14rem;}
.check1_btn {display:inline-block; padding:.75rem 2rem; background:#2e3640; border-radius:3px; color:#fff; font-size:1.14rem;}
.edit_btn {display:inline-block; padding:.75rem 2rem; background:#345f9f; border-radius:3px; color:#fff; border:solid 1px #2a2a2a; font-size:1.14rem;}
.txt_right {text-align:right;}
.txt_center {text-align:center;}

@keyframes ball {
0% {
	top:0px;
	}
100% {
	top:-5px;
	} 
}

.push_onbtn {position:relative;right:10px; top:0; animation:ball 1s ease-in-out Infinite Alternate;}

/* @keyframes blink {
  50% { opacity: 0.0; }
}
@-webkit-keyframes blink {
  50% { opacity: 0.0; }
}
.m_blink {
  animation: blink 1s step-start 0s 1;
  -webkit-animation: blink 1s step-start 0s 1;
} */


.blink_me {
  animation: blinker 1s linear 1;
}

@keyframes blinker {  
  50% { opacity: 0; }
}
