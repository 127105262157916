@charset "UTF-8";

#wrap {
  min-width: 100%;
  overflow: hidden;
}
.clear {
  padding-bottom: 100px;
}
.tabulator .tabulator-paginator > .tabulator-page {
  text-indent: -9999px;
  border: 1px solid #e0e0e0;
}
.tabulator .tabulator-paginator > .tabulator-page[data-page="first"] {
  margin: 0 1px 0 1px;
  background: url("../img/common/btn_paging_first.png") no-repeat center center;
}
.tabulator .tabulator-paginator > .tabulator-page[data-page="prev"] {
  margin: 0 10px 0 1px;
  background: url("../img/common/btn_paging_prev.png") no-repeat center center;
}
.tabulator .tabulator-paginator > .tabulator-page[data-page="next"] {
  margin: 0 1px 0 10px;
  background: url("../img/common/btn_paging_next.png") no-repeat center center;
}
.tabulator .tabulator-paginator > .tabulator-page[data-page="last"] {
  margin: 0 1px 0 1px;
  background: url("../img/common/btn_paging_last.png") no-repeat center center;
}
/* skip navi to content */
.skip-nav a {
  position: absolute;
  top: -9999em;
  z-index: 9999;
  width: 100%;
  background: #475470;
  font: bold 1.3em;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.skip-nav a:hover,
.skip-nav a:active,
.skip-nav a:focus {
  top: 0;
  padding: 12px 0;
  color: #fff;
}
/*  ===================================================================================  */
.top_pop {
  height: 71px;
  background: #565053;
  width: 100%;
}
.top_pop h2 {
  height: 0px;
  line-height: 0px;
  font-size: 0px;
}
.top_pop_con {
  width: 1080px;
  height: 71px;
  margin: 0 auto;
  position: relative;
}
.pop_btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.go_btn1 {
  position: absolute;
  bottom: 10px;
  right: 310px;
}
.close1 {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 20px;
  right: 0px;
}
/* main layout */

.top_uitt {
  background: #2a2a2a;
  width: 100%;
}
.top_box {
  width: 1080px;
  padding: 10px 0px;
  margin: 0 auto;
  overflow: hidden;
  display: none;
}
.top_box ul {
  float: right;
}
.top_box ul li:first-child {
  padding-left: 0px;
  margin-left: 0px;
}
.top_box ul li:first-child:before {
  background: none;
}
.top_box ul li {
  float: left;
}
.top_box ul li:before {
  content: "";
  padding-left: 10px;
  margin-left: 10px;
  background: url(../img/common/grey_line.png) left 50% no-repeat;
}
.top_box ul li a {
  color: #fff;
}
.header {
  width: 100%;
  margin: 20px 0px;
}
.header_wrap {
  width: 1080px !important;
  margin: 0 auto;
  height: 62px;
  position: relative;
}
.header_wrap .logo {
  float: left;
  margin-top: 10px;
}
.header_wrap .all_box1 {
  position: absolute;
  top: 15px;
  right: 0px;
}
.header_wrap ul.sf-menu {
  float: right;
  width: auto;
  margin-right: 40px;
}
.header_wrap ul.sf-menu > li {
  float: left;
}
.header_wrap ul.sf-menu > li > a {
  display: block;
  font-size: 18px;
  padding-bottom: 30px;
  font-weight: 400;
}
.header_wrap ul.sf-menu > li.sfHover > a {
  color: #ff5f00;
}
.header_wrap ul.sf-menu li ul li a:hover {
  background: #ff5f00;
  color: #fff;
}
.sub_header {
  width: 100%;
  margin: 20px 0px;
  border-bottom: solid 1px #eeeeee;
}

.svisual_box {
  position: relative;
  height: 331px;
}

.allmenu1 {
  padding: 30px 40px 30px 40px;
}
h2.alltitl {
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 50px;
  font-weight: bold;
}
ul.menulist {
  overflow: hidden;
}
ul.menulist > li {
  float: left;
  margin-left: 85px;
}
ul.menulist > li:first-child {
  margin-left: 0px;
}
ul.menulist > li dl dt {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: bold;
}
ul.menulist > li dl dd ul li {
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 25px;
  text-align: left;
}
ul.menulist > li dl dd ul li a {
  color: #333333;
}
ul.menulist > li dl dd ul li a:hover {
  text-decoration: underline;
  font-weight: bold;
}
.layer-wrap {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.layer-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
  background-color: #fff;
  z-index: 10;
}
.pop-layer .pop-container {
  position: relative;
}
a.btn-layerClose {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
}

/* =========================================================== main layout */

.infor_wrap1 {
  background: #242424;
  padding: 40px 0px 40px 0px;
}
.infor_box1 {
  width: 1080px;
  margin: 0 auto;
  position: relative;
}
.infor_box1 ul {
  overflow: hidden;
}
.infor_box1 ul li {
  float: left;
  padding-left: 40px;
}
.infor_box1 ul li:first-child {
  padding-left: 0px;
}
.infor_box1 ul li a {
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
}
.infor_box1 .sel_family {
  position: absolute;
  top: -20px;
  right: 10px;
}

.subvisual_wrap {
  width: 1080px;
  height: 330px;
  margin: 0 auto;
}
dl.sub_vtitl1 {
  padding-top: 110px;
  padding-bottom: 80px;
}
dl.sub_vtitl1 dt {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: bold;
}
dl.sub_vtitl1 dd {
  font-size: 30px;
  line-height: 30px;
}
dl.sub_vtitl2 {
  padding-top: 110px;
  padding-bottom: 80px;
}
dl.sub_vtitl2 dt {
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: bold;
}
dl.sub_vtitl2 dd {
  font-size: 30px;
  line-height: 30px;
  color: #fff;
}
.sub_mvisual1_bg {
  background: #f0f0f1 url(../img/sub/visual1_1.jpg) center top no-repeat;
  height: 300px;
  min-width: 1450px;
}
.sub_visual1_bg {
  background: #f0f0f1 url(../img/sub/visual1_1.jpg) center top no-repeat;
  height: 300px;
  min-width: 1450px;
}
.sub_visual2_bg {
  background: #f0f0f1 url(../img/sub/visual2_1.png) center top no-repeat;
  height: 300px;
  min-width: 1450px;
}
.sub_visual3_bg {
  background: #f0f0f1 url(../img/sub/visual3_1.png) center top no-repeat;
  height: 300px;
  min-width: 1450px;
}
.sub_visual4_bg {
  background: #f0f0f1 url(../img/sub/visual4_1.png) center top no-repeat;
  height: 300px;
  min-width: 1450px;
}
.sub_visual5_bg {
  background: #f0f0f1 url(../img/sub/visual5_1.jpg) center top no-repeat;
  height: 300px;
  min-width: 1450px;
}
.sub_visual5_bg .subvisual_wrap .sub_vtitl1 dt {
  color: #000;
}
.sub_visual5_bg .subvisual_wrap .sub_vtitl1 dd {
  color: #000;
}
.location_wrap {
  width: 100%;
}
.location_box {
  background: #ff5f00;
  height: 50px;
  line-height: 50px;
  box-shadow: 5px 5px 10px #f4ceb8;
}
.location_box dl dt.home {
  width: 199px;
  border-right: solid 1px #e97d3c;
  text-align: center;
}
.location_box dl dt.home a {
  width: 100%;
  height: 35px;
  padding-top: 15px;
  display: block;
}
.location_box dl dt,
.location_box dl dd {
  float: left;
}
.location_box dl dd {
  width: 239px;
  height: 50px;
  border-right: solid 1px #e97d3c;
  text-align: center;
  position: relative;
}
.location_box dl dd button.dropbtn {
  border: 0px;
  background: none;
  display: block;
  text-align: left;
  width: 230px;
  padding-left: 20px;
  cursor: pointer;
  color: #fff;
  height: 50px;
  line-height: 50px;
}
.location_box dl dd:after {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 23px;
  height: 23px;
  background: url(../img/sub/sel_arrow1.png) center 50% no-repeat;
}
.dropdown-content {
  display: none;
  position: absolute;
}
.dropdown-content.show {
  display: block;
  background: #fff;
  width: 230px;
  box-shadow: 3px 3px 3px #bababa;
  border: solid 1px #f0f0f1;
  z-index: 99;
}
.dropdown-content.show a {
  width: 230px;
  text-align: left;
  display: block;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  color: #242424;
}

.sub_container {
  min-height: 500px;
  width: 100%;
  min-width: 1450px;
}
.sub_container_wrap {
  width: 1080px;
  min-height: 600px;
  position: relative;
  margin: 0px auto;
}
.login_container {
  min-height: 500px;
  width: 100%;
  min-width: 1450px;
}
.login_container_wrap {
  width: 580px;
  min-height: 700px;
  position: relative;
  margin: 0px auto;
}
.mem_container {
  min-height: 500px;
  width: 100%;
  min-width: 1450px;
}
.mem_container_wrap {
  width: 785px;
  min-height: 700px;
  position: relative;
  margin: 0px auto;
}
.lnb {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 199px;
  height: 100%;
  border-right: solid 1px #e5e5e5;
}
.lnb ul > li a {
  display: block;
  position: relative;
  border-bottom: solid 1px #e5e5e5;
  width: 180px;
  height: 40px;
  padding-top: 15px;
  padding-left: 20px;
  color: #666666;
  font-size: 16px;
}
.lnb ul > li a.on {
  background: #ff5f00;
  color: #fff;
  border-bottom: 0px;
}
.lnb ul > li a.on:after {
  position: absolute;
  top: 20px;
  right: 20px;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.lnb ul > li a:hover {
  background: #ff5f00;
  color: #fff;
  border-bottom: 0px;
  width: 200px;
  height: 55px;
  color: #fff;
}
.lnb ul > li a:hover:after {
  position: absolute;
  top: 20px;
  right: 20px;
  border-bottom: 0px;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
h4.lb_titl1 {
  width: 100%;
  height: 75px;
  padding-top: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}
h4.lb_titl2 {
  width: 100%;
  height: 40px;
  padding-top: 50px;
  margin-left: 5px;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  border-bottom: 1px solid #ddd;
}
.contents {
  margin-left: 230px;
  width: 850px;
  min-height: 800px;
}
.con_titl1 {
  padding: 50px 0px;
  margin-bottom: 50px;
  border-bottom: solid 1px #e5e5e5;
}
.con_titl1 h4 {
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
  margin-bottom: 10px;
  text-align: center;
}
.con_titl1 span {
  color: #ff5f00;
  font-size: 20px;
  line-height: 20px;
  display: block;
  text-align: center;
}

.stable_type1 {
  border-top: solid 2px #eeeeee;
  overflow: hidden;
  margin-bottom: 20px;
}
.stable_type1 table {
  width: 100%;
}
.stable_type1 table thead tr th {
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  padding: 20px 0px;
  background: #fffbf9;
}
.stable_type1 table tbody tr th {
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  color: #000000;
  padding: 20px 0px;
}
.stable_type1 table tbody tr th.tal {
  padding: 0px 10px;
  text-align: left;
}
.stable_type1 table tbody tr th.tar {
  padding: 0px 10px;
  text-align: right;
}
.stable_type1 table tbody tr td {
  padding: 20px 0px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.stable_type1 table tbody tr td.tal {
  padding: 0px 10px;
  text-align: left;
}
.stable_type1 table tbody tr td.tar {
  padding: 0px 10px;
  text-align: right;
}
.stable_type1.bth1 table thead th.bdline {
  border-left: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
}
.stable_type1.bth1 table tr th {
  background: #fafafa;
}

.stb_typ {
  width: 500px;
  float: left;
}
.cltable_type1 {
  border-top: solid 2px #eeeeee;
  overflow: hidden;
}
.cltable_type1 table {
  width: 100%;
}
.cltable_type1 table thead tr th {
  font-size: 16px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  padding: 20px 0px;
  background: #fffbf9;
}
.cltable_type1 table tbody tr th {
  background: #fafafa;
  font-size: 16px;
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  color: #000000;
  padding: 20px 0px;
}
.cltable_type1 table tbody tr td {
  font-size: 16px;
  padding: 20px 0px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}

.ctable_type1 {
  width: 300px;
  float: left;
  border-top: solid 2px #eeeeee;
  overflow: hidden;
  box-shadow: 1px 2px 10px #bebebe;
}
.ctable_type1 table {
  width: 100%;
}
.ctable_type1 table thead tr th {
  font-size: 16px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  padding: 20px 0px;
  background: #fffbf9;
}
.ctable_type1 table tbody tr th {
  background: #fafafa;
}
.ctable_type1 table tbody tr td.hd1 {
  height: 104px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.ctable_type1 table tbody tr td.hd2 {
  height: 88px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.ctable_type1 table tbody tr td.hd3 {
  height: 61px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.ctable_type1 table tbody tr td.hd4 {
  height: 72px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.ctable_type1 table tbody tr td.hd5 {
  height: 104px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.ctable_type1 table tbody tr td.hd6 {
  height: 60px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}

.p_titl1 {
  font-size: 1.714rem;
  text-align: center;
  margin-bottom: 5%;
  margin-top: 5%;
}
.mypage_table1 {
  border-top: solid 2px #2a2a2a;
  overflow: hidden;
  margin-bottom: 1.2rem;
}
.mypage_table1 table {
  width: 100%;
}
.mypage_table1 table tbody tr th {
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  color: #000000;
  padding: 20px 0px;
  font-size: 16px;
}
.mypage_table1 table tbody tr th.tal {
  padding: 0px 10px;
  text-align: left;
}
.mypage_table1 table tbody tr th.tar {
  padding: 0px 10px;
  text-align: right;
}
.mypage_table1 table tbody tr td {
  padding: 20px 0px;
  line-height: 16px; /*text-align:center;*/
  color: #000000;
  vertical-align: middle;
  border-bottom: solid 1px #eeeeee;
}
.mypage_table1 table tbody tr td.tal {
  padding: 0px 10px;
  text-align: left;
}
.mypage_table1 table tbody tr td.tar {
  padding: 0px 10px;
  text-align: right;
}
.mypage_table1 table tbody tr td input.input_mtype {
  width: 100%;
  height: 26px;
  border: solid 1px #eeeeee;
  width: 88%;
}

#footer {
  background: #000000;
}
.footer_wrap {
  width: 1080px;
  padding: 40px 0px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.footer_logo {
  float: left;
}
.footer_info {
  float: left;
  margin-left: 40px;
}
.footer_info address {
  color: #fff;
}
.footer_info p {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
.footer_info .addres3 a {
  color: #fff;
}
.company_num {
  margin-bottom: 20px;
}
ul.footer_phone1 {
  float: right;
}
ul.footer_phone1 li {
  color: #fff;
}
ul.footer_phone1 li span {
  display: inline-block;
  width: 80px;
  line-height: 35px;
}
ul.footer_phone1 li.re_time {
  background: url(../img/common/tel_icon.png) left 70% no-repeat;
  padding-left: 35px;
  font-size: 16px;
}
ul.footer_phone1 li.pro_time {
  background: url(../img/common/voice_icon.png) left 70% no-repeat;
  padding-left: 35px;
  font-size: 16px;
} /* list-style: none; */
ul.footer_phone1 li strong {
  font-size: 34px;
  font-weight: normal;
  width: 200px;
  display: inline-block;
}

/*매수매도표*/
.tbl_table1 {
}
.tbl_table1 ul {
  display: flex;
  text-align: center;
}
.tbl_bs {
  padding: 10px 10px;
  width: 50%;
  border: 1px solid #dbdada;
  font-weight: 700;
  cursor: pointer;
}
.tbl_bs.active {
  background-color: #ffe675;
  border: none;
}
.tbl_pro {
  padding: 10px 7px;
  width: 20%;
  border: 1px solid #dbdada;
  border-radius: 5px;
  margin: 15px 5px 10px;
  color: #fff;
  background-color: #dddddd;
  font-weight: 700;
  cursor: pointer;
}
.tbl_pro.active {
  background-color: #ff5f00;
}
.tbl_tit {
  padding: 7px 7px;
  width: 20%;
  margin: 0 5px;
  font-weight: 700;
}
.tbl_cot {
  padding: 7px 7px;
  width: 20%;
  margin: 0 5px;
}
.tbl_cot_ul {
  border-bottom: 1px solid #dbdada;
  height: 30px;
}
.tbl_cot_ul:nth-child(even) {
  background-color: #fff;
}

/*약관*/
.scroll_wrap {
  position: absolute;
  top: 339px;
  left: 50%;
  width: 1080px;
  margin-left: -540px;
  z-index: 100;
}
.terms .sub_content {
  padding: 45px 0 0 0;
  position: relative;
  width: 1080px;
  margin: 0 auto;
}

#scroll_right {
  position: absolute;
  top: 0px;
  right: -132px;
  width: 112px;
}
.scroll_right_cont {
  float: left;
  min-width: 76px;
  padding: 18px;
  background: #f9f9f9;
}
#scroll_right .btn_scroll_top {
  display: block;
  padding: 10px 0 0 0;
  clear: both;
  text-align: center;
}
.scroll_right_cont h4 {
  padding: 0;
  color: #666666;
  text-align: center;
}
.scroll_right_cont .scr_paging {
  width: 74px;
  margin: 0 auto;
  color: #939393;
  text-align: center;
}
#scroll_left {
  position: absolute;
  top: 0px;
  left: -105px;
  width: 70px;
}

#scroll_right.ban_fixed {
  position: fixed;
  top: 150px;
  right: 50%;
  margin-right: -672px;
}

.all_box1 {
  margin-left: 550px !important;
  display: none;
}

/*회원가입*/
.join_box {
  padding-top: 50px;
}
.join_tit {
  margin-bottom: 50px;
}
.join_tit p {
  font-size: 20px;
}
.join_tit p span {
  font-size: 20px;
  font-weight: bold;
}

.btn_member ul {
  display: flex;
  justify-content: center;
}

.mylnb {
  width: 199px;
  height: auto;
  margin-top: 95px;
}
.mylnb ul > li a {
  display: block;
  position: relative;
  width: 180px;
  height: 40px;
  padding-top: 15px;
  padding-left: 5px;
  color: #666666;
  font-size: 16px;
}
.mylnb ul > li a.on {
  background: #ff5f00;
  color: #fff;
  border-bottom: 0px;
}
.mylnb ul > li a.on:after {
  position: absolute;
  top: 20px;
  right: 20px;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mylnb ul > li a:hover {
  background: #ff5f00;
  color: #fff;
  border-bottom: 0px;
  width: auto;
  height: 55px;
  color: #fff;
}
.mylnb ul > li a:hover:after {
  position: absolute;
  top: 20px;
  right: 20px;
  border-bottom: 0px;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pagination {
  display: flex;
  cursor: pointer;
  justify-content: center;
  margin-top: 15px;
}
ul {
  list-style: none;
  padding: 0;
}
ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
}
ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 1rem;
}
ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}
ul.pagination li a:hover {
  color: black;
  text-decoration: none;
}
ul.pagination li a.active {
  color: blue;
}
.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

/*live spot price 시세 수정*/
.gold_prices_bg h3 {
  font-size: 30px;
  line-height: 22px;
  margin: 37px 0 60px 37px;
  color: #fff;
  font-weight: 600;
}
.gold_prices_bg h3 span {
  float: right;
  padding-right: 30px;
  font-size: 20px;
  font-weight: 400;
  margin-top: 5px;
}

.gold_prices_box_new {
  overflow: hidden;
  margin-left: 27px;
  height: 350px;
}
.gold_prices_box_new table {
  color: #fff;
  width: 100%;
}
.gold_prices_box_new thead td {
  padding-left: 5%;
  padding-bottom: 10px;
}
.gold_prices_box_new table > tbody > tr > td {
  padding: 5px 0;
}
.gold_prices_box_new td {
  font-size: 21px;
}
.gold_prices_box_new td span {
  font-size: 16px;
}
.gold_prices_box_new td span img {
  vertical-align: initial;
}
/*실시간시세 구분,국제시세,국내시세 thead*/
.tbl_table9.rnow .rnow_top {
  box-sizing: border-box;
  margin-bottom: 7px;
  min-height: 0;
}
.tbl_table9.rnow .rnow_top tr:first-child {
  border-bottom: 0px;
  height: 60px;
}
.tbl_table9.rnow .rnow_top tr:first-child td {
  font-size: 17px;
  background-color: #ffd966;
  font-weight: 700;
  color: #000;
}
.tbl_table9.rnow .rnow_top tr td {
  font-size: 13px;
  background-color: #ffc000;
  color: #fff;
  border-left: 5px solid #f9f9f4;
  border-bottom: 5px solid #f9f9f4;
}
.tbl_table9.rnow .rnow_top tr:first-child td:first-child {
  border-left: 0px;
}
.tbl_table9.rnow .rnow_top tr {
  height: 40px;
}
.tbl_table9.rnow .rnow_top tr td span {
  font-size: 11px;
}

/*실시간시세표 금,은,백금,팔라듐*/

.odd_tr,
.even_tr {
  height: 130px !important;
}
.odd_tr .td1 {
  background-color: #fdfdfd;
  border-right: 1px solid #ddd;
  font-weight: bold;
}
.even_tr .td1 {
  background-color: #f4f4f4;
  border-right: 1px solid #ddd;
  font-weight: bold;
}

.odd_tr .td2 {
  background-color: #fdfdfd;
  border-right: 1px dashed #ddd;
}
.even_tr .td2 {
  background-color: #f4f4f4;
  border-right: 1px dashed #ddd;
}

.odd_tr .td2.lst {
  border-right: 1px solid #ddd;
}
.even_tr .td2.lst {
  border-right: 1px solid #ddd;
}

.odd_tr .td3 {
  background-color: #f7f6f9;
  border-right: 1px dashed #ddd;
}
.even_tr .td3 {
  background-color: #eeeeee;
  border-right: 1px dashed #ddd;
}

.odd_tr .td3:last-child {
  background-color: #f7f6f9;
  border-right: 0px dashed #ddd;
}
.even_tr .td3:last-child {
  background-color: #eeeeee;
  border-right: 0px dashed #ddd;
}

.tbl_table9.rnow thead tr {
  color: #fff;
}
.tbl_table9.rnow thead tr td {
  font-size: 17px;
}

.tbl_table9.rnow .td1 {
  font-size: 15px;
}

/*매매프리미엄 하단표 (국제가,환율,기준가.기준가3.75)*/
.mcontainer1 table.tbl_tb1 {
  background-color: #dbdada;
  height: 70px;
  min-height: 70px;
  margin-top: 15px;
  border-collapse: inherit;
}
.mcontainer1 table.tbl_tb1 tr {
  border: none;
  height: 35px;
}

.mcontainer1 table.tbl_tb1 tr td:nth-child(even) {
  background: #fff;
  box-sizing: border-box;
  border: 3px solid #dbdada;
  border-radius: 7px;
}

/*런던픽스시세 표 */
.lf_table {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.lf_table thead {
  border-top: 2px solid;
  border-bottom: 1px solid #828282;
}
.lf_table thead tr:first-child td {
  padding: 10px 0 0 0;
  font-size: 17px;
  font-weight: 700;
}
.lf_table thead tr:first-child td:first-child {
  padding: 10px;
}
.lf_table thead tr:last-child td {
  padding: 3px 0 10px 0;
  font-size: 17px;
  font-weight: 500;
}
.lf_table tr td {
  padding: 10px;
  font-size: 15px;
}
.lf_table tr td.lf_pk {
  background-color: #fff8f4;
}
.lf_table tr td.lf_date {
  background-color: #efefef;
}

.londonfix_cont select {
  border: 1px solid #d5d5d5;
  padding: 5px 10px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  float: right;
}
