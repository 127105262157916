@charset "utf-8";

.today_quo1 {
  overflow: hidden;
  margin-bottom: 20px;
}
.today_quo1 h5 {
  font-size: 22px;
  line-height: 22px;
  float: left;
  font-weight: bold;
}
.today_quo1 span.qu_day {
  font-size: 16px;
  line-height: 16px;
  float: right;
}
ul.quo_list {
  border-radius: 20px;
  background: #fafafa;
  border: solid 1px #eeeeee;
  overflow: hidden;
  margin-bottom: 10px;
}
ul.quo_list li:first-child {
  border-left: 0px;
}
ul.quo_list li {
  float: left;
  width: 150px;
  padding: 20px 30px;
  border-left: solid 1px #eeeeee;
}
ul.quo_list li dl dt {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
}
ul.quo_list li dl dd {
  overflow: hidden;
  margin-bottom: 10px;
}
ul.quo_list li dl dd span:first-child {
  font-size: 20px;
  color: #000;
  float: left;
}
ul.quo_list li dl dd span:last-child {
  font-size: 16px;
  color: #999999;
  float: right;
  text-align: right;
}
ul.quo_list li dl dd:last-child {
  margin-bottom: 0px;
}
ul.quo_list li dl dd:last-child span {
  font-size: 16px;
  line-height: 16px;
}
ul.quo_list li dl dd span.first_c {
  float: left;
}
ul.quo_list li dl dd span.last_c {
  float: left;
  margin-left: 10px;
}
ul.quo_list li dl.gold dt {
  color: #ff5f00;
}
ul.quo_list li dl.silver dt {
  color: #0096ff;
}
ul.quo_list li dl.sil_gold dt {
  color: #999999;
}
.day_schbox {
  overflow: hidden;
  margin: 20px 0px;
  position: relative;
  text-align: right;
}
.data_day {
  border: solid 1px #dddddd;
  border-radius: 3px;
  display: inline-block;
  width: 114px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  position: relative;
}
.data_day2 {
  border: solid 1px #dddddd;
  border-radius: 3px;
  display: inline-block;
  width: 166px;
  height: 30px;
  line-height: 30px;
  padding-left: 0px;
  position: relative;
}
.data_day2 input {
  width: 154px;
  height: 27px;
  padding-left: 10px;
}
.data_day_f1 {
  border: solid 1px #dddddd;
  border-radius: 3px;
  display: inline-block;
  width: 166px;
  height: 30px;
  line-height: 30px;
  padding-left: 0px;
  position: relative;
}
.data_day_f1 input {
  width: 154px;
  height: 27px;
  padding-left: 10px;
}
.data_day_f1 input[type="text"] {
  height: 23px;
  padding-top: 7px;
  border: none;
}
.data_day2 input[type="text"] {
  height: 30px;
  padding-top: 7px;
  border: none;
}

.ui-datepicker-trigger {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  right: 5px;
  z-index: 1;
}
.data_day .hasDatepicker {
  float: right;
  width: 100px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
}
.search_btn1 {
  padding: 0px 10px;
  border: solid 1px #dddddd;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  margin-left: 3px;
  height: 32px;
  line-height: 32px;
}
.sch_daytitl1 ul li a.search_btn1:hover {
  padding-bottom: 0px;
  border-bottom: solid 1px #dddddd;
  padding-bottom: 5px;
}
.sch_daytitl1 ul li a.search_btn1.on {
  padding: 5px 10px 5px 10px;
  border: solid 1px #dddddd;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  margin-left: 3px;
}

/* 귀금속 테이블 */
.gold_table1 {
  border-top: solid 2px #eeeeee;
  border-bottom: solid 1px #eeeeee;
}
.gold_table1 table {
  width: 100%;
}
.gold_table1 table thead th {
  background: #fff8f4;
  font-size: 16px;
  padding: 15px 0px;
  text-align: center;
}
.gold_table1 table tbody th {
  background: #fafafa;
  font-size: 16px;
  padding: 15px 0px;
  text-align: center;
}
.gold_table1 table tbody td.tal40 {
  padding-left: 40px;
  text-align: left;
}
.gold_table1 table tbody td {
  padding: 15px 0px;
  text-align: center;
  position: relative;
}
.gold_table1 table tbody td span.first_c {
  float: left;
  margin-left: 50px;
  margin-right: 20px;
}
.gold_table1 table tbody td span.last_c {
  float: left;
}
.tl_line {
  padding-bottom: 40px;
}
/* ul.ring_btn li button.gold:before {position:absolute; top:13px; left:30px; content:''; width:12px; height:12px; display:block; padding-left:15px; background:url(../img/common/g_ring1.jpg) left 50% no-repeat;}
ul.ring_btn li button.silver:before {position:absolute; top:13px; left:30px; content:''; width:12px; height:12px; display:block; padding-left:15px; background:url(../img/common/g_ring2.jpg) left 50% no-repeat;}
ul.ring_btn li button.plut:before {position:absolute; top:13px; left:30px; content:''; width:12px; height:12px; display:block; padding-left:15px; background:url(../img/common/g_ring3.jpg) left 50% no-repeat;}
ul.ring_btn li button.para:before {position:absolute; top:13px; left:30px; content:''; width:12px; height:12px; display:block; padding-left:15px; background:url(../img/common/g_ring4.jpg) left 50% no-repeat;}
ul.ring_btn li button.dalle:before {position:absolute; top:13px; left:30px; content:''; width:12px; height:12px; display:block; padding-left:15px; background:url(../img/common/g_ring5.jpg) left 50% no-repeat;} */
ul.skltbs-tab-group.ring_btn li button {
  border: solid 1px #e5e5e5;
  background: #fafafa;
  color: #666666;
  font-size: 16px;
  line-height: 16px;
  width: 165px;
  padding: 10px 0px;
  position: relative;
  text-align: center;
  display: inline-block;
}
.skltbs-theme-light .skltbs-tab.gold,
.skltbs-theme-light .skltbs-tab.silver,
.skltbs-theme-light .skltbs-tab.plut,
.skltbs-theme-light .skltbs-tab.para,
.skltbs-theme-light .skltbs-tab.dalle {
  border: solid 1px #e5e5e5;
}
.skltbs-theme-light .skltbs-tab.gold.skltbs-active,
.skltbs-theme-light .skltbs-tab.gold.skltbs-active:hover,
.skltbs-theme-light .skltbs-tab.silver.skltbs-active,
.skltbs-theme-light .skltbs-tab.silver.skltbs-active:hover,
.skltbs-theme-light .skltbs-tab.plut.skltbs-active,
.skltbs-theme-light .skltbs-tab.plut.skltbs-active:hover,
.skltbs-theme-light .skltbs-tab.para.skltbs-active,
.skltbs-theme-light .skltbs-tab.para.skltbs-active:hover,
.skltbs-theme-light .skltbs-tab.dalle.skltbs-active,
.skltbs-theme-light .skltbs-tab.dalle.skltbs-active:hover {
  background: #fff;
  box-shadow: 0px 0px 5px #cccccc;
  border: solid 1px #000;
  font-size: 16px;
}

ul.quo_list2 {
  border-radius: 20px;
  background: #fafafa;
  border: solid 1px #eeeeee;
  overflow: hidden;
}
ul.quo_list2 li:first-child {
  border-left: 0px;
}
ul.quo_list2 li {
  width: 360px;
  padding: 20px 30px;
  border-left: solid 1px #eeeeee;
  display: inline-block;
}
ul.quo_list2 li dl dt {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 15px;
}
ul.quo_list2 li dl dd {
  overflow: hidden;
  margin-bottom: 10px;
}
ul.quo_list2 li dl dd span:first-child {
  font-size: 18px;
  color: #000;
  float: left;
}
ul.quo_list2 li dl dd span:last-child {
  font-size: 30px;
  color: #333333;
  float: right;
  text-align: right;
  font-weight: bold;
}
ul.quo_list2 li dl.bidask dd span.bbid {
  color: #002aff;
}
ul.quo_list2 li dl.bidask dd span.aask {
  color: #ff0000;
}
ul.quo_list2 li dl dd:last-child {
  margin-bottom: 0px;
}
ul.quo_list2 li.last {
  width: 94%;
  padding-left: 3%;
  padding-right: 3%;
  border-top: solid 1px #eeeeee;
  overflow: hidden;
}
ul.quo_list2 li.last dl:first-child {
  float: left;
}
ul.quo_list2 li.last dl:first-child dt {
  float: left;
  font-size: 16px;
  line-height: 22px;
  color: #999999;
  margin-bottom: 0px;
}
ul.quo_list2 li.last dl:first-child dd {
  float: left;
  font-size: 20px;
  line-height: 22px;
  color: #666666;
  margin-left: 20px;
}
ul.quo_list2 li.last dl:last-child {
  float: right;
}
ul.quo_list2 li.last dl:last-child dt {
  float: left;
  font-size: 16px;
  line-height: 22px;
  color: #999999;
  margin-bottom: 0px;
}
ul.quo_list2 li.last dl:last-child dd {
  float: left;
  font-size: 20px;
  line-height: 22px;
  color: #666666;
  margin-left: 20px;
}

/* .sch_daytitl1 {background:url(../img/common/gline.png) left bottom repeat-x; height:20px; padding-bottom:20px; margin-top:40px; overflow:hidden;} */
.sch_daytitl1 h5 {
  float: left;
  font-weight: bold;
  font-size: 16px;
}
.sch_daytitl1 ul {
  height: 20px;
  padding-left: 10px;
  float: left;
}
.sch_daytitl1 ul li:first-child {
  border-left: solid 1px #eeeeee;
}
.sch_daytitl1 ul li.last {
}
.sch_daytitl1 ul li {
  padding-left: 10px;
  margin-left: 10px;
  float: left;
}
.sch_daytitl1 ul li a {
  font-size: 16px;
}
.sch_daytitl1 ul li a.on {
  font-weight: bold;
  color: #333333;
  padding-bottom: 17px;
  border-bottom: solid 2px #ff5f00;
}
.sch_daytitl1 ul li a:hover {
  font-weight: bold;
  color: #333333;
  padding-bottom: 17px;
  border-bottom: solid 2px #ff5f00;
}
.sch_daytitl1 dl {
  float: right;
  height: 20px;
}
.sch_daytitl1 dl dt {
  float: left;
  margin-left: 20px;
  border-right: solid 1px #eeeeee;
  font-size: 16px;
}
.sch_daytitl1 dl dd {
  float: left;
  margin-left: 20px;
}
.sch_daytitl1 dl dd a {
  display: block;
  font-size: 16px;
}
.sch_daytitl1 dl dd a.on {
  font-weight: bold;
  color: #333333;
  padding-bottom: 17px;
  border-bottom: solid 2px #ff5f00;
}
.sch_daytitl1 dl dd a:hover {
  font-weight: bold;
  color: #333333;
  padding-bottom: 17px;
  border-bottom: solid 2px #ff5f00;
}

/* 시세 테이블 */
.gold_table2 {
  border-top: solid 2px #eeeeee;
  margin-bottom: 40px;
}
.gold_table2 table {
  width: 100%;
}
.gold_table2 table thead th {
  background: #fafafa;
  font-size: 14px;
  padding: 20px 0px;
  text-align: center;
}
.gold_table2 table tbody td.tal40 {
  padding-left: 40px;
  text-align: left;
}
.gold_table2 table tbody td {
  padding: 15px 0px;
  font-size: 14px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
}

/* 기념품 */
.rem_titl1 {
  margin-bottom: 50px;
}
/* .rem_titl1 h5 {text-align:center; background:url(../img/common/rem_underline.png) center bottom no-repeat; font-weight:bold; padding-bottom:20px; margin-bottom:20px; font-size:22px;} */
.rem_titl1 p {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
.rem_titl1 p.orage {
  color: #ff5f00;
}
.rem_plist1 dl {
  float: left;
  width: 25%;
  text-align: center;
  margin-bottom: 80px;
}
.rem_plist1 dl dt {
  margin-bottom: 10px;
}
.rem_plist1 dl dd {
  font-size: 16px;
  line-height: 16px;
}
.gld_nx {
  margin-bottom: 50px;
}
.gld_nx dt {
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: bold;
}
.gld_nx dt strong {
  font-size: 22px;
}
.gld_nx dt span.orage {
  color: #ff5f00;
  font-size: 18px;
  font-weight: bold;
}
/* 기념품 질문 테이블 */
.search_area1 {
  text-align: right;
  margin-bottom: 20px;
}
.search_area1 select.select_type2 {
  padding: 0 2em;
  line-height: 42px;
  height: 42px;
  vertical-align: middle;
}
.search_area1 .sarch_inputbtn {
  display: inline-flex;
  width: auto;
  align-items: center;
}

.pagination {
  text-align: center;
  margin-bottom: 40px;
}
.pagination a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 0.2em;
}
.pagination a.on {
  background: #ff5f00;
  color: #fff;
  border-radius: 3px;
}

.gold_table3 {
  margin-bottom: 40px;
}
.gold_table3 table {
  width: 100%;
}
.gold_table3 table thead th {
  padding: 15px 0px;
  font-size: 16px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  font-weight: bold;
}
.gold_table3 table tbody th {
  padding: 15px 0px;
  font-size: 16px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  font-weight: bold;
}
.gold_table3 table tbody th.tal60 {
  padding-left: 60px;
  text-align: left;
}
.gold_table3 table tbody th p {
  font-size: 16px;
}
.gold_table3 table tbody td.tal10 {
  padding-left: 10px;
  text-align: left;
}
.gold_table3 table tbody td div.tal10 {
  padding: 10px;
  text-align: left;
  line-height: 22px;
}
.gold_table3 table tbody td {
  padding: 15px 0px;
  font-size: 14px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
}
.gold_table3 table tbody td input {
  font-size: 14px;
  line-height: 30px;
}
.gold_table3 table tbody td.infor dl {
  margin-bottom: 10px;
}
.gold_table3 table tbody td.infor dl dt {
  color: #666666;
}
.gold_table3 table tbody td.infor dl dd {
  color: #666666;
}
.gold_table3 table tbody td.infor div.rd_box {
  color: #232323;
}

.gold_table_view {
  border-top: solid 2px #666666;
  margin-bottom: 40px;
}
.gold_table_view table {
  width: 100%;
}
.gold_table_view table thead th {
  padding: 15px 20px;
  font-size: 20px;
  line-height: 20px;
  border-bottom: solid 1px #eeeeee;
  font-weight: bold;
}
.gold_table_view table tbody td .new_box {
  padding-left: 10px;
  text-align: left;
}
.gold_table_view table tbody td {
  padding: 15px 20px;
  font-size: 14px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
}

.scr_pic {
  margin-bottom: 10px;
}
.scr_pic img#code {
  width: 115px;
}

.h4tit_area {
  overflow: hidden;
  margin-bottom: 10px;
}
.h4tit_area .fllt {
  float: left;
}
.h4tit_area .flrt {
  display: inline-flex;
  float: right;
  align-items: center;
}

.menu_bar {
  margin-bottom: 20px;
  border-right: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
}
.menu_bar table {
  width: 100%;
}
.menu_bar table td a {
  display: block;
  padding-left: 10px;
  height: 51px;
  line-height: 50px;
  font-size: 12px;
  border: solid 1px #eeeeee;
  border-bottom: 0px;
}
.menu_bar table td.on a {
  display: block;
  padding-left: 10px;
  height: 51px;
  line-height: 50px;
  font-size: 12px;
  background: url(../img/common/chk_pic1.png) 90% 50% no-repeat;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  border-bottom: solid 1px #ff5f00;
}
.menu_bar table td a:hover {
  background: url(../img/common/chk_pic1.png) 90% 50% no-repeat;
  color: #ff5f00;
  border: solid 1px #ff5f00;
}
.list_solt {
  border-top: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
}
.list_solt ul li {
  float: left;
  margin-left: 50px;
}
.list_solt ul li:first-child {
  margin-left: 0px;
}
.list_solt ul li a.on {
  background: url(../img/common/chk_pic2.png) left 50% no-repeat;
  padding-left: 20px;
}
.num_view {
  float: right;
}

.product_list {
  border-bottom: solid 1px #dddddd;
  margin-bottom: 30px;
  margin-top: 30px;
}
.product_list ul {
  display: flex;
  justify-content: flext-start;
  flex-wrap: wrap;
}
.product_list ul li {
  width: 202px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.product_list ul li.product > a {
  text-align: center;
  display: block;
}
.product_list ul li.product a.pic1 img {
  width: 100%;
  height: auto;
}
.product_list ul li .prd_titnum {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.product_list ul li a.prd_titl {
  font-size: 15px;
  line-height: 20px;
  color: #404040;
  margin-bottom: 15px;
  display: block;
}
.product_list ul li a.prd_won {
  font-size: 18px;
  line-height: 24px;
  color: #000;
  font-weight: bold;
  display: block;
}
.product_list ul li .sum_pic {
  margin-left: 20px;
}
.product_list ul li.on {
  box-shadow: 0px 0px 6px #d3d3d3;
}
.product_list ul li:hover {
  box-shadow: 0px 0px 6px #d3d3d3;
}
.slick-slide.slick-cloned .slick-slide img {
  width: 384px;
  height: 384px;
}
.slider-nav-thumbnails .slick-slide.slick-active {
  width: 95px;
}
ul.prd_box1 {
  border-bottom: solid 1px #eeeeee;
  padding-bottom: 50px;
  padding-top: 50px;
  overflow: hidden;
  margin-bottom: 50px;
}
ul.prd_box1 li:first-child {
  float: left;
  width: 386px;
}
ul.prd_box1 li:last-child {
  float: right;
  width: 400px;
}
ul.prd_box1:after {
  content: "";
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  clear: both;
  overflow: hidden;
}
ul.prd_box1 li:last-child h4 {
  font-size: 22px;
  line-height: 28px;
  color: #ff5f00;
  margin-bottom: 30px;
}
ul.prd_box1 li:last-child .prd_detit1 {
  border-top: solid 3px #ff5f00;
}
ul.prd_box1 li:last-child .prd_detit1 table {
  width: 100%;
}
ul.prd_box1 li:last-child .prd_detit1 table tbody th {
  font-size: 16px;
  line-height: 16px;
  padding: 20px 0px;
  color: #333333;
  border-bottom: solid 1px #eeeeee;
}
ul.prd_box1 li:last-child .prd_detit1 table tbody td {
  font-size: 16px;
  line-height: 16px;
  padding: 20px 0px;
  color: #333333;
  border-bottom: solid 1px #eeeeee;
}
ul.prd_box1 li:last-child .prd_detit1 table tbody td strong {
  font-size: 20px;
  color: #000000;
}
.contents_box {
  margin-bottom: 70px;
}

/* 로그인 페이지 */
.login_contents {
  width: 100%;
  padding-top: 50px;
}
.login_contents .logn_titl1 {
  margin-bottom: 60px;
}
.login_contents .logn_titl1 h4 {
  margin-bottom: 25px;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  text-align: center;
}
.login_contents .logn_titl1 p.login_p1 span {
  display: block;
  text-align: center;
  line-height: 22px;
  font-size: 16px;
}
.login_contents .logn_titl1 p {
  display: block;
  text-align: center;
  line-height: 22px;
  font-size: 16px;
}
.login_contents .login_inpbox p.login_inpt1 {
  margin-bottom: 10px;
}
.login_contents .login_inpbox p input {
  border: solid 1px #ddd;
  width: 100%;
  padding-left: 5%;
  height: 50px;
  line-height: 50px;
}
.login_contents .login_inpbox p input:focus {
  border: solid 1px #000;
  background: #fbfbfb;
}
.pass_inpt1 {
  margin-bottom: 15px;
}
.chk_id {
  margin-bottom: 25px;
  font-size: 16px;
  color: #232323;
}
.login_btn1 {
  display: block;
  padding: 13px 0px;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #2a2a2a;
  text-align: center;
  font-size: 18px;
}
.login_btn1.logn {
  width: 49%;
  float: left;
}
.login_btn1.logn:last-child {
  margin-left: 2%;
}
.member_btnlist {
  overflow: hidden;
}
.member_btnlist p {
  float: left;
  border: solid 1px #707070;
  width: 175px;
  height: 45px;
  position: relative;
}
/* .member_btnlist p:after {position:absolute; top:15px; right:15px; content:''; background:url(../img/common/lgin_arrow.jpg) left 50% no-repeat; width:7px; height:11px;} */
.member_btnlist p a {
  width: 155px;
  padding-left: 20px;
  height: 45px;
  line-height: 45px;
  display: block;
}
.member_btnlist p.id_search {
  margin-left: 22px;
  margin-right: 22px;
}
.member_btnlist p.pw_search {
}
.cael {
  color: #ff7713;
  margin-bottom: 5px;
}

.logn_ing_tit {
  font-size: 24px;
  font-weight: bold;
}
.logn_ing {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.logn_ing_cot {
  font-size: 18px;
  color: #666;
}
.bt_line {
  border-bottom: 1px solid;
  border-color: #999;
}
.logn_ing_cot a {
  color: #666;
}

.mem_id {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: solid 3px #000000;
}
.mem_sch1 {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f5f5f5;
}
.mem_sch1 dl dt {
  text-align: center;
}
.mem_sch1 dl dd {
  text-align: center;
  width: 190px;
  font-size: 16px;
  line-height: 24px;
  margin: 20px auto;
}
.mem_sch1 dl dd span {
  display: block;
}
.mem_sch1 dl dd strong {
  font-size: 22px;
  line-height: 28px;
  color: #333333;
}
.sch_list {
  margin-top: 30px;
  padding-top: 30px;
  border-top: solid 1px #dddddd;
}

.login_scharea {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.login_scharea button {
  text-align: center;
  display: block;
  width: 48%;
  margin: 0 2%;
  color: #999999;
  border-bottom: solid 3px #b2b2b2;
  padding: 2rem 0px;
  outline: 0px;
  background: #fff;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #121212;
  cursor: pointer;
}
.login_scharea button.active {
  font-weight: bold;
  color: #232323;
  border-bottom: solid 3px #232323;
}
.login_scharea2 ul.skltbs-tab-group {
  overflow: hidden;
}
.login_scharea2 ul.skltbs-tab-group li {
  width: 49%;
  text-align: center;
}
.login_scharea2 ul.skltbs-tab-group li button {
  width: 100%;
}
.login_scharea2 ul.skltbs-tab-group li button.skltbs-active {
  font-weight: bold;
  color: #232323;
  border-bottom: solid 3px #232323;
}
.login_scharea2 button {
  text-align: center;
  display: block;
  color: #999999;
  border-bottom: solid 3px #b2b2b2;
  padding: 2rem 0px;
  outline: 0px;
  background: #fff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #121212;
  cursor: pointer;
}
.login_scharea2 button.active {
  font-weight: bold;
  color: #232323;
  border-bottom: solid 3px #232323;
}
.login_contents .tabsub1 .tabssl1 {
  display: none;
}
.login_contents .tabsub1 .tabssl1.active {
  display: block;
}
.login_contents .tabsub1 .tabssl1.active input {
  height: 45px;
  line-height: 45.5px;
}
.login_contents .tabsub1 .tabssl1 p.name_inpt1 {
  margin-bottom: 10px;
}
/* .name_inpt1 {margin-bottom:10px;}
.name_inpt1 input {width:552px;  height:45px;}
.mail_inpt1 {margin-bottom:50px;}
.mail_inpt1 input.input_type03 {display:inline-block; width:345px; margin-right:10px; height:45px;}
.mail_inpt1 select.select_type2 {width:192px; height:45.5px; display:inline-block;}
.sch_list1 {padding-top:30px; border-top:solid 1px #dddddd;}
.name_inpt2 {margin-bottom:10px;}
.name_inpt2 input {width:552px;  height:45px;}
.mail_inpt2 {margin-bottom:50px;}
.mail_inpt2 input {width:552px;  height:45px;}
.skltbs-theme-light .skltbs-tab-group.mb50 {margin-bottom:50px;}
.sch_list2.member_btnlist {padding-top:30px; border-top:solid 1px #dddddd;}
.sch_list2.member_btnlist .id_search {margin-left:0px; margin-right:0px;} */

.tab_area {
  width: 575px;
}
.login_scharea ul li:first-child {
  margin-right: 40px;
}
.name_inpt1 {
  margin-bottom: 10px;
}
.name_inpt1 input {
  width: 552px;
  height: 45px;
}
.mail_inpt1 {
  margin-bottom: 50px;
}
.mail_inpt1 input {
  vertical-align: top;
}
.mail_inpt1 input.input_type03 {
  display: inline-block;
  width: 345px;
  margin-right: 10px;
  height: 45px;
}
.mail_inpt1 select.select_type2 {
  width: 192px;
  height: 45.5px;
  display: inline-block;
}
.sch_list1 {
  padding-top: 30px;
  border-top: solid 1px #dddddd;
}
.name_inpt2 {
  margin-bottom: 10px;
}
.name_inpt2 input {
  width: 552px;
  height: 45px;
}
.mail_inpt2 {
  margin-bottom: 50px;
}
.mail_inpt2 input {
  width: 552px;
  height: 45px;
}
.skltbs-theme-light .skltbs-tab-group.mb50 {
  margin-bottom: 50px;
}
.sch_list2.member_btnlist {
  padding-top: 30px;
  border-top: solid 1px #dddddd;
}
.sch_list2.member_btnlist .id_search {
  margin-left: 0px;
  margin-right: 0px;
}

.pop-layer2 {
  width: 690px;
  display: inline-block;
  vertical-align: middle;
  height: auto;
  background-color: #fff;
  z-index: 10;
}
.pop-layer2 .pop_titl1 {
  position: relative;
  height: 60px;
  line-height: 60px;
  height: 60px;
  background: #2a2a2a;
  color: #fff;
}
.pop-layer2 .pop_titl1 h4 {
  font-size: 22px;
  line-height: 60px;
  margin-left: 20px;
}
.pop_area {
  background: #fff;
  padding-bottom: 45px;
}
.pop_line {
  width: 580px;
  padding: 0 20px;
  margin: 35px auto;
  padding-bottom: 35px;
  border-bottom: solid 1px #ececec;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
}
dl.pw_box1 {
  overflow: hidden;
  width: 635px;
  margin: 0 auto;
}
dl.pw_box1 dd input {
  width: 400px;
}
dl.pw_box1.mb20 {
  margin-bottom: 20px;
}
dl.pw_box1 dt {
  float: left;
  margin-left: 30px;
  width: 140px;
  font-size: 16px;
  line-height: 42px;
  text-align: left;
}
dl.pw_box1 dd {
  float: left;
}
.btn-layerClose1 {
  position: absolute;
  top: 0px;
  right: 0px;
}
.chk_num {
  text-align: right;
  margin-top: 5px;
}
.chk_num a {
  text-decoration: underline;
}

ul.rd_check1 li {
  text-align: left;
  padding-left: 20px;
  width: 600px;
  margin: 0 auto;
}
ul.rd_check1 li:first-child {
  margin-bottom: 20px;
}
ul.rd_check1 li label {
  font-size: 16px;
  line-height: 24px;
}
ul.rd_check1 li label p.br_p1 {
  padding-left: 20px;
  font-size: 16px;
  line-height: 24px;
}
.login_btn2 {
  width: 140px;
  height: 50px;
  line-height: 50px;
  background: #333333;
  color: #fff;
  border-radius: 3px;
  display: block;
  margin: 0 auto 40px auto;
}
.login_btn3 {
  width: 140px;
  height: 50px;
  line-height: 50px;
  background: #333333;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  margin: 0 auto 40px auto;
}
.login_btn4 {
  width: 140px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  color: #333;
  border: solid 1px #333;
  border-radius: 3px;
  display: inline-block;
  margin: 0 auto 40px auto;
}

/* 회원가입 */

.mem_cont {
  margin-bottom: 60px;
  margin-top: 80px;
}
.mem_cont dl {
  margin-bottom: 50px;
}
.mem_cont.myp dl {
  margin-bottom: 0px;
}
.mem_cont dl dt {
  margin-bottom: 20px;
}
.mem_cont dl dt h4 {
  font-size: 32px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.mem_cont dl dd {
  text-align: center;
}
.mem_cont dl dd ul {
  overflow: hidden;
  width: 360px;
  margin: 0 auto;
}
/* .mem_cont dl dd ul li {float:left; width:100px; font-size:16px; position:relative; background:url(../img/sub/local_off_arrow.jpg) right 5px no-repeat; padding-right:20px; margin-right:10px;} */
.mem_cont dl dd ul li:last-child {
  padding-right: 0px;
  margin-right: 0px;
  background: none;
}
.mem_cont dl dd ul li:last-child.on:after {
  background: none;
}
.mem_cont dl dd ul li.on {
  color: #232323;
  background: none;
  font-weight: bold;
}
/* .mem_cont dl dd ul li.on:after {position:absolute; top:0px; right:0px; content:''; width:7px; height:17px; background:url(../img/sub/local_on_arrow.jpg) right 5px no-repeat; z-index:99;} */

.line_h4 {
  padding-bottom: 10px;
  font-size: 18px;
  border-bottom: solid 3px #000000;
  font-weight: bold;
  margin-bottom: 30px;
}
.chk_box1 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 30px;
  font-weight: bold;
}
.chk_box1 span {
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
}
.chs_box1 {
  margin-bottom: 30px;
}
.chs_box1 .txt_area {
  width: 740px;
  padding: 20px 20px;
  overflow-y: scroll;
  height: 100px;
  border: solid 1px #cccccc;
}
.chs_box1.last .txt_area {
  height: 250px;
}
.chk_boxlist {
  overflow: hidden;
  margin-bottom: 10px;
}
.chk_boxlist .chk_titl1 {
  float: left;
  font-weight: bold;
}
.chk_boxlist .all_view {
  float: right;
  display: none;
}

.input_area {
  overflow: hidden;
  padding-bottom: 15px;
}
.input_area h5 {
  float: left;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
}
.input_area p.org1 {
  float: right;
  font-size: 14px;
  line-height: 14px;
  color: #ff6b13;
}
.mem_inpt {
  border-top: solid 3px #000000;
  margin-bottom: 30px;
  margin-bottom: 20px;
}
.mem_inpt table {
  width: 100%;
}
.mem_inpt table tbody tr th {
  padding: 20px 0px;
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #dddddd;
}
.mem_inpt table tbody tr th span.org2 {
  color: #ff9252;
  font-size: 18px;
}
.mem_inpt table tbody tr td {
  padding: 20px 0px;
  border-bottom: solid 1px #dddddd;
}
.mem_inpt table tbody tr.last th {
  padding: 20px 0px;
  text-align: left;
  padding-left: 10px;
  border-bottom: solid 1px #000;
}
.mem_inpt table tbody tr:last-child td {
  padding: 20px 0px;
  border-bottom: solid 1px #000;
}
.id_check1 {
  display: inline-block;
  background: #2a2a2a;
  padding: 13px 30px;
  color: #fff;
  border-radius: 3px;
}
.chk_wrap1 {
  margin-top: 10px;
}
.addr_btn {
  border: solid 1px #232323;
  color: #232323;
  padding: 11px 30px;
  display: inline-block;
  border-radius: 3px;
}

.mem_clear {
  border-top: solid 3px #000000;
  border-bottom: solid 1px #dddddd;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-bottom: 30px;
}
.mem_clear h5 {
  text-align: center;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 20px;
  font-weight: bold;
}
.mem_clear dl.mem_end {
  margin-bottom: 0px;
}
.mem_clear dl dt {
  text-align: center;
}
.mem_clear dl dd p {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
}
.mem_clear dl dd strong {
  font-size: 18px;
  line-height: 28px;
}

/* 다이아몬드 시세 */
.dia_pic {
  margin-bottom: 40px;
}
.diamaond_block .cont_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
/* 유가증권 */
ul.gold_tab1 {
  overflow: hidden;
  height: 74px;
  margin-bottom: 35px;
}
ul.gold_tab1 li {
  float: left;
  width: 422px;
  height: 71px;
  line-height: 71px;
  background: #fafafa;
  border: solid 1px #eeeeee;
}
ul.gold_tab1 li a {
  display: block;
  line-height: 71px;
  text-align: center;
  font-size: 22px;
}
ul.gold_tab1 li.on a {
  font-weight: bold;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
}
ul.gold_tab1 li a:hover {
  font-weight: bold;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
  line-height: 71px;
}
dl.stock_explan1 {
  margin-bottom: 50px;
}
dl.stock_explan1 dt {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 45px;
  color: #272828;
}
dl.stock_explan1 dd {
  height: 210px;
  font-size: 16px;
  line-height: 22px;
  color: #221815;
  background: url(../img/sub/sub_contpage1.png) left top no-repeat;
  padding-left: 450px;
}
dl.stock_explan1_2 {
  margin-bottom: 50px;
}
dl.stock_explan1_2 dt {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 45px;
  color: #272828;
}
dl.stock_explan1_2 dd {
  height: 210px;
  font-size: 16px;
  line-height: 22px;
  color: #221815;
  background: url(../img/sub/sub_contpage1_1.png) left top no-repeat;
  padding-left: 450px;
}

ul.stock_explan2 {
  overflow: hidden;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: solid 1px #e5e5e5;
}
ul.stock_explan2 li {
  float: left;
  text-align: center;
  width: 25%;
}
ul.stock_explan2 li dl {
  margin-top: 20px;
}
ul.stock_explan2 li dl dt {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #444444;
}
ul.stock_explan2 li dl dt {
  font-size: 18px;
  line-height: 22px;
}

.t_underlien1 {
  overflow: hidden;
  margin-bottom: 40px;
  padding-bottom: 60px;
  border-bottom: solid 1px #e5e5e5;
}

.subtitl1 {
  font-weight: bold;
  line-height: 22px;
  font-size: 22px;
  color: #272828;
  margin-bottom: 30px;
}
.subtitl1_1 {
  border-radius: 20px;
  background: #ff5f00;
  color: #fff;
  font-size: 18px;
  line-height: 48px;
  display: inline-block;
  height: 48px;
  padding: 0 20px;
  margin-bottom: 30px;
}
.subtitl1_2 {
  border-radius: 20px;
  background: #2282f4;
  color: #fff;
  font-size: 18px;
  line-height: 48px;
  display: inline-block;
  height: 48px;
  padding: 0 20px;
  margin-bottom: 30px;
}
ul.stock_explan3 {
  overflow: hidden;
  margin-bottom: 65px;
}
ul.stock_explan3 li {
  float: left;
  text-align: center;
  width: 25%;
}
ul.stock_explan3 li p {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
}
ul.stock_explan4 {
  overflow: hidden;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: solid 1px #e5e5e5;
}
ul.stock_explan4 li {
  float: left;
  text-align: center;
  width: 20%;
}
ul.stock_explan4 li p {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
}
ul.stock_explan5 {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: solid 1px #e5e5e5;
}
ul.stock_explan5 li:first-child {
  margin-bottom: 20px;
}
ul.stock_explan5 li {
  text-align: center;
}
ul.stock_explan6 {
  overflow: hidden;
}
ul.stock_explan6 li {
  float: left;
  width: 33%;
  text-align: center;
}
ul.stock_explan6 li p {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}
ul.stock_explan6 li p strong {
  font-weight: bold;
  color: #dd6c2a;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}

.gray_bg1 {
  background: #fafafa;
  padding: 25px 10px;
  font-size: 16px;
}
.gray_bg2 {
  background: #fafafa;
  padding: 25px 25px;
}
.gray_bg2 h6 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.gray_bg2 ul li {
  font-size: 16px;
  line-height: 28px;
}
.stock_careful1 {
  margin-top: 30px;
}
.stock_careful1 p {
  font-size: 16px;
  line-height: 26px;
  color: #666666;
}
.stock_careful1 p strong {
  color: #e83f1e;
  font-size: 16px;
  line-height: 26px;
}

.org_line {
  height: 2px;
  margin-bottom: 10px;
  padding-top: 25px;
}
ul.step_box1 {
  margin-bottom: 40px;
  padding-bottom: 40px;
  padding-top: 10px;
  overflow: hidden;
}
ul.step_box1 li:first-child {
  margin-right: 30px;
  margin-left: 5px;
}
ul.step_box1 li {
  float: left;
  width: 185px;
  height: 360px;
  margin-right: 35px;
  box-shadow: 0 0 10px #cbcbcb;
  text-align: center;
}
ul.step_box1 li:last-child {
  margin-right: 0px;
}
ul.step_box1 li dl {
  height: 172px;
}
ul.step_box1 li dl dt {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.step_pic {
  overflow: hidden;
}
.step_pic img {
  float: right;
}

.ceo_name {
  font-weight: bold;
  line-height: 18px;
  font-size: 17px;
  color: #272828;
  text-align: end;
  margin-top: 20px;
}

dl.ceo_bou {
  overflow: hidden;
  padding-bottom: 50px;
}
dl.ceo_bou dt {
  float: left;
}
dl.ceo_bou dd strong {
  font-size: 18px;
  line-height: 24px;
  color: #ff5f00;
  display: block;
  margin-bottom: 30px;
}
dl.ceo_bou dd {
  float: right;
  width: 469px;
  margin-left: 30px;
  color: #333333;
  font-size: 16px;
  line-height: 24px;
}
dl.ceo_bou dd p {
  font-size: 16px;
  line-height: 24px;
}

.gold_m_number {
  background: url(../img/sub/gold_neumber_bg.png) left top no-repeat;
  width: 439px;
  padding-left: 400px;
  height: 160px;
  padding-top: 80px;
}
.gold_m_number dl dt {
  background: url(../img/sub/gold_icon.png) left 50% no-repeat;
  padding-left: 40px;
  font-size: 28px;
  line-height: 28px;
  color: #cccccc;
  margin-bottom: 15px;
}
.gold_m_number dl dd {
  color: #fff;
  font-weight: bold;
  font-size: 52px;
  line-height: 52px;
}

ul.stroe_numlist li {
  border-bottom: solid 1px #eeeeee;
  height: 120px;
  padding-top: 30px;
  overflow: hidden;
  position: relative;
}
ul.stroe_numlist li:last-child {
  border-bottom: none;
}
ul.stroe_numlist li div {
  vertical-align: top;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #ff5f00;
  text-align: center;
  line-height: 42px;
  color: #fff;
  font-size: 20px;
  display: inline-block;
}
ul.stroe_numlist li dl {
  display: inline-block;
  width: 620px;
  margin-left: 10px;
}
ul.stroe_numlist li dl dt {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 15px;
  padding-top: 8px;
}
ul.stroe_numlist li dl dd {
  font-size: 20px;
  line-height: 24px;
}
ul.stroe_numlist li img.icon {
  position: absolute;
  top: 40px;
  right: 10px;
}
/* =============================== 유가증권 */

.seach_ovfl {
  overflow: hidden;
}
.search_area1.seach_ovfl .all_count {
  float: left;
  line-height: 42px;
}
.search_area1.seach_ovfl .se_right {
  float: right;
}

.bank_opening {
  border: solid 1px #e5e5e5;
  position: relative;
  padding: 40px 30px;
  margin-bottom: 60px;
}
/* .bank_opening:before {content:'';background:url(../img/sub/book_icon.png) left top no-repeat; width:44px; height:35px; position:absolute;} */
.bank_opening p {
  padding-left: 60px;
  line-height: 22px;
}
ul.bank_list {
  padding-bottom: 100px;
}
ul.bank_list > li {
  margin-bottom: 40px;
}
ul.bank_list > li:last-child {
  margin-bottom: 0px;
}
ul.bank_list > li dl {
  overflow: hidden;
}
ul.bank_list > li dl dt {
  font-size: 18px;
  line-height: 18px;
  float: left;
  width: 189px;
  line-height: 80px;
  text-align: center;
  font-weight: bold;
}
ul.bank_list > li.one_cont {
  line-height: 40px;
  height: 40px;
}
ul.bank_list > li.one_cont dt {
  line-height: 30px;
}
ul.bank_list > li dl dd {
  float: left;
}
/* ul.bank_list > li dl dd ul li {font-size:15px; line-height:26px; background:url(../img/sub/bl_dott1.png) left 50% no-repeat; padding-left:10px; color:#454545;} */

/* === 브랜드 소개 ==== */

ul.prd_box2 {
  padding-bottom: 50px;
  padding-top: 50px;
  overflow: hidden;
  margin-bottom: 50px;
}
ul.prd_box2 li:first-child {
  float: left;
  width: 405px;
}
ul.prd_box2 li:last-child {
  float: right;
  width: 286px;
}
ul.prd_box2:after {
  content: "";
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  clear: both;
  overflow: hidden;
}
ul.prd_box2 li:last-child h4 {
  font-size: 22px;
  line-height: 24px;
  color: #ff5f00;
  margin-bottom: 30px;
}
ul.prd_box2 li:last-child .prd_detit1 {
  border-top: solid 3px #ff5f00;
}
ul.prd_box2 li:first-child p.prd_tits1 {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
}
ul.prd_box2 li:first-child h6.prd_tits2 {
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}
ul.prd_box2 li:first-child p.prd_s1 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
ul.prd_box2 li:first-child p.prd_s2 {
  font-size: 16px;
  line-height: 24px;
}

.tab_buttons {
  display: block;
  height: 77px;
  overflow: hidden;
}
.outer .tabs .tab {
  display: none;
}
.outer .tabs .tab.active {
  display: block;
}
.tab_buttons .active {
  border-bottom: solid 2px #ff5f00;
}
.tab_buttons button {
  width: 200px;
  height: 76px;
  border: 0px;
  float: left;
  font-size: 0px;
}
.tab_buttons .active.int_img1 {
  background: url(../img/sub/brand/introduce_tab1_on.png) center 50% no-repeat;
}
.tab_buttons .int_img1 {
  background: url(../img/sub/brand/introduce_tab1_off.png) center 50% no-repeat;
}
.tab_buttons .active.int_img2 {
  background: url(../img/sub/brand/introduce_tab2_on.png) center 50% no-repeat;
}
.tab_buttons .int_img2 {
  background: url(../img/sub/brand/introduce_tab2_off.png) center 50% no-repeat;
}
.tab_buttons .active.int_img3 {
  background: url(../img/sub/brand/introduce_tab3_on.png) center 50% no-repeat;
}
.tab_buttons .int_img3 {
  background: url(../img/sub/brand/introduce_tab3_off.png) center 50% no-repeat;
}
.tab_buttons .active.int_img4 {
  background: url(../img/sub/brand/introduce_tab4_on.png) center 50% no-repeat;
}
.tab_buttons .int_img4 {
  background: url(../img/sub/brand/introduce_tab4_off.png) center 50% no-repeat;
}
.pic-slider-1 img {
  width: 100%;
  height: 262px;
}
.slider-nav-thumbnails div img {
  width: 107px;
  height: 98px;
}
.tab1s {
  display: none;
}
.tab1s.active {
  display: block;
}
.tab2s {
  display: none;
}
.tab2s.active {
  display: block;
}
.tab.active {
  display: block;
}
.busi_p1 {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
}
.busi_cont1 {
  text-align: center;
}
.chart_p1 {
  text-align: center;
  background: url(../img/sub/chart_cont2.png) left top no-repeat;
  height: 110px;
  padding-top: 50px;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
}
.garyline {
  border-bottom: solid 2px #999999;
  height: 2px;
}

/* 오시는길 테이블 */
h6.mtitl1 {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0px;
}
.mem_inpt1 {
  border-top: solid 3px #000000;
  margin-bottom: 20px;
}
.mem_inpt1 table {
  width: 100%;
}
.mem_inpt1 table tbody tr th {
  padding: 20px 0px;
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #dddddd;
}
.mem_inpt1 table tbody tr td {
  padding: 20px 0px;
  border-bottom: solid 1px #dddddd;
}

h6.mtitl2 {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0px;
}
.mem_inpt2 {
  border-top: solid 3px #000000;
  margin-bottom: 20px;
}
.mem_inpt2 table {
  width: 100%;
}
.mem_inpt2 table tbody tr th {
  padding: 20px 0px;
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #dddddd;
}
.mem_inpt2 table tbody tr td {
  padding: 20px 0px;
  border-bottom: solid 1px #dddddd;
}

/* 사업연혁 탭 */
ul.history_tab4 {
  overflow: hidden;
  height: 120px;
  margin-bottom: 35px;
  position: relative;
}
ul.history_tab4 li {
  float: left;
  width: 200px;
  height: 71px;
  line-height: 71px;
  background: #f5f5f5;
  border: solid 1px #eeeeee;
}
ul.history_tab4 li a {
  display: block;
  line-height: 71px;
  text-align: center;
  font-size: 22px;
}
ul.history_tab4 li.on a {
  font-weight: bold;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
}
ul.history_tab4 .slick-current.slick-active {
  color: #000;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
  outline: none;
}
.slick-next.slick-arrow {
  position: absolute;
  top: 80px;
  right: 0px;
  width: 36px;
  height: 36px;
  background: url(../img/sub/h_right_arrow.png) left top no-repeat;
  font-size: 0px;
  border: 0px;
  cursor: pointer;
}
.slick-prev.slick-arrow {
  position: absolute;
  top: 80px;
  right: 37px;
  width: 36px;
  height: 36px;
  background: url(../img/sub/h_left_arrow.png) left top no-repeat;
  font-size: 0px;
  border: 0px;
  cursor: pointer;
}

.history_cont {
  background: url(../img/sub/hline.png) center top repeat-y;
  min-height: 400px;
}
.history_cont dl {
  margin-bottom: 20px;
}
.history_right {
  width: 387px;
  margin-left: 450px;
  position: relative;
}
.history_right:before {
  position: absolute;
  top: 5px;
  left: -35px;
  content: "";
  width: 31px;
  height: 20px;
  background: url(../img/sub/left_d.png) left top no-repeat;
}
.history_right dt {
  font-size: 20px;
  color: #ff5f00;
  font-weight: bold;
  margin-bottom: 10px;
}
/* .history_right dd {color:#404040; font-size:16px; line-height:18px; padding-left:10px; background:url(../img/sub/bl_dott2.png) left 50% no-repeat; } */
.history_left {
  width: 400px;
  position: relative;
  margin-right: 20px;
}
.history_left:before {
  position: absolute;
  top: 5px;
  right: -35px;
  content: "";
  width: 31px;
  height: 20px;
  background: url(../img/sub/right_d.png) left top no-repeat;
}
.history_left dt {
  text-align: right;
  font-size: 20px;
  color: #ff5f00;
  font-weight: bold;
  margin-bottom: 10px;
}
.history_left dd {
  text-align: right;
  color: #404040;
  font-size: 16px;
  line-height: 18px;
}
/* .history_left dd span.ldot {width:4px; height:4px; background:url(../img/sub/bl_dott2.png) left top no-repeat; display:inline-block; padding-left:5px;} */

/* 지도 추가 기능 버튼 */
.wrap_controllers {
  overflow: hidden;
  padding: 7px 11px;
  border: 1px solid #dfdfdf;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 2px 2px;
  background-color: #f9f9f9;
}
.wrap_controllers .tit_controllers {
  float: left;
  color: #333;
  text-decoration: none;
}
.wrap_btn_roadview {
  top: 0;
  font-size: 12px;
}
.wrap_btn_roadview {
  float: right;
  position: relative;
  top: 1px;
  font-size: 11px;
}
.wrap_btn_roadview .txt {
  float: left;
  height: 15px;
  padding-top: 1px;
  line-height: 15px;
  color: #000;
  font-size: 12px;
}
.wrap_btn_roadview .txt_bar {
  width: 1px;
  padding: 0;
  margin: 0 8px 0 9px;
  height: 11px;
  vertical-align: top;
  position: relative;
  top: 2px;
  border-left: 1px solid #d0d0d0;
}
.wrap_btn_roadview > * {
  float: left;
}

/*상품소개 카테고리 on상태*/
.category.on {
  background: url(../img/common/chk_pic1.png) 90% 50% no-repeat;
  color: #ff5f00;
  border: solid 1px #ff5f00;
}

/*사업연혁 탭 수정*/
.tab_buttons.bhistory {
  display: flex;
  justify-content: center;
}
.tab_buttons.bhistory button {
  width: 25%;
  height: 76px;
  border: 0px;
  float: left;
  font-size: 22px;
  background: #f5f5f5;
  border: solid 1px #eeeeee;
}
.tab_buttons.bhistory .active {
  border: solid 2px #ff5f00;
  background-color: #fff;
}

/*유가증권버튼*/
.securitybt {
  margin-bottom: 40px;
}
.securitybt button {
  border: none;
  background-color: #ff5f00;
  border-radius: 5px;
  width: 350px;
  height: 76px;
  font-size: 22px;
}
.securitybt button a {
  color: #fff;
}

/* 시세 버튼 */
.graph_type_buttons {
  float: left;
  margin: 0 0 0;
  overflow: hidden;
}
.graph_type_buttons li {
  float: left;
  margin-left: 8px;
}
.graph_type_buttons li:first-child {
  margin-left: 0;
}
.graph_type_buttons li input {
  display: none;
}
.graph_type_buttons li label {
  display: block;
  min-width: 60px;
  height: 30px;
  padding: 0 15px;
  line-height: 29px;
  font-size: 16px;
  font-weight: 500;
  color: #999;
  border: 1px solid #999;
  text-align: center;
}
.graph_type_buttons li input:checked ~ label {
  color: #ff5f00;
  border-color: #ff5f00;
}

.graph_dt_buttons {
  float: right;
  margin: 0 0 0 0;
  overflow: hidden;
  text-align: right;
}
.graph_dt_buttons li {
  display: inline-block;
  margin-left: 15px;
}
.graph_dt_buttons li:first-child {
  margin-left: 0;
}
.graph_dt_buttons li input {
  display: none;
}
.graph_dt_buttons li label {
  display: block;
  font-size: 15px;
  color: #999;
  line-height: 31px;
}
.graph_dt_buttons li input:checked ~ label {
  color: #404040;
}

.graph_dt_calender {
  float: right;
  margin-left: 15px;
}
/* .graph_dt_calender input {width: 120px; background: url('/img/icon_calendar.png') no-repeat right -2px top 6px} */
.graph_dt_calender input:disabled {
  background-color: #e6e6e6;
  color: #999;
  height: 30px;
}
.graph_dt_calender .bar {
  margin: 0 5px;
}

.sub_graph {
  position: relative;
  height: auto;
  margin-top: 30px;
}
.sub_graph .loader_box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
}
.sub_graph .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;
  color: #999;
  font-size: 12px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sub_graph .reload {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 100px 0 0;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sub_graph .reload button {
  border-radius: 4px;
  padding: 10px 15px;
  color: #fff;
  background-color: #666;
  margin-top: 15px;
}

.sub_graph .series_tooltip {
  padding: 10px;
}
.sub_graph .series_tooltip > dt {
  padding: 10px;
  margin: -10px -10px 0;
  background-color: #f1f1f1;
}
.sub_graph .series_tooltip > dd {
  display: table;
  margin-top: 10px;
}
.sub_graph .series_tooltip > dd > dl {
  display: table-row;
}
.sub_graph .series_tooltip > dd > dl > dt,
.sub_graph .series_tooltip > dd > dl > dd {
  display: table-cell;
  padding: 5px 5px;
}
.sub_graph .series_tooltip > dd > dl > dt {
  width: 80px;
}

.ui-widget.ui-widget-content {
  z-index: 100 !important;
}

/* 다이아몬드 시세 */
.diamaond_top {
  position: relative;
  height: 300px;
  padding-top: 72px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 1.45;
  background: url(../img/img_diamaond_top_new.jpeg) center no-repeat;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.diamaond_top * {
  display: block;
}
.diamaond_top dt {
  font-size: 50px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 35px;
}
.diamaond_top dt strong {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: normal;
}

.diamaond_info_table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 95px;
  border-top: 2px solid #ddd;
}

.diamaond_info_table tbody tr:hover {
  background-color: #fff;
  box-shadow: 1px 0px 12px 3px rgba(0, 0, 0, 0.2);
}

.diamaond_info_table th,
.diamaond_info_table td {
  position: relative;
  height: 55px;
  padding-left: 15px;
  border-bottom: 1px solid #eee;
  text-align: left;
  font-size: 18px;
  color: #404040;
}

.diamaond_info_table th.number,
.diamaond_info_table td.number {
  text-indent: -9999px;
}
.diamaond_info_table td.number:after {
  display: none !important;
}

.diamaond_info_table thead th {
  background-color: #f5f5f5;
  color: #222;
}
.diamaond_info_table thead th.soldout {
  text-indent: -9999px;
}
.diamaond_info_table tbody tr.soldout td:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #ff5f00;
}
.diamaond_info_table tbody tr.soldout td.soldout:after {
  display: none;
}
.diamaond_info_table tbody tr.soldout td.soldout .flag_soldout {
  display: inline-block;
  width: 44px;
  line-height: 30px;
  background: #ff5f00;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.diamaond_block {
  margin-top: 70px;
  margin-bottom: 15px;
}

.diamaond_process_box {
  text-align: center;
}
.diamaond_process_list {
  display: inline-block;
  height: 230px;
  padding-top: 12px;
  vertical-align: top;
}
.diamaond_process_list .item {
  position: relative;
  z-index: 0;
  float: left;
  width: 150px;
  height: 150px;
  padding-top: 30px;
  margin: 0 30px;
  text-align: center;
  border-radius: 50%;
  background: #f5f5f5;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
/* .diamaond_process_list .item:after {content:""; display: block; position: absolute; left: -68px; top: 50%; width: 19px; height: 32px; margin-top: -16px; background: url(/img/arrow/arr_right_grey_big_1.png);} */
.diamaond_process_list .item:first-child:after {
  display: none;
}
.diamaond_process_list .item strong {
  display: block;
  font-size: 18px;
  color: #404040;
}
.diamaond_process_list .item strong::before {
  content: "";
  display: block;
  width: 80px;
  height: 60px;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
}
.diamaond_process_list .item_buy_1 strong::before {
  background-image: url("../img/ico_diamaond_process_1.png");
}
.diamaond_process_list .item_buy_2 strong::before {
  background-image: url("../img/ico_diamaond_process_2.png");
}
.diamaond_process_list .item_buy_3 strong::before {
  background-image: url("../img/ico_diamaond_process_3.png");
}
.diamaond_process_list .item_buy_4 strong::before {
  background-image: url("../img/ico_diamaond_process_4.png");
}
.diamaond_process_list .item_sell_1 strong::before {
  background-image: url("../img/ico_diamaond_process_1.png");
}
.diamaond_process_list .item_sell_2 strong::before {
  background-image: url("../img/ico_diamaond_process_5.png");
}
.diamaond_process_list .item_sell_3 strong::before {
  background-image: url("../img/ico_diamaond_process_6.png");
}
.diamaond_process_list .item_sell_4 strong::before {
  background-image: url("../img/ico_diamaond_process_7.png");
}
.diamaond_process_list .item span {
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  margin: -12px 0 0 -12px;
  z-index: 1;
  width: 25px;
  line-height: 25px;
  text-align: center;
  background: #ff5f00;
  color: #fff;
  border-radius: 50%;
}
.diamaond_process_list .item p {
  position: absolute;
  left: -55px;
  top: 165px;
  width: 260px;
  font-size: 13px;
  color: #666;
}

/* .diamaond_process_list .item:after {content: "";display: block;position: absolute;left: -40px;top: 50%;width: 19px;height: 32px;margin-top: -16px;background: url('/img/arr_right_grey_big_1.png') no-repeat;} */

/*국제시세*/

.price_info_table {
  border-collapse: collapse;
  width: 850px;
  margin-top: 50px;
  margin-bottom: 15px;
}

.price_info_table tbody tr:hover {
  background-color: #fff;
  box-shadow: 1px 0px 12px 3px rgba(0, 0, 0, 0.2);
}

.price_info_table th,
.price_info_table td {
  position: relative;
  height: 55px;
  padding-left: 15px;
  border-bottom: 1px solid #eee;
  text-align: center;
  font-size: 15px;
  color: #404040;
}

.price_info_table th.number,
.price_info_table td.number {
  text-indent: -9999px;
}
.price_info_table td.number:after {
  display: none !important;
}

.price_info_table thead th {
  background-color: #f5f5f5;
  color: #222;
}
.price_info_table thead th.soldout {
  text-indent: -9999px;
}
.price_info_table tbody tr.soldout td:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #ff5f00;
}
.price_info_table tbody tr.soldout td.soldout:after {
  display: none;
}
.price_info_table tbody tr.soldout td.soldout .flag_soldout {
  display: inline-block;
  width: 44px;
  line-height: 30px;
  background: #ff5f00;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

/*유튜브*/
.mainVideo {
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.mainVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

/*유가증권*/
.table_top {
  position: relative;
  margin-bottom: 10px;
  font-size: 14px;
}
.table_top b {
  font-weight: 500;
  color: #ff5f00;
}
.table_top .table_paging_size {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
}
select.table_paging_size {
  height: 31px;
  color: #717171;
  vertical-align: top;
  outline: none;
  border: 1px solid #666;
  border-radius: 5px;
}
select.table_paging_size option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

/*시세*/
.tab_buttons.bhistory .dptab {
  height: 55px;
}

/*유가증권*/
.now_price {
  width: 100%;
}
.now_price h5 {
  font-size: 22px;
  float: left;
  margin-bottom: 10px;
  font-weight: bold;
}
.now_price table {
  width: 100%;
}
.now_price table td,
.now_price table th {
  border: 1px solid #ddd;
  text-align: center;
  height: 40px;
  font-size: 17px;
}
.now_price table td {
  color: #666;
}
.now_price table .color_price {
  background-color: #ffe9da;
}
