@charset "utf-8";
.main-header{position: relative; height: auto;}
.main-header-b{height: 40px; background-color: #2A2A2A; display: none;}
.main-header-w{height: 102px; background-color: #fff;}

.p50{padding-top: 50px; padding-bottom: 50px;background:#f9f9f4;}
.visual_box {position:relative; height: 480px;}
.swiper-slide .swbox1 {position:absolute; left:50%; top:0px; margin-left:-540px; width:1080px;}
.swiper-slide dl.swi_titl1 {position:absolute; right:130px; top:110px; display:none;}
.swiper-slide dl.swi_titl1 dt span {display:block; font-size:22px; line-height:22px; margin-bottom:10px; color:#fff;}
.swiper-slide dl.swi_titl1 dt h3 {font-size:38px; line-height:38px; color:#fff; font-weight:bold;}
.swiper-slide dl.swi_titl1 dt {margin-bottom:40px;}
.swiper-slide dl.swi_titl1 dd p {font-size:16px; line-height:16px; font-weight:normal; color:#fff; line-height:28px;}
.swiper-slide dl.swi_titl2 {position:absolute; right:200px; top:110px; display:none;}
.swiper-slide dl.swi_titl2 dt span {display:block; font-size:22px; line-height:22px; margin-bottom:10px; color:#fff;}
.swiper-slide dl.swi_titl2 dt h3 {font-size:38px; line-height:38px; color:#fff; font-weight:bold;}
.swiper-slide dl.swi_titl2 dt {margin-bottom:40px;}
.swiper-slide dl.swi_titl2 dd p {font-size:16px; line-height:16px; font-weight:normal; color:#fff; line-height:28px;}
.gold_prices_wrap {position:relative; margin:0 auto; width:1080px; z-index:98;}
.gold_prices_bg {width:553px; height:484px; background:url(../img/main/num_bg1.png) left top no-repeat; position:absolute; bottom:-4px; left:0px;}
.gold_prices_bg h3 {font-size:22px; line-height:22px; margin:55px 0 75px 37px; color:#fff; font-weight:600;}
.gold_prices_box {overflow:hidden; margin-left:27px; height:350px;}
.gold_prices_box dl {float:left; width:96px;}
.gold_prices_box dl:first-child {margin-left:0px;width:75px;}
.gold_prices_box dl.first ul li {margin-bottom:45px;}
.gold_prices_box dl.first ul li:last-child {margin-bottom:0px;}
.gold_prices_box dl.first ul li {text-align:left;}
.gold_prices_box dl dt {color:#fff; font-size:20px; line-height:20px; margin-bottom:50px; text-align:center;}
.gold_prices_box dl dd ul {margin-bottom:43px;}
.gold_prices_box dl dd ul:last-child {margin-bottom:0px;}
.gold_prices_box dl dd ul li {color:#fff;font-size:18px; line-height:18px; text-align:center;}
.gold_prices_box dl:first-child dd ul li {text-align:left;}
.gold_prices_box dl dd ul li span {font-size:20px; line-height:20px;}
.gold_prices_box dl dd ul li span.counter {text-align:right; display:block;}
.gold_prices_box dl dd ul.per_num li {font-size:18px; line-height:18px; overflow:hidden;}
.gold_prices_box dl dd ul.per_num li span:first-child {float:left; margin-left:23px;}
.gold_prices_box dl dd ul.per_num li span:last-child {float:right;}
.gold_prices_box dl dd ul.per_num {margin-bottom:43px;}
.gold_prices_box dl dd ul.per_num:nth-child(2) {margin-bottom:45px;}
.gold_prices_box dl dd ul.per_num:nth-child(3) {margin-bottom:42px;}
.gold_prices_box dl dd ul.per_num li span.up { width:16px; height:16px; margin-left:10px;}
.gold_prices_box dl dd ul.per_num li span + span.counter { margin-left:5px;}
.gold_prices_box dl dd ul.per_num li span.down { width:16px; height:16px;}
.gold_prices_box dl dd ul.per_num li span.midd { width:16px; height:16px;}

.mcontainer1 {background:#f9f9f4; padding:21px 0px 0px 0px;}
.mcontainer1 table{width: 100%;min-height: 400px;}
.mcontainer1 table.tit_tabl3{min-height: 200px; margin-bottom: 40px;}
.mcontainer1 table thead tr{background-color: #ffe675 !important; border-bottom: none; font-weight: bold;}
.mcontainer1 table tr{height: 50px; text-align: center;border-bottom: 1px solid #ddd;}
/* .mcontainer1 table tr{height: 50px; text-align: center;background-color: #fff;border-bottom: 1px solid #ddd;} */

.ch_up{color:red}
.ch_dw{color:blue}

/* .mcontainer1 {background:#f9f9f4; padding:50px 0px 0px 0px;} */
.mmiddle_area1 {width:1080px; margin:0 auto;}
.mmiddle_area1 ul.pro_money1  {overflow:hidden;}
.mmiddle_area1 ul.pro_money1 > li {position:relative; float:left;  height: 467px; width:500px;}
.mmiddle_area1 ul.pro_money1 > li:first-child {margin-bottom:30px; width:500px;  height: 467px;}
.mmiddle_area1 ul.pro_money1 > li:nth-child(2) {margin-bottom:30px; width:500px;  height: 467px; float:right;}
.mmiddle_area1 ul.pro_money1 > li:nth-child(3) {margin-right:55px; width:500px; }
.mmiddle_area4 ul.pro_money1 > li:nth-child(4) {float: right; width:500px;}
.mmiddle_area1 ul.pro_money1 li h4.mdtitl1 {font-size:22px; line-height:23px; margin-bottom:20px; font-weight: 600;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 {height:357px; margin-bottom:20px;}
/* .mmiddle_area1 ul.pro_money1 li .tbl_table1:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl {float:left; width:99px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl:last-child {width:100px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:14px; padding:21px 0;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul { background:#fff;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:44px; padding-top:30px; text-align:center; overflow:hidden;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span { font-size:16px; line-height:21px; height:21px; }
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span:first-child {float:left; margin-left:15px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span:last-child {float:right; margin-right:10px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span.gray {color:#666666;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span.rdup {background:#fccdcd; display:block; padding:1px 0px; width:90px; margin:0 auto}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span.rddown {background:#d3e6fd; display:block; padding:1px 0px; width:90px; margin:0 auto}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span.blnl {font-weight:bold;}
/* .mmiddle_area1 ul.pro_money1 li .tbl_table1 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl:hover dt {width:100px; font-size:18px; line-height:18px; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span {font-weight:bold; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 li .tbl_table2 {height:387px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table2:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl {float:left; width:125px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl:last-child {width:121px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:14px; padding:15px 0;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul {background:#fff}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:43px; padding-top:25px; text-align:center; font-size:16px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span { font-size:16px; line-height:12px; font-weight:400;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span:first-child {float:left; margin-left:30px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.country.first {margin-left:14px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span:last-child {float:right; margin-right:50px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px; margin-right:0px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.fn_num1 {margin-right:40px; color:#666;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.midd_gray {color:#666666; margin-right:15px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.up_red {color:#f42222; font-weight:normal; margin-right:15px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal; margin-right:15px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal; margin-right:15px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl:hover dt {width:125px; font-size:18px; line-height:18px;  color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl:hover dd ul li span {font-weight:bold; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 li .tbl_table3 {height:387px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table3:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl {float:left; width:100px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dt {background:#707070; text-align:center; font-size:16px; line-height:16px; padding:15px 0; color:#fff;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:44px; padding-top:30px; text-align:center;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span { font-size:16px; line-height:16px; font-weight:400;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}

.mmiddle_area1 ul.pro_money1 li .tbl_table8 {height:389px; margin-bottom:17px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table8:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl {float:left; width:118px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl.last {width:141px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:16px; padding:15px 0; color:#000;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul {background:#fff}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul.txt_center li span:last-child {float:none;text-align:center !important;} 
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:18px; padding-top:19px; padding-bottom:19px; text-align:center; font-size:16px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span { font-size:16px; line-height:18px;  font-weight:400;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span:first-child {float:left; margin-left:20px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span:last-child {float:right; margin-right:20px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area1 ul.pro_money1 li .tbl_table8 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}

.mmiddle_area1 ul.pro_money1 li .tbl_table9 {height:628px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거

.mmiddle_area1 ul.pro_money1 li .tbl_table9:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt2 dt{background-color: #ffd000; height: 70px !important;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dt{background-color: #f8b721; height: 70px !important;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl {float:left; width:160px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl:last-child {width:244px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dt {text-align:center;height: 90px !important;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dt div span {font-size:16px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt1 {width:90px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt1 ul {width:88px; background:#fff;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt1 dt {background:#ffe675; text-align:center; font-size:16px; height: 70px !important; line-height: 60px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt2 dt div.tbtil2 {background:#ffd000; padding:5px 0;  font-size:16px; line-height:14px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dt div.tbtil3 {background:#f8b721; padding:5px 0;  font-size:16px; line-height:14px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dt {background:none; padding:0px; background-color: #f8b721;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt2 dt div.tbtil_c2{background:#ffd000; padding:6px 0; }
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt2 dt div.tbtil_c2 span {width:75px; display:inline-block;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dt div.tbtil_c3{background:#f8b721; text-align:center; padding:6px 0; }
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dt div.tbtil_c3 span { display:inline-block;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul {float:left;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt2 dd ul {width:80px; background:#fff;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dd ul#perG {width:80px; background:#fff;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dd ul#perDon {width:80px; background:#fff;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl.tbt3 dd ul#price {width:84px; background:#fff;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:115px; line-height: 75px !important; text-align:center; font-weight:normal; overflow:hidden;float:none; font-size:18px; line-height:14px; padding-top: 31px !important;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span { font-size:18px; line-height:75px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span img {vertical-align: middle;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span:first-child {float:left; margin-left:10px; line-height: 75px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span:last-child {float:inherit; }
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl::first-child dd ul li span {float:none;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area1 ul.pro_money1 li .tbl_table9 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}


/* .mmiddle_area1 ul.pro_money1 li .tbl_table3 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl:hover dt {width:100px; font-size:18px; line-height:18px; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl:hover dd ul li span {font-weight:bold; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money1 > li .more_btn1 {position:absolute; top:5px; right:5px; font-weight:600;}
.mmiddle_area1 ul.pro_money2 > li .more_btn1 {position:absolute; top:5px; right:5px; font-weight:600;}
.mmiddle_area1 ul.pro_money1 > li .more_btn1 a {color:#233455; font-size:16px; line-height:16px;}
.mmiddle_area1 ul.pro_money2 > li .more_btn1 a {color:#233455; font-size:16px; line-height:16px;}
.gtime {color:#666666; font-size:16px;}


.mmiddle_area1 ul.pro_money2  {overflow:hidden;}
.mmiddle_area1 ul.pro_money2 > li {position:relative; float:left;  height: 467px; width:500px;}
.mmiddle_area1 ul.pro_money2 > li:first-child {margin-bottom:30px; width:500px;  height: 467px;}
.mmiddle_area1 ul.pro_money2 > li:nth-child(2) {margin-bottom:30px; width:500px;  height: 467px; float:right;}
.mmiddle_area1 ul.pro_money2 > li:nth-child(3) {margin-right:55px; width:500px; }
.mmiddle_area4 ul.pro_money2 > li:nth-child(4) {float: right; width:500px;}
.mmiddle_area1 ul.pro_money2 li h4.mdtitl1 {font-size:22px; line-height:23px; margin-bottom:20px; font-weight: 600;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 {height:357px; margin-bottom:20px;}
/* .mmiddle_area1 ul.pro_money1 li .tbl_table1:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl {float:left; width:99px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl:last-child {width:100px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:14px; padding:21px 0;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul { background:#fff;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:44px; padding-top:30px; text-align:center; overflow:hidden;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li span { font-size:16px; line-height:21px; height:21px; }
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li span:first-child {float:left; margin-left:15px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li span:last-child {float:right; margin-right:10px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li span.gray {color:#666666;}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li span.rdup {background:#fccdcd; display:block; padding:1px 0px; width:90px; margin:0 auto}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li span.rddown {background:#d3e6fd; display:block; padding:1px 0px; width:90px; margin:0 auto}
.mmiddle_area1 ul.pro_money2 li .tbl_table1 dl dd ul li span.blnl {font-weight:bold;}
/* .mmiddle_area1 ul.pro_money1 li .tbl_table1 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl:hover dt {width:100px; font-size:18px; line-height:18px; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table1 dl dd ul li span {font-weight:bold; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money2 li .tbl_table2 {height:387px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table2:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl {float:left; width:125px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl:last-child {width:121px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:14px; padding:15px 0;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul {background:#fff}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:43px; padding-top:25px; text-align:center; font-size:16px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span { font-size:16px; line-height:12px; font-weight:400;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span:first-child {float:left; margin-left:30px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.country.first {margin-left:14px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span:last-child {float:right; margin-right:50px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px; margin-right:0px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.fn_num1 {margin-right:40px; color:#666;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.midd_gray {color:#666666; margin-right:15px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.up_red {color:#f42222; font-weight:normal; margin-right:15px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal; margin-right:15px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table2 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal; margin-right:15px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl:hover dt {width:125px; font-size:18px; line-height:18px;  color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area1 ul.pro_money1 li .tbl_table2 dl:hover dd ul li span {font-weight:bold; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money2 li .tbl_table3 {height:387px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table3:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl {float:left; width:100px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dt {background:#707070; text-align:center; font-size:16px; line-height:16px; padding:15px 0; color:#fff;}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:44px; padding-top:30px; text-align:center;}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li span { font-size:16px; line-height:16px; font-weight:400;}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area1 ul.pro_money2 li .tbl_table3 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}

.mmiddle_area1 ul.pro_money2 li .tbl_table8 {height:389px; margin-bottom:17px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area1 ul.pro_money1 li .tbl_table8:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl {float:left; width:118px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl.last {width:141px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:16px; padding:15px 0; color:#000;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul {background:#fff}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul.txt_center li span:last-child {float:none;text-align:center !important;} 
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:18px; padding-top:19px; padding-bottom:19px; text-align:center; font-size:16px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span { font-size:16px; line-height:18px;  font-weight:400;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span:first-child {float:left; margin-left:20px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span:last-child {float:right; margin-right:20px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area1 ul.pro_money2 li .tbl_table8 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}

.mmiddle_area1 ul.pro_money2 li .tbl_table9 {height:360px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거

.mmiddle_area1 ul.pro_money1 li .tbl_table9:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt2 dt{background-color: #ffd000}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dt{background-color: #f8b721;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl {float:left; width:160px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl:last-child {width:244px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dt {text-align:center;height: 60px !important;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dt div span {font-size:16px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt1 {width:90px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt1 ul {width:90px; background:#fff;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt1 dt {background:#ffe675; text-align:center; font-size:16px; height: 60px; line-height: 60px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt2 dt div.tbtil2 {background:#ffd000; padding:5px 0;  font-size:16px; line-height:14px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dt div.tbtil3 {background:#f8b721; padding:5px 0;  font-size:16px; line-height:14px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dt {background:none; padding:0px; background-color: #f8b721;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt2 dt div.tbtil_c2{background:#ffd000; padding:6px 0; }
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt2 dt div.tbtil_c2 span {width:75px; display:inline-block;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dt div.tbtil_c3{background:#f8b721; text-align:center; padding:6px 0; }
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dt div.tbtil_c3 span {width:31%; display:inline-block;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul {float:left;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt2 dd ul {width:80px; background:#fff;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dd ul#perG {width:80px; background:#fff;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dd ul#perDon {width:80px; background:#fff;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl.tbt3 dd ul#price {width:84px; background:#fff;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:75px; line-height: 75px !important; text-align:center; font-weight:normal; overflow:hidden;float:none; font-size:16px; line-height:14px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span { font-size:16px; line-height:75px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span img {vertical-align: middle;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span:first-child {float:left; margin-left:10px; line-height: 75px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span:last-child {float:right; margin-right: 7px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl::first-child dd ul li span {float:none;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area1 ul.pro_money2 li .tbl_table9 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}


.mcontainer2 {background:#f9f9f4; padding:50px 0px 0px 0px;}
.mmiddle_area2 {width:1080px; margin:0 auto;}
.htitl3 {font-size:22px; line-height:22px; color:#000000; font-weight:600}
ul.inter_chart1 {overflow:hidden;}
ul.inter_chart1 li {float:left; width:500px; }
/*ul.inter_chart1 li:nth-child(1), ul.inter_chart1 li:nth-child(2) {margin-bottom:40px;}*/
ul.inter_chart1 li:nth-child(2) {float:right;}
ul.inter_chart1 li:nth-child(4) {float:right;}
ul.inter_chart1 li h4.htitl3 {position:relative; margin-bottom:20px;}
ul.inter_chart1 li h4.htitl3 .more_btn {position:absolute; top:5px; right:0px; font-size:16px;}
ul.inter_chart1 li .prod_list1 {overflow:hidden; margin-bottom:20px;}
ul.inter_chart1 li .prod_list1 p{float:left; background:#dddddd; color:#fff; border-radius:3px; padding:5px 10px; margin-left:10px; cursor:pointer;}
ul.inter_chart1 li .prod_list1 p.on {background:#ff5f00;}
ul.inter_chart1 li .day_wrap {overflow:hidden;}
ul.inter_chart1 li .day_list1 {float:right}
ul.inter_chart1 li .day_list1 p {float:left; margin-left:10px; color:#999999; cursor:pointer;}
ul.inter_chart1 li .day_list1 p.on {font-weight:bold;color:#000;}

.mcontainer3 {background:#e8e8e8 url(../img/main/md_bg1.png) left top no-repeat; background-size:cover; width:1920px; height:545px ;margin:50px auto;}
.mmiddle_area3 {width:1080px; margin:0 auto; position:relative;}
.mmiddle_area3 .mdtitl1 {padding-top:50px;}
.mmiddle_area3 .mdtitl1 .htitl3 {font-size:22px; line-height:22px; color:#000000; font-weight:600}
.mmiddle_area3 .tab1  {overflow:hidden;}
.mmiddle_area3 .tab1 dl:first-child {margin-left:0px;}
.mmiddle_area3 .tab1 dl {float:left; width:200px; height:345px; margin-left:20px; background:#ffffff;}
.mmiddle_area3 .tab1 dl dt a {width:200px; height:200px; display:block;}
.mmiddle_area3 .tab1 dl dt a img {width:200px; height:200px;}
.mmiddle_area3 .tab1 dl dd {padding:20px; width:160px; height:80px;}
.mmiddle_area3 .tab1 dl dd a {font-size:14px; color:#333333; display:block;}
.mmiddle_area3 .tab1 dl dd a.gold_num1 {font-size:14px; line-height:18px; margin-bottom:10px;}
.mmiddle_area3 .tab1 dl dd a.gold_num2 {font-size:18px; line-height:18px; font-weight:bold;}
.mmiddle_area3 .more_btn2 {position:absolute; top:55px; right:10px;}
.mmiddle_area3 .more_btn2 a {color:#666666; font-size:16px; line-height:16px;}
.mmiddle_area3 .more_btn2 a::after {content:''; margin-left:10px; padding-right:20px; background:url(../img/common/tit_arrow2.png) left 50% no-repeat;}
.tab_buttons1 {margin:2rem 0px;}
.tab_buttons1 button {background:none; font-size:18px; line-height:18px; color:#999999; border:0px; outline:0px; cursor:pointer;}
.tab_buttons1 .active {font-size:18px; line-height:18px; font-weight:600;color:#121212;}
.tab1 {display:none;}
.tab1.active {display:block;}

.mcontainer4 {background:#f9f9f4; padding:50px 0px 50px 0px;}
.mmiddle_area4 {width:1080px; margin:0 auto;}
.mmiddle_area4 ul.pro_money2  {overflow:hidden;}
.mmiddle_area4 ul.pro_money2 > li {width:500px; position:relative;  height: 467px;}
.mmiddle_area4 ul.pro_money2 > li:nth-child(2) {width:500px;}
.mmiddle_area4 ul.pro_money2 > li:first-child {margin-right:33px; margin-bottom:100px; margin-left:5px; float:left; float:left;}
.mmiddle_area4 ul.pro_money2 > li:nth-child(2) {margin-bottom:100px; float: right}
.mmiddle_area4 ul.pro_money2 > li:nth-child(3) {margin-right:33px; margin-left:5px; float:left;}
.mmiddle_area4 ul.pro_money2 > li:nth-child(4) {float: right}
.mmiddle_area4 ul.pro_money2 > li:nth-child(3) h4.mdtitl1 {font-size:22px; line-height:22px; margin-bottom:20px; font-weight: 600; float:left; width:100px}
.mmiddle_area4 ul.pro_money2 li h4.mdtitl1 {font-size:22px; line-height:22px; margin-bottom:20px; font-weight: 600;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 {height:192px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area4 ul.pro_money2 li .tbl_table4:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl {float:left; width:131px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:16px; padding:15px 0;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul {background:#fff;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li {line-height: 12px; border-bottom:solid 1px #d7d7d7; height:44px; padding-top:30px; text-align:center; font-size:16px; overflow:hidden;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li span { font-size:16px; line-height:12px; height:21px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li span:first-child {float:left; margin-left:28px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li span:last-child {float:right; margin-right:28px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li span.gray {color:#666666;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li span.rdup {background:#fccdcd; display:block; padding:1px 0px; font-weight:bold; width:90px; margin:0 auto}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li span.rddown {background:#d3e6fd; display:block; padding:1px 0px; font-weight:bold; width:90px; margin:0 auto}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl dd ul li span.blnl {font-weight:bold;}
/* .mmiddle_area4 ul.pro_money2 li .tbl_table4 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl:hover dt {width:132px; font-size:18px; line-height:18px; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table4 dl:hover dd ul li span {font-weight:bold; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 li .tbl_table5 {height:378px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area4 ul.pro_money2 li .tbl_table5:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl {float:left; width:125.5px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:16px; padding:15px 0;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul {background:#fff;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li {line-height: 12px; border-bottom:solid 1px #d7d7d7; height:40px; padding-top:25px; text-align:center; font-size:16px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span:first-child {float:left; margin-left:20px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span:last-child {float:right; margin-right:20px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span { font-size:16px; line-height:12px; font-weight:400; display:block; text-align:center;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px; float:none;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span.midd_gray {color:#666666; float:none;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span.up_red {color:#f42222; font-weight:normal; float:none;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal; float:none;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal; float:none;}
/* .mmiddle_area4 ul.pro_money2 li .tbl_table5 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl:hover dt {width:126px; font-size:18px; line-height:18px;  color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table5 dl:hover dd ul li span {font-weight:bold; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 li .tbl_table6 {height:346px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area4 ul.pro_money2 li .tbl_table6:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl {float:left; width:175px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:16px; padding:15px 0; color:#000;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul {background:#fff}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:44px; padding-top:30px; text-align:center; font-size:16px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li span { font-size:16px; line-height:12px; font-weight:400;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 10px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}
/* .mmiddle_area4 ul.pro_money2 li .tbl_table6 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl:hover dt {width:175px; font-size:18px; line-height:18px; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table6 dl:hover dd ul li span {font-weight:bold; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 li .tbl_table7 {height:348px; margin-bottom:20px;}
/* 마우스 호버시 확대 효과 제거
.mmiddle_area4 ul.pro_money2 li .tbl_table7:hover {z-index: 1;border:solid 2px #ffe675; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.3); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl {float:left; width:125px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dt {background:#ffe675; text-align:center; font-size:16px; line-height:16px; padding:15px 0; color:#000;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul {background:#fff;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li {border-bottom:solid 1px #d7d7d7; height:44px; padding-top:30px; text-align:center; font-size:16px; line-height:12px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span { font-size:15px; line-height:12px; font-weight:400;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span:first-child {float:left; margin-left:25px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span:last-child {float:right; margin-right:24px;line-height: 16px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span.country {font-weight:normal;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span.gray {color:#666666; font-weight:normal; display:inline-block; padding:0 5px;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span.midd_gray {color:#666666;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span.up_red {color:#f42222; font-weight:normal}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span.dw_blue {color:#2282f4; font-weight:normal}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl dd ul li span.midd_gray {color:#2282f4; font-weight:normal}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl:nth-child(4) {width:127px}
/* .mmiddle_area4 ul.pro_money2 li .tbl_table7 dl:hover {z-index: 1; box-shadow: 2px 4px 5px 5px rgba(0,0,0,0.08); transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl:hover dt {width:125px; font-size:18px; line-height:18px; color: #fff; background:#454545; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;}
.mmiddle_area4 ul.pro_money2 li .tbl_table7 dl:hover dd ul li span {font-weight:bold; transition: all 0.4s ease-out; -ms-transition: all 0.4s ease-out; -webkit-transition: all 0.4s ease-out; -moz-transition: all 0.4s ease-out;} */
.mmiddle_area4 ul.pro_money2 > li .more_btn1 {position:absolute; top:5px; right:5px;}
.mmiddle_area4 ul.pro_money2 > li .more_btn1 a {color:#666666; font-size:16px; line-height:16px;}

.m4_time{margin-top: -10px;}
h4.mdtitl1 {width:1080px; margin:0 auto; font-size:22px; line-height:23px; margin-bottom:20px; font-weight: 600;}
.mcontainer5 {background:#f9f9f4; padding:50px 0px 50px 0px;}
.mmiddle_area5 {width:1080px; margin:0 auto;}
.table_type1 {border-top:solid 2px #313131; overflow:hidden;}
.table_type1 table {width:360px;float:left;}
.table_type1 table tbody tr th {text-align:center; border-bottom:solid 1px #d1d1d1; height:90px; background:#fff;}
.table_type1 table tbody tr th dl dt {font-size:16px; line-height:18px; color:#232323; margin-bottom:15px;}
.table_type1 table tbody tr th dl dd span.rdup {background:#fccdcd; display:inline-block; padding:4px 0px;width:95px; text-align:center; margin:0 auto; font-size:18px;}
.table_type1 table tbody tr th dl dd span.rddown {background:#d3e6fd; display:inline-block; padding:2px 0px; width:95px; text-align:center; margin:0 auto; font-size:18px;}
.table_type1 table tbody tr th dl dd span {font-weight:normal; font-size:18px; line-height:18px;}
.table_type1 table tbody tr th.tal {padding:0px 10px; text-align:left;}
.table_type1 table tbody tr th.tar {padding:0px 10px; text-align:right;}
.table_type1 table tbody tr td {padding:20px 0px; line-height:16px; text-align:center; color:#666666; vertical-align:middle; background:#fff;}
.table_type1 table tbody tr.bdmlin td { border-bottom:solid 1px #d1d1d1}
.table_type1 table tbody tr td.tal {padding:0px 10px; text-align:left;}   
.table_type1 table tbody tr td.tar {padding:0px 10px; text-align:right;} 
.table_type1 table tbody tr td.tarCreateAt {padding:15px 21px 0px 10px; text-align:right;}   
.table_type1 table tbody tr.bdmlin td img {float:left;}
.table_type1 table tbody tr th dl dd span.gray {color:#232323; display:inline-block; margin-left:3px; font-weight:400; font-size:14px;}


.mcontainer6 {background:#f9f9f4; padding:50px 0px 50px 0px;}
.mmiddle_area6 {width:1080px; margin:0 auto; overflow:hidden;}
.mmiddle_area6 .news_table1, .mmiddle_area6 .notice_table1 {float:left; width:49%;}
.mmiddle_area6 .news_table1 {margin-right:2%;}
.mmiddle_area6 .news_table1 .mdtitl2 {font-size:22px; line-height:22px; font-weight:bold; margin-bottom:20px;}
.mmiddle_area6 .news_table1 .mdtitl2:after {content:''; padding-left:15px; background:url(../img/common/tit_arrow2.png) right 50% no-repeat;}
.mmiddle_area6 .news_table1 dl {overflow:hidden; padding:15px 0px; /* border-bottom:solid 1px #dddddd; */} 
.mmiddle_area6 .news_table1 dl dt {float:left; font-size:12px; text-align:center; color:#666666}
.mmiddle_area6 .news_table1 dl dt strong {font-size:24px; line-height:24px; margin-bottom:10px; display:block;}
.mmiddle_area6 .news_table1 dl dd {float:left; line-height:35px; margin-top:5px; margin-left:30px;}
.mmiddle_area6 .notice_table1 .mdtitl2 {font-size:22px; line-height:22px; font-weight:bold; margin-bottom:20px;}
.mmiddle_area6 .notice_table1 .mdtitl2:after {content:''; padding-left:15px; background:url(../img/common/tit_arrow2.png) right 50% no-repeat;}
.mmiddle_area6 .notice_table1 dl {overflow:hidden; padding:15px 0px; /* border-bottom:solid 1px #dddddd; */}
.mmiddle_area6 .notice_table1 dl dt {float:left; font-size:12px; text-align:center; color:#666666}
.mmiddle_area6 .notice_table1 dl dt strong {font-size:24px; line-height:24px; margin-bottom:10px; display:block;}
.mmiddle_area6 .notice_table1 dl dd {float:left; line-height:35px; margin-top:5px; margin-left:30px;}
.new_i {display:inline-block; margin-top:8px; margin-left:3px}

.mcontainer7 {background:#f9f9f4;}
.mmiddle_area7 {width:1080px; margin:0 auto; overflow:hidden;}
.bottom_banner {overflow:hidden;}
.bottom_banner ul li {float:left; width:49%; text-align:center;}
.bottom_banner ul li a img{ width:100%; height:auto;}
.bottom_banner ul li:nth-child(1), .bottom_banner ul li:nth-child(2) {margin-bottom:20px;}
.bottom_banner ul li:nth-child(1),.bottom_banner ul li:nth-child(3) {margin-right:20px;}


.mcontainer8 {background:#f9f9f4; padding:50px 0px 50px 0px;}
.mmiddle_area8 {width:1080px; margin:0 auto; overflow:hidden;}
.mmiddle_area8 .main_title {position: relative;min-height: 28px;font-size: 28px;font-weight: bold;color: #000;line-height: 1;}
.mmiddle_area8 .main_title .btn_main_more {position: absolute;right: 0;top: 1px;}
/* .mmiddle_area8 .btn_main_more {padding-right: 17px;font-size: 16px;font-weight: 300;color: #8a8a8a;line-height: 1.6;background: url(../img/arr_right_grey_1.png) right 8px no-repeat;} */
.main_magazine_list {margin: 20px 0 0 -20px;}
.main_magazine_list .item {position: relative;display: block;float: left;width: 255px; margin: 0 0 0 20px;}
.main_magazine_list .item-inner {padding: 0;margin: 0;}
.main_magazine_list .item-img {display: block;width: 255px;}
.main_magazine_list .link {display: block;position: absolute;left: 0;top: 0;width: 100%;height: 100%;text-indent: -9999px;}
.main_magazine_list .item-info .date {display: block;margin: 0 5px;position: absolute;left: 0;color: #666;}
.main_magazine_list .item-info .subject {margin: 5px 0 0;font-size: 20px;font-weight: 500;line-height: 70px;color: #000;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
figure {display: block;margin-block-start: 1em;margin-block-end: 1em;margin-inline-start: 40px;margin-inline-end: 40px;}





.mmiddle_area1 ul.pro_money1 li .tbl_table3 dl dd ul li span.sfwb {font-size:12px; display:block; font-weight:normal; color:#333;}
/* .swiper-slide {text-align:center;} */
.swiper-slide img {max-width:none;}

.ldnfix {overflow:hidden;}
.ldnfix h4 {float:left;}
.ldnfix .ldn_m {float:left; margin-left:10px; line-height:22px; letter-spacing:-1px;}
.redfn {color:#ff0000;}
.redfn img {vertical-align:middle;}

.usd_area1 { padding:10px 15px; width:1080px; margin:10px auto; border-radius:5px; }
.usd_area1 h4 {float:left; margin-right:20px;}
ul.usd_box {overflow:hidden;}
ul.usd_box li {float:left; margin-left:40px;}
ul.usd_box li dl dt {float:left; width:100px;}
ul.usd_box li dl dd {float:left; margin-left:40px; width:100px}
ul.usd_box li dl dd:nth-child(3) {width:125px;}
ul.usd_box li dl dd:last-child {width:270px}
.banner_img1 {margin-bottom:20px;}
.apexcharts-canvas {position: relative;user-select: none; /* cannot give overflow: hidden as it will crop tooltips which overflow outside chart area */}
/* scrollbar is not visible by default for legend, hence forcing the visibility */
.apexcharts-canvas ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
  
  .apexcharts-canvas ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  
  
  .apexcharts-inner {
    position: relative;
  }
  
  .apexcharts-text tspan {
    font-family: inherit;
  }
  
  .legend-mouseover-inactive {
    transition: 0.15s ease all;
    opacity: 0.20;
  }
  
  .apexcharts-series-collapsed {
    opacity: 0;
  }
  
  .apexcharts-tooltip {
    border-radius: 5px;
    box-shadow: 2px 2px 6px -4px #999;
    cursor: default;
    font-size: 14px;
    left: 62px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    z-index: 12;
    transition: 0.15s ease all;
  }
  
  .apexcharts-tooltip.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
  }
  
  .apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid #e3e3e3;
    background: rgba(255, 255, 255, 0.96);
  }
  
  .apexcharts-tooltip.apexcharts-theme-dark {
    color: #fff;
    background: rgba(30, 30, 30, 0.8);
  }
  
  .apexcharts-tooltip * {
    font-family: inherit;
  }
  
  
  .apexcharts-tooltip-title {
    padding: 6px;
    font-size: 15px;
    margin-bottom: 4px;
  }
  
  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #ECEFF1;
    border-bottom: 1px solid #ddd;
  }
  
  .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
    background: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid #333;
  }
  
  .apexcharts-tooltip-text-value,
  .apexcharts-tooltip-text-z-value {
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
  }
  
  .apexcharts-tooltip-text-z-label:empty,
  .apexcharts-tooltip-text-z-value:empty {
    display: none;
  }
  
  .apexcharts-tooltip-text-value,
  .apexcharts-tooltip-text-z-value {
    font-weight: 600;
  }
  
  .apexcharts-tooltip-marker {
    width: 12px;
    height: 12px;
    position: relative;
    top: 0px;
    margin-right: 10px;
    border-radius: 50%;
  }
  
  .apexcharts-tooltip-series-group {
    padding: 0 10px;
    display: none;
    text-align: left;
    justify-content: left;
    align-items: center;
  }
  
  .apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
    opacity: 1;
  }
  
  .apexcharts-tooltip-series-group.apexcharts-active,
  .apexcharts-tooltip-series-group:last-child {
    padding-bottom: 4px;
  }
  
  .apexcharts-tooltip-series-group-hidden {
    opacity: 0;
    height: 0;
    line-height: 0;
    padding: 0 !important;
  }
  
  .apexcharts-tooltip-y-group {
    padding: 6px 0 5px;
  }
  
  .apexcharts-tooltip-candlestick {
    padding: 4px 8px;
  }
  
  .apexcharts-tooltip-candlestick>div {
    margin: 4px 0;
  }
  
  .apexcharts-tooltip-candlestick span.value {
    font-weight: bold;
  }
  
  .apexcharts-tooltip-rangebar {
    padding: 5px 8px;
  }
  
  .apexcharts-tooltip-rangebar .category {
    font-weight: 600;
    color: #777;
  }
  
  .apexcharts-tooltip-rangebar .series-name {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .apexcharts-xaxistooltip {
    opacity: 0;
    padding: 9px 10px;
    pointer-events: none;
    color: #373d3f;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    background: #ECEFF1;
    border: 1px solid #90A4AE;
    transition: 0.15s ease all;
  }
  
  .apexcharts-xaxistooltip.apexcharts-theme-dark {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  
  .apexcharts-xaxistooltip:after,
  .apexcharts-xaxistooltip:before {
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .apexcharts-xaxistooltip:after {
    border-color: rgba(236, 239, 241, 0);
    border-width: 6px;
    margin-left: -6px;
  }
  
  .apexcharts-xaxistooltip:before {
    border-color: rgba(144, 164, 174, 0);
    border-width: 7px;
    margin-left: -7px;
  }
  
  .apexcharts-xaxistooltip-bottom:after,
  .apexcharts-xaxistooltip-bottom:before {
    bottom: 100%;
  }
  
  .apexcharts-xaxistooltip-top:after,
  .apexcharts-xaxistooltip-top:before {
    top: 100%;
  }
  
  .apexcharts-xaxistooltip-bottom:after {
    border-bottom-color: #ECEFF1;
  }
  
  .apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: #90A4AE;
  }
  
  .apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after {
    border-bottom-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
    border-bottom-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-xaxistooltip-top:after {
    border-top-color: #ECEFF1
  }
  
  .apexcharts-xaxistooltip-top:before {
    border-top-color: #90A4AE;
  }
  
  .apexcharts-xaxistooltip-top.apexcharts-theme-dark:after {
    border-top-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-xaxistooltip-top.apexcharts-theme-dark:before {
    border-top-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-xaxistooltip.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
  }
  
  .apexcharts-yaxistooltip {
    opacity: 0;
    padding: 4px 10px;
    pointer-events: none;
    color: #373d3f;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    background: #ECEFF1;
    border: 1px solid #90A4AE;
  }
  
  .apexcharts-yaxistooltip.apexcharts-theme-dark {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  
  .apexcharts-yaxistooltip:after,
  .apexcharts-yaxistooltip:before {
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .apexcharts-yaxistooltip:after {
    border-color: rgba(236, 239, 241, 0);
    border-width: 6px;
    margin-top: -6px;
  }
  
  .apexcharts-yaxistooltip:before {
    border-color: rgba(144, 164, 174, 0);
    border-width: 7px;
    margin-top: -7px;
  }
  
  .apexcharts-yaxistooltip-left:after,
  .apexcharts-yaxistooltip-left:before {
    left: 100%;
  }
  
  .apexcharts-yaxistooltip-right:after,
  .apexcharts-yaxistooltip-right:before {
    right: 100%;
  }
  
  .apexcharts-yaxistooltip-left:after {
    border-left-color: #ECEFF1;
  }
  
  .apexcharts-yaxistooltip-left:before {
    border-left-color: #90A4AE;
  }
  
  .apexcharts-yaxistooltip-left.apexcharts-theme-dark:after {
    border-left-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-yaxistooltip-left.apexcharts-theme-dark:before {
    border-left-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-yaxistooltip-right:after {
    border-right-color: #ECEFF1;
  }
  
  .apexcharts-yaxistooltip-right:before {
    border-right-color: #90A4AE;
  }
  
  .apexcharts-yaxistooltip-right.apexcharts-theme-dark:after {
    border-right-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-yaxistooltip-right.apexcharts-theme-dark:before {
    border-right-color: rgba(0, 0, 0, 0.5);
  }
  
  .apexcharts-yaxistooltip.apexcharts-active {
    opacity: 1;
  }
  
  .apexcharts-yaxistooltip-hidden {
    display: none;
  }
  
  .apexcharts-xcrosshairs,
  .apexcharts-ycrosshairs {
    pointer-events: none;
    opacity: 0;
    transition: 0.15s ease all;
  }
  
  .apexcharts-xcrosshairs.apexcharts-active,
  .apexcharts-ycrosshairs.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
  }
  
  .apexcharts-ycrosshairs-hidden {
    opacity: 0;
  }
  
  .apexcharts-selection-rect {
    cursor: move;
  }
  
  .svg_select_boundingRect, .svg_select_points_rot {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }
  .apexcharts-selection-rect + g .svg_select_boundingRect,
  .apexcharts-selection-rect + g .svg_select_points_rot {
    opacity: 0;
    visibility: hidden;
  }
  
  .apexcharts-selection-rect + g .svg_select_points_l,
  .apexcharts-selection-rect + g .svg_select_points_r {
    cursor: ew-resize;
    opacity: 1;
    visibility: visible;
  }
  
  .svg_select_points {
    fill: #efefef;
    stroke: #333;
    rx: 2;
  }
  
  .apexcharts-svg.apexcharts-zoomable.hovering-zoom {
    cursor: crosshair
  }
  
  .apexcharts-svg.apexcharts-zoomable.hovering-pan {
    cursor: move
  }
  
  .apexcharts-zoom-icon,
  .apexcharts-zoomin-icon,
  .apexcharts-zoomout-icon,
  .apexcharts-reset-icon,
  .apexcharts-pan-icon,
  .apexcharts-selection-icon,
  .apexcharts-menu-icon,
  .apexcharts-toolbar-custom-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 24px;
    color: #6E8192;
    text-align: center;
  }
  
  .apexcharts-zoom-icon svg,
  .apexcharts-zoomin-icon svg,
  .apexcharts-zoomout-icon svg,
  .apexcharts-reset-icon svg,
  .apexcharts-menu-icon svg {
    fill: #6E8192;
  }
  
  .apexcharts-selection-icon svg {
    fill: #444;
    transform: scale(0.76)
  }
  
  .apexcharts-theme-dark .apexcharts-zoom-icon svg,
  .apexcharts-theme-dark .apexcharts-zoomin-icon svg,
  .apexcharts-theme-dark .apexcharts-zoomout-icon svg,
  .apexcharts-theme-dark .apexcharts-reset-icon svg,
  .apexcharts-theme-dark .apexcharts-pan-icon svg,
  .apexcharts-theme-dark .apexcharts-selection-icon svg,
  .apexcharts-theme-dark .apexcharts-menu-icon svg,
  .apexcharts-theme-dark .apexcharts-toolbar-custom-icon svg {
    fill: #f3f4f5;
  }
  
  .apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg,
  .apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
  .apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg {
    fill: #008FFB;
  }
  
  .apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
  .apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
  .apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
  .apexcharts-theme-light .apexcharts-zoomout-icon:hover svg,
  .apexcharts-theme-light .apexcharts-reset-icon:hover svg,
  .apexcharts-theme-light .apexcharts-menu-icon:hover svg {
    fill: #333;
  }
  
  .apexcharts-selection-icon,
  .apexcharts-menu-icon {
    position: relative;
  }
  
  .apexcharts-reset-icon {
    margin-left: 5px;
  }
  
  .apexcharts-zoom-icon,
  .apexcharts-reset-icon,
  .apexcharts-menu-icon {
    transform: scale(0.85);
  }
  
  .apexcharts-zoomin-icon,
  .apexcharts-zoomout-icon {
    transform: scale(0.7)
  }
  
  .apexcharts-zoomout-icon {
    margin-right: 3px;
  }
  
  .apexcharts-pan-icon {
    transform: scale(0.62);
    position: relative;
    left: 1px;
    top: 0px;
  }
  
  .apexcharts-pan-icon svg {
    fill: #fff;
    stroke: #6E8192;
    stroke-width: 2;
  }
  
  .apexcharts-pan-icon.apexcharts-selected svg {
    stroke: #008FFB;
  }
  
  .apexcharts-pan-icon:not(.apexcharts-selected):hover svg {
    stroke: #333;
  }
  
  .apexcharts-toolbar {
    position: absolute;
    z-index: 11;
    max-width: 176px;
    text-align: right;
    border-radius: 3px;
    padding: 0px 6px 2px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .apexcharts-menu {
    background: #fff;
    position: absolute;
    top: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px;
    right: 10px;
    opacity: 0;
    min-width: 110px;
    transition: 0.15s ease all;
    pointer-events: none;
  }
  
  .apexcharts-menu.apexcharts-menu-open {
    opacity: 1;
    pointer-events: all;
    transition: 0.15s ease all;
  }
  
  .apexcharts-menu-item {
    padding: 6px 7px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .apexcharts-theme-light .apexcharts-menu-item:hover {
    background: #eee;
  }
  
  .apexcharts-theme-dark .apexcharts-menu {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
  }
  
  @media screen and (min-width: 768px) {
    .apexcharts-canvas:hover .apexcharts-toolbar {
      opacity: 1;
    }
  }
  
  .apexcharts-datalabel.apexcharts-element-hidden {
    opacity: 0;
  }
  
  .apexcharts-pie-label,
  .apexcharts-datalabels,
  .apexcharts-datalabel,
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    cursor: default;
    pointer-events: none;
  }
  
  .apexcharts-pie-label-delay {
    opacity: 0;
    animation-name: opaque;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
  
  .apexcharts-canvas .apexcharts-element-hidden {
    opacity: 0;
  }
  
  .apexcharts-hide .apexcharts-series-points {
    opacity: 0;
  }
  
  .apexcharts-gridline,
  .apexcharts-annotation-rect,
  .apexcharts-tooltip .apexcharts-marker,
  .apexcharts-area-series .apexcharts-area,
  .apexcharts-line,
  .apexcharts-zoom-rect,
  .apexcharts-toolbar svg,
  .apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
  .apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
  .apexcharts-radar-series path,
  .apexcharts-radar-series polygon {
    pointer-events: none;
  }
  
  
  /* markers */
  
  .apexcharts-marker {
    transition: 0.15s ease all;
  }
  
  @keyframes opaque {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  
  /* Resize generated styles */
  
  @keyframes resizeanim {
    from {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }
  
  .resize-triggers {
    animation: 1ms resizeanim;
    visibility: hidden;
    opacity: 0;
  }
  
  .resize-triggers,
  .resize-triggers>div,
  .contract-trigger:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  
  .resize-triggers>div {
    background: #eee;
    overflow: auto;
  }
  
  .contract-trigger:before {
    width: 200%;
    height: 200%;
  }