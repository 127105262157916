@charset "utf-8";
dl.price_titl1 dt {
  font-size: 2.2rem;
  line-height: 2.2rem;
  margin-bottom: 3%;
}
dl.price_titl1 dd {
  font-size: 1.8rem;
  line-height: 1.9rem;
}
dl.price_titl2 dt {
  font-size: 2.2rem;
  line-height: 2.2rem;
  margin-bottom: 3%;
  color: #fff;
}
dl.price_titl2 dd {
  font-size: 1.8rem;
  line-height: 1.9rem;
  color: #fff;
}
.p_titl1 {
  font-size: 1.714rem;
  text-align: center;
  margin-bottom: 5%;
  margin-top: 5%;
  font-weight: 700;
}
.today_sock {
  overflow: hidden;
  margin-bottom: 3%;
}
.today_sock h6 {
  float: left;
  font-size: 1.857rem;
  line-height: 1.857rem;
}
.today_sock p {
  float: right;
}
.search_btn {
  padding: 5px 10px;
  border: solid 1px #dddddd;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}

.today_money {
  border-radius: 10px;
  border: solid 1px #eeeeee;
  background: #fafafa;
  padding: 15px;
  overflow: hidden;
}
.today_money h6 {
  font-size: 1.286rem;
  line-height: 1.286rem;
  font-weight: bold;
  margin-bottom: 5%;
}
.today_money h6.gold {
  color: #ff5f00;
}
.today_money h6.sliver {
  color: #0096ff;
}
.today_money h6.Bronze {
  color: #2c9688;
}
.today_money ul li {
  overflow: hidden;
}
.today_money ul li.prev_day span {
  color: #999;
}
.today_money ul li.prev_day span:first-child {
  float: left;
}
.today_money ul li.prev_day span:last-child {
  float: right;
}
.today_money ul li dl {
  overflow: hidden;
  margin-bottom: 5%;
}
.today_money ul li dl dt {
  float: left;
  font-size: 1.714rem;
  line-height: 1.714rem;
  color: #000;
}
.today_money ul li dl dd {
  float: right;
  color: #999999;
}
.today_sk1 .swiper-slide {
  width: 80%;
}

.day_inquiry {
  text-align: right;
  margin: 10px 0px;
  height: 30px;
  border-radius: 5px;
}
.day_inquiry span {
  border: solid 1px #dddddd;
  height: 25px;
  display: inline-block;
  padding-right: 5px;
  padding-top: 3px;
}
.day_inquiry a {
  vertical-align: middle;
}

/* 귀금속 테이블 */
.m_gold_table1 {
  border-top: solid 2px #eeeeee;
  border-bottom: solid 1px #eeeeee;
}
.m_gold_table1 table {
  width: 100%;
}
.m_gold_table1 table thead th {
  background: #fff8f4;
  padding: 15px 0px;
  text-align: center;
}
.m_gold_table1 table tbody th {
  background: #fafafa;
  padding: 15px 0px;
  text-align: center;
}
.m_gold_table1 table tbody td {
  padding: 15px 0px;
  text-align: center;
}

/* 기념품 */
.rem_titl1 {
  margin-bottom: 2rem;
}
/* .rem_titl1 h5 {text-align:center; background:url(../img/common/rem_underline.png) center bottom no-repeat; font-weight:bold; padding-bottom:1rem; margin-bottom:1rem; font-size:1.4rem;} */
.rem_titl1 p {
  text-align: center;
  font-size: 1rem;
  line-height: 20px;
}
.rem_titl1 p.orage {
  color: #ff5f00;
}
.rem_plist1 {
  overflow: hidden;
  margin-bottom: 2rem;
}
.rem_plist1 dl {
  float: left;
  width: 50%;
  text-align: center;
  margin-bottom: 1.3rem;
}
.rem_plist1 dl dt {
  margin-bottom: 10px;
}
.rem_plist1 dl dt img {
  width: 80%;
}
.rem_plist1 dl dd {
  font-size: 1.2rem;
  line-height: 1.1rem;
}
.gld_nx {
  margin-bottom: 2rem;
}
.gld_nx dt {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.gld_nx dt strong {
  font-size: 1.1rem;
}
.gld_nx dt span.orage {
  color: #ff5f00;
  font-size: 1.1rem;
  font-weight: bold;
}
.gld_nx dd img {
  width: 100%;
}
/* 기념품 질문 테이블 */
.search_area1 {
  text-align: right;
  margin-bottom: 20px;
}
.search_area1 select.select_type2 {
  padding: 0.5em 2em;
}
.search_area1 .sarch_inputbtn {
  display: inline-block;
  width: 302px;
}

.pagination {
  text-align: center;
  margin-bottom: 40px;
}
.pagination a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 0.2em;
}
.pagination a.on {
  background: #ff5f00;
  color: #fff;
  border-radius: 3px;
}

.swiper-slide-thumb-active {
  color: #ff5f00;
}
.tab_topline {
  border-top: solid 2px #eeeeee;
  padding-top: 20px;
  margin-top: 20px;
  overflow: hidden;
}
.tab_xline span {
  display: inline-block;
  width: 19%;
  text-align: center;
}
.tab_xline span.on {
  display: inline-block;
  width: 19%;
  height: 2px;
  background: #ff5f00;
  vertical-align: top;
  margin-top: -20px;
}
.m_tabs {
  border: solid 1px #eeeeee;
  background: #fafafa;
  border-radius: 3px;
}
.tabs a {
  color: #333;
}
.m_tabs a.on {
  font-weight: 700;
}
.today_money2 .titsl {
  font-size: 1.286rem;
  margin-bottom: 5%;
}
.today_money2 ul li {
  overflow: hidden;
  margin-bottom: 5%;
}
.stock_cont1 .swiper-slide {
  width: 70%;
  border: solid 1px #eeeeee;
  background: #fafafa;
  margin: 10px 0px;
  border-radius: 5px;
  padding: 5%;
}
.today_money2 .prev_day {
  overflow: hidden;
}
.today_money2 .prev_day span {
  font-size: 1.143rem;
}

.grapha1_box {
  border-radius: 3px;
  background: #fafafa;
  border: solid 1px #eeeeee;
  width: 96%;
  padding: 2% 2%;
}

.gr_selarea1 {
  overflow: hidden;
  margin-bottom: 0.7rem;
}
.gr_selarea1 select:first-child {
  float: left;
}
.gr_selarea1 select:last-child {
  float: right;
}

.graph_app {
  width: 100%;
  overflow: hidden;
}
.graph_app img {
  width: 100%;
}

.grapha1_table {
  border-top: solid 2px #eeeeee;
}
.grapha1_table table {
  width: 100%;
}
.grapha1_table table thead tr th {
  background: #fffbf9;
  border-bottom: solid 1px #eeeeee;
  color: #222;
  padding: 0.7rem 0;
  text-align: center;
}
.grapha1_table table tbody tr td {
  border-bottom: solid 1px #eeeeee;
  padding: 0.7rem 0;
  text-align: center;
  background: #fff;
}

.gold_table3 {
  border-top: solid 2px #666666;
  margin-bottom: 1rem;
}
.gold_table3 table {
  width: 100%;
}
.gold_table3 table thead th {
  padding: 20px 0px;
  font-size: 1rem;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  font-weight: bold;
}
.gold_table3 table tbody th {
  padding: 20px 0px;
  font-size: 1rem;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
  font-weight: bold;
  background: #f9f9f9;
}
.gold_table3 table tbody th.tal60 {
  padding-left: 4rem;
  text-align: left;
}
.gold_table3 table tbody th p {
  font-size: 1rem;
}
.gold_table3 table tbody td.tal10 {
  padding-left: 0.9rem;
  text-align: left;
}
.gold_table3 table tbody td div.tal10 {
  padding: 0.9rem;
  text-align: left;
  line-height: 22px;
}
.gold_table3 table tbody td {
  padding: 20px 0px;
  font-size: 1rem;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
}
.gold_table3 table tbody td input.text {
  font-size: 1rem;
  line-height: 1rem;
  width: 90%;
}
.gold_table3 table tbody td.infor dl {
  margin-bottom: 10px;
}
.gold_table3 table tbody td.infor dl dt {
  color: #666666;
}
.gold_table3 table tbody td.infor dl dd {
  color: #666666;
}
.gold_table3 table tbody td.infor div.rd_box {
  color: #232323;
}
.gold_table3 table tbody td .area_box2 {
  width: 80%;
}

.gold_table4 {
  border-top: solid 2px #000000;
}
.gold_table4 ul li {
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px #dddddd;
  padding: 0.9rem 0px;
}
.gold_table4 ul li span.tb_num1 {
  width: 10%;
  vertical-align: top;
}
.gold_table4 ul li dl {
  width: 90%;
}
.gold_table4 ul li dl dt {
  margin-bottom: 0.4rem;
}
.gold_table4 ul li dl dt > a {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  font-size: 1rem;
}
.gold_table4 ul li dl dt > h6 a {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  font-size: 1rem;
  margin-bottom: 0.4rem;
}
.gold_table4 ul li dl dt a.nupist {
  font-size: 0.8rem;
  color: #7a7f87;
}
.gold_table4 ul li dl dd {
  color: #989898;
}
.gold_table4 ul li dl dd span {
  color: #989898;
}
.gold_table4 ul li dl dd span.days {
  font-size: 0.8rem;
  display: inline-block;
  margin-right: 5px;
}
.gold_table4 ul li dl dd span.tb_admin {
  font-size: 0.8rem;
  margin-right: 5px;
}
.gold_table4 ul li dl dd span.tb_conter {
  font-size: 0.8rem;
}
.gold_table_view {
  border-top: solid 2px #666666;
  margin-bottom: 40px;
}
.gold_table_view table {
  width: 100%;
}
.gold_table_view table thead th {
  padding: 20px 20px;
  font-size: 20px;
  line-height: 20px;
  border-bottom: solid 1px #eeeeee;
  font-weight: bold;
}
.gold_table_view table tbody td .new_box {
  padding-left: 10px;
  text-align: left;
}
.gold_table_view table tbody td {
  padding: 20px 20px;
  font-size: 14px;
  border-bottom: solid 1px #eeeeee;
  text-align: center;
}

.sch_l1 {
  overflow: hidden;
  margin-bottom: 0.8rem;
}
.sch_l1 p.pslit1 {
  float: left;
  line-height: 25px;
}
.sch_l1 p.pslit1 strong {
  color: #ff5f00;
}
.sch_l1 .ash_btn {
  float: right;
  border: solid 1px #232323;
  border-radius: 3px;
  color: #232323;
  padding: 5px 15px;
  margin-right: 2%;
}
.inpt_area {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.9rem;
}
.inpt_area select {
  height: 35px;
}
.inpt_area .ipt_sch1 {
  border: solid 1px #dcdcde;
  width: 90%;
  height: 33px;
  line-height: 33px;
}
.inpt_area .zom_black {
  width: 33px;
  height: 30px;
  padding-top: 5px;
  background: #000000;
  text-align: center;
  display: table;
  vertical-align: middle;
}
.inpt_area .zom_black img {
  width: 23px;
  height: 25px;
}
.pagination {
  text-align: center;
  margin-bottom: 40px;
}
.pagination a {
  display: inline-block;
  width: 23px;
  height: 23px;
  line-height: 23px;
  margin: 0 0.2em;
}
.pagination a.on {
  background: #ff5f00;
  color: #fff;
  border-radius: 3px;
}
.pagination a img {
  width: 24px;
  height: 24px;
}

.dia_pic img {
  width: 100%;
  height: auto;
}
.sc_menu_wrap {
  width: 90%;
  overflow: hidden;
  overflow-x: scroll;
  height: 60px;
  line-height: 60px;
  padding: 0px 5%;
  border-bottom: solid 1px #eeeeee;
  margin-bottom: 1rem;
}
.sc_menu_wrap .sc_menu {
  width: 450px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
}
.sc_menu_wrap .sc_menu a {
  display: inline-block;
}
.sc_menu_wrap .sc_menu a.on {
  border-bottom: solid 1px #ff5f00;
  height: 59px;
  line-height: 59px;
  color: #ff5f00;
  font-weight: bold;
}
.sc_menu_wrap1 {
  width: 90%;
  overflow: hidden;
  overflow-x: scroll;
  height: 60px;
  line-height: 60px;
  padding: 0px 5%;
  border-bottom: solid 1px #eeeeee;
}
.sc_menu_wrap1 .sc_menu {
  width: 450px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
}
.sc_menu_wrap1 .sc_menu span {
  display: inline-block;
}
.sc_menu_wrap1 .sc_menu span.on {
  border-bottom: solid 1px #ff5f00;
  height: 59px;
  line-height: 59px;
  color: #ff5f00;
  font-weight: bold;
}
.sub_content img {
  width: 100%;
  height: auto;
}

.sort_list {
  background: #fafafa;
  padding: 0.7rem;
  margin-bottom: 1rem;
  text-align: right;
}
.sel_opcty1 {
  background: none;
}
.pic-slider-2 {
  margin-bottom: 1.3rem;
}
.det_titl1 {
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  color: #ff5f00;
  font-weight: bold;
}
.prd_detit1 {
  border-top: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
}
.prd_detit1 table {
  width: 100%;
}
.prd_detit1 table th {
  font-weight: bold;
  color: #232323;
  font-size: 1.1rem;
  padding: 0.9rem 0 0 10px;
}
.prd_detit1 table td {
  color: #232323;
  font-size: 1rem;
  padding: 0.9rem 0;
}
.prd_detit1 table td strong.gram {
  color: #ff5f00;
}

.product_list ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product_list ul li {
  width: 40%;
  padding: 0 5%;
  text-align: center;
  margin-bottom: 1.1rem;
}
.product_list ul li > a.prodt1 {
  display: block;
  padding: 4rem 1rem;
  margin-bottom: 0.9rem;
  background: #f8f8f8;
  font-size: 1.2rem;
}
.product_list ul li > a.prodt1 > img {
  width: 100%;
}
.prd_titnum {
  margin-bottom: 0.5rem;
}
.prd_titnum a.prd_titl {
  display: block;
}
.prd_titnum a.prd_won {
  display: block;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.3rem;
}

.product_list ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product_list ul li {
  width: 46%;
  padding: 0 2%;
  text-align: center;
}

.list_solt {
  border-top: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
}
.list_solt ul li {
  float: left;
  margin-left: 50px;
}
.list_solt ul li:first-child {
  margin-left: 0px;
}
.list_solt ul li a.on {
  background: url(../img/common/chk_pic2.png) left 50% no-repeat;
  padding-left: 20px;
}

.pic-slider-2 div {
  text-align: center;
}
.pic-slider-2 div img {
  width: 100%;
  height: auto;
}

.m_gold_m_number {
  background: url(../img/sub/gold_neumber_bg.png) center center no-repeat;
  width: 100%;
  height: 93px;
  margin-bottom: 20px;
  background-size: 100% 100%;
}
.m_gold_m_number dl dt {
  font-size: 16px;
  line-height: 16px;
  color: #cccccc;
  padding: 15px;
  text-align: right;
}
.m_gold_m_number dl dd {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-align: right;
  padding-right: 15px;
}

ul.m_stroe_numlist {
  overflow: hidden;
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
}
ul.m_stroe_numlist li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 210px;
  padding: 20px 10px;
  margin-bottom: 15px;
  border-top: solid 2px #232323;
  border-bottom: solid 1px #dddddd;
  border-left: solid 1px #dddddd;
  border-right: solid 1px #dddddd;
}
ul.m_stroe_numlist li img.icon {
  width: 50px;
  height: auto;
  text-align: center;
}
ul.m_stroe_numlist li dl dt {
  font-size: 14px;
  margin-bottom: 0.7rem;
  text-align: center;
}
ul.m_stroe_numlist li dl dd {
  font-size: 11px;
  color: #666666;
  text-align: center;
}
.m_store_num {
  background-color: #ff5f00;
  color: #fff;
  width: 15%;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
}

/* 회사 조직도 */
.company_cont1 {
  margin-bottom: 1.2rem;
}
.company_cont1 img {
  width: 90%;
  padding: 0 5%;
  height: auto;
}
h5.titlh5 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.9rem;
}
.comp_table1 {
  border-top: solid 2px #232323;
  margin-bottom: 1.2rem;
}
.comp_table1 table {
  width: 100%;
}
.comp_table1 table tbody th {
  background: #fafafa;
  padding: 1rem 0;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  border-left: solid 1px #eeeeee;
}
.comp_table1 table tbody th.first,
.comp_table1 table tbody td.first {
  border-left: 0px;
}
.comp_table1 table tbody td strong {
  font-weight: bold;
  color: #555555;
}
.comp_table1 table tbody td {
  font-size: 1rem;
  color: #9b9b9b;
  text-align: left;
  padding: 1rem 0 1rem 0.9rem;
  border-bottom: solid 1px #eeeeee;
  border-left: solid 1px #eeeeee;
}

/* 금거래 계좌 */
.bank_opening {
  border: solid 1px #e5e5e5;
  width: 80%;
  padding: 5% 5%;
  margin: 0 5% 10% 5%;
}
.bank_list > li {
  width: 90%;
  padding: 0% 5%;
  margin: 0 0 10% 0;
}
.bank_list > li.one_cont {
  background: #fafafa;
  width: 80%;
  padding: 5% 5%;
  margin: 0 5% 10% 5%;
}
.bank_list > li dl dt {
  color: #232323;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.bank_list > li dl dd {
  color: #454545;
  font-size: 1rem;
  line-height: 1.4rem;
}
/* .bank_list > li dl dd ul li {background:url(../img/sub/bl_dott3.png) left 7px no-repeat; padding-left:15px; margin-bottom:1rem;} */

/* 인사말 */
.subtitl1 {
  font-weight: bold;
  line-height: 1.3rem;
  font-size: 1.3rem;
  color: #272828;
  margin-bottom: 1.5rem;
}
.subtitl1_1 {
  border-radius: 20px;
  background: #ff5f00;
  color: #fff;
  font-size: 18px;
  line-height: 48px;
  display: inline-block;
  height: 48px;
  padding: 0 20px;
  margin-bottom: 30px;
}
.subtitl1_2 {
  border-radius: 20px;
  background: #2282f4;
  color: #fff;
  font-size: 18px;
  line-height: 48px;
  display: inline-block;
  height: 48px;
  padding: 0 20px;
  margin-bottom: 30px;
}
.work_mess {
  margin: 1.4rem 0px;
}
.work_mess img {
  width: 100%;
  height: auto;
}
.m_step_box {
  width: 100%;
  display: flex;
  flex-flow: wrap row;
}
ul.m_step_box1 {
  width: 100%;
}
ul.m_step_box1 li {
  width: 100%;
  box-shadow: 0 0 10px #cbcbcb;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  margin-bottom: 10px;
}
ul.m_step_box1 li:last-child {
  margin-bottom: 0px;
}
ul.m_step_box1 li dl dt {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.m_org_line {
  width: 45%;
}
.m_org_line img {
  vertical-align: middle;
}
dl.m_ceo_bou dt {
  margin-bottom: 25px;
}
dl.m_ceo_bou dt img {
  width: 100%;
}
dl.m_ceo_bou dd strong {
  color: #ff5f00;
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
}
dl.m_ceo_bou dd p.mb30 {
  margin-bottom: 10px;
}
.m_step_pic {
  width: 45%;
}
.m_step_pic img {
  width: 100%;
}

/* 사업소개 */
.m_busi_cont2 {
  margin: 10% 0px;
}
.m_busi_cont2 img {
  width: 90%;
  margin: 0 5%;
}
.m_busi_p1 {
  width: 100%;
  padding: 10% 5%;
  background: #fff8f5;
  font-size: 1.1rem;
  text-align: center;
}
ul.busi_list1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
ul.busi_list1 li:first-child {
  border-left: 0px;
}
ul.busi_list1 li {
  width: 50%;
  border-left: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1.2rem;
  text-align: center;
}
ul.busi_list1 li dl dt {
  margin-bottom: 1rem;
}
ul.busi_list1 li dl dd span {
  display: block;
  line-height: 18px;
}

/* 오시는길 테이블 */
h6.mtitl1 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 10px 0px;
}
.m_mem_inpt1 {
  border-top: solid 3px #000000;
}
.m_mem_inpt1 table {
  width: 100%;
}
.m_mem_inpt1 table tbody tr th {
  padding: 20px 0px;
  text-align: left;
  font-weight: bold;
  border-bottom: solid 1px #dddddd;
}
.m_mem_inpt1 table tbody tr td {
  padding: 20px 0px;
  border-bottom: solid 1px #dddddd;
}
.naver_map img {
  width: 96%;
  padding: 0 2%;
  height: 250px;
}

/* 사업연혁 탭 */
ul.m_history_tab4 {
  margin-bottom: 1.2rem;
  position: relative;
}
ul.m_history_tab4 li {
  float: left;
  width: 100px;
  padding: 0.7rem 0;
  background: #f5f5f5;
  border: solid 1px #eeeeee;
}
ul.m_history_tab4 li a {
  display: block;
  text-align: center;
  font-size: 1rem;
}
ul.m_history_tab4 li.on a {
  font-weight: bold;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
}
ul.m_history_tab4 .slick-current.slick-active {
  color: #000;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
  outline: none;
}
ul.m_history_tab4 .slick-prev,
ul.history_tab4 .slick-next {
  top: 145%;
}
/* .history_cont {background:url(/m/img/sub/hline.png) 30px top repeat-y;} */
.m_history_cont dl {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}
.m_history_cont dl:last-child {
  border: 0px;
}
.m_history_right {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.m_history_right dt {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}
.m_history_right dd {
  color: #404040;
  font-size: 1rem;
  line-height: 18px;
}
.m_history_left {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.m_history_left dt {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}
.m_history_left dd {
  color: #404040;
  font-size: 1rem;
  line-height: 18px;
}
.m_history_left dd span.ldot {
  width: 4px;
  height: 4px;
  background: url(../img/sub/bl_dott2.png) center center no-repeat;
  padding-left: 10px;
}
.m_history_right dd span.ldot {
  width: 4px;
  height: 4px;
  background: url(../img/sub/bl_dott2.png) center center no-repeat;
  padding-left: 10px;
}

/* 브랜드 소개 */
.m_tab2s_abrec {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  margin-bottom: 20px;
}
.m_tab_buttons {
  margin-bottom: 30px;
  width: 100%;
}
.m_tab_buttons .m_active {
  border-bottom: solid 2px #ff5f00;
}
.m_tab_buttons button {
  width: 25%;
  border: 0px;
  float: left;
}
.m_tab_buttons .m_active.int_img1 {
  background: url(../img/sub/introduce_tab1_on.png) 50% no-repeat;
  background-size: 60%;
}
.m_tab_buttons .m_int_img1 {
  background: url(../img/sub/introduce_tab1_off.png) 50% no-repeat;
  background-size: 60%;
}
.m_tab_buttons .m_active.int_img2 {
  background: url(../img/sub/introduce_tab2_on.png) 50% no-repeat;
  background-size: 60%;
}
.m_tab_buttons .m_int_img2 {
  background: url(../img/sub/introduce_tab2_off.png) 50% no-repeat;
  background-size: 60%;
}
.m_tab_buttons .m_active.int_img3 {
  background: url(../img/sub/introduce_tab3_on.png) 50% no-repeat;
  background-size: 60%;
}
.m_tab_buttons .m_int_img3 {
  background: url(../img/sub/introduce_tab3_off.png) 50% no-repeat;
  background-size: 60%;
}
.m_tab_buttons .m_active.int_img4 {
  background: url(../img/sub/introduce_tab4_on.png) 50% no-repeat;
  background-size: 60%;
}
.m_tab_buttons .m_int_img4 {
  background: url(../img/sub/introduce_tab4_off.png) 50% no-repeat;
  background-size: 60%;
}
.pic-slider-1 img {
  width: 100%;
  height: 262px;
}
/* === 브랜드 소개 ==== */

ul.m_prd_box2 {
  padding: 30px 15px;
}
ul.m_prd_box2:after {
  content: "";
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  clear: both;
  overflow: hidden;
}
ul.m_prd_box2 li:last-child h4 {
  font-size: 1.3rem;
  line-height: 1.3rem;
  color: #ff5f00;
  margin-bottom: 10px;
}
ul.m_prd_box2 li:last-child .prd_detit1 {
  border-top: solid 3px #ff5f00;
}
ul.m_prd_box2 li:first-child h6.prd_tits2 {
  font-size: 14px;
  line-height: 1.3rem;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
}
ul.m_prd_box2 li:first-child p.prd_tits1 {
  line-height: 1rem;
  margin-bottom: 10px;
  text-align: center;
}
ul.m_prd_box2 li:last-child p.prd_s1 {
  line-height: 1.3rem;
  margin: 15px 0 10px;
}
ul.m_prd_box2 li:last-child p.prd_s2 {
  line-height: 1.3rem;
}
.tab1s {
  display: none;
}
.tab1s.active {
  display: block;
}
.tab2s {
  display: none;
}
.tab2s.active {
  display: block;
}
.tab.active {
  display: block;
}
.busi_p1 {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.busi_cont1 {
  text-align: center;
}
/* .chart_p1 {text-align:center;background:url(/m/img/sub/chart_cont2.png) left top no-repeat; height:110px; padding-top:50px; color:#fff; font-size:20px; line-height:28px;} */
.garyline {
  border-bottom: solid 2px #999999;
  height: 2px;
}
.m_tab_buttons.bhistory.int_img button {
  height: 50px;
  padding: 0;
}
.prd_box2 li img {
  width: 100%;
}

/* 유가증권 */
ul.gold_tab1 {
  overflow: hidden;
  margin-bottom: 1.2rem;
}
ul.gold_tab1 li {
  float: left;
  width: 49%;
  background: #fafafa;
  border: solid 1px #eeeeee;
}
ul.gold_tab1 li a {
  display: block;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.2rem;
}
ul.gold_tab1 li.on a {
  font-weight: bold;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
}
ul.gold_tab1 li a:hover {
  font-weight: bold;
  color: #ff5f00;
  border: solid 1px #ff5f00;
  background: #fff;
}
dl.stock_explan1 {
  margin-bottom: 1.4rem;
}
dl.stock_explan1 dt {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
  color: #272828;
  text-align: center;
}
dl.stock_explan1 dd {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #221815;
  text-align: center;
}
dl.stock_explan1_2 {
  margin-bottom: 1.4rem;
}
dl.stock_explan1_2 dt {
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
  color: #272828;
}
dl.stock_explan1_2 dd {
  height: 210px;
  font-size: 1.1rem;
  line-height: 22px;
  color: #221815;
}
.stock_pay img {
  width: 100%;
  height: auto;
}
ul.stock_explan2_1 {
  overflow: hidden;
  border-bottom: solid 1px #e5e5e5;
  border-top: solid 1px #e5e5e5;
  border-left: solid 1px #e5e5e5;
  border-right: solid 1px #e5e5e5;
  display: flex;
  justify-content: space-between;
}
ul.stock_explan2_1 li {
  text-align: center;
  padding-bottom: 1.4rem;
  padding-top: 1.4rem;
  width: 50%;
  border-right: solid 1px #e5e5e5;
}
ul.stock_explan2_1 li dl {
  margin-top: 1.3rem;
}
ul.stock_explan2_1 li dl dt {
  margin-bottom: 1.2rem;
}
ul.stock_explan2_1 li p {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: bold;
  color: #444444;
}
ul.stock_explan2_2 {
  overflow: hidden;
  border-bottom: solid 1px #e5e5e5;
  border-left: solid 1px #e5e5e5;
  border-right: solid 1px #e5e5e5;
  display: flex;
  justify-content: space-between;
}
ul.stock_explan2_2 li {
  text-align: center;
  width: 50%;
  border-right: solid 1px #e5e5e5;
  padding-bottom: 1.4rem;
  padding-top: 1.4rem;
}
ul.stock_explan2_2 li dl {
  margin-top: 1.3rem;
}
ul.stock_explan2_2 li dl dt {
  margin-bottom: 1.2rem;
}
ul.stock_explan2_2 li p {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: bold;
  color: #444444;
}
ul.stock_explan2_2 li span {
  display: block;
}
.graylines {
  height: 2px;
  background: #eeeeee;
  margin: 1.4rem 0;
}
.subtitl1_cet {
  text-align: center;
  font-weight: bold;
  line-height: 1.3rem;
  font-size: 1.3rem;
  color: #272828;
  margin-bottom: 1.5rem;
}
.subtitl1_cet_1 {
  border-radius: 20px;
  background: #ff5f00;
  color: #fff;
  font-size: 18px;
  line-height: 48px;
  display: inline-block;
  height: 48px;
  padding: 0 20px;
  margin-bottom: 30px;
}
.subtitl1_cet_2 {
  border-radius: 20px;
  background: #2282f4;
  color: #fff;
  font-size: 18px;
  line-height: 48px;
  display: inline-block;
  height: 48px;
  padding: 0 20px;
  margin-bottom: 30px;
}

ul.stock_explan3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
ul.stock_explan3 li {
  width: 50%;
  text-align: center;
  margin-bottom: 1rem;
}
ul.stock_explan3 li p {
  font-size: 1.2rem;
  margin-top: 1.2rem;
  font-weight: bold;
}

.stocks_cont1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
}
.stocks_cont1 li {
  position: relative;
  height: 182px;
  text-align: center;
}
.stocks_cont1 li p {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 0.9rem;
}
.stocks_cont1 li img {
  width: 100px;
  height: auto;
}
.stocks_cont1 li.stocak_d1 {
  width: 33%;
}
.stocks_cont1 li.stocak_d2 {
  width: 33%;
}
.stocks_cont1 li.stocak_d3 {
  width: 33%;
}
.stocks_cont1 li.stocak_d4 {
  width: 49%;
}
.stocks_cont1 li.stocak_d5 {
  width: 49%;
}

.stock_nlist1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.stock_nlist1 li {
  width: 30%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fff8f4;
  text-align: center;
  border-radius: 5px;
  padding: 10px 0px;
  margin-bottom: 1.2rem;
}
.stock_nlist1 li span {
  background: #e76e27;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.stock_nlist1 li p {
  font-size: 0.8rem;
  margin-top: 0.9rem;
}

.stock_explans5 img {
  width: 100%;
  height: auto;
}

.stock_exbox {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
ul.stock_explan6 {
  width: 600px;
}
ul.stock_explan6 li {
  float: left;
  width: 200px;
}
ul.stock_explan6 li img {
  width: 180px;
}
ul.stock_explan6 li p {
  color: #000000;
  text-align: center;
}
ul.stock_explan6 li strong {
  color: #dd6c2a;
}

.gray_bg1 {
  margin-top: 1.2rem;
  background: #fafafa;
  padding: 1rem 5%;
  width: 84%;
  margin-left: 3%;
  margin-right: 3%;
}
.gray_bg2 {
  margin-top: 1.2rem;
  background: #fafafa;
  padding: 1rem 5%;
  width: 84%;
  margin-left: 3%;
  margin-right: 3%;
}
.gray_bg1 p,
.gray_bg2 p {
  color: #221815;
}
.stock_careful1 p {
  color: #666;
}
.stock_careful1 strong {
  color: #e83f1e;
}

.iduser1 {
  font-size: 1.714rem;
  color: #dd6c2a;
  font-weight: normal;
}

.all_lists1 {
  margin-bottom: 10px;
}

.push_onoff {
  color: #546275;
  margin-bottom: 0.7rem;
  overflow: hidden;
}
.push_onoff .push_btn1 {
  float: right;
  margin-right: 10px;
}
.push_onoff .all_lists1 {
  float: left;
  margin-left: 10px;
}

.infor_check {
  margin: 1rem 0px;
}

/* 로그인 페이지 */
.login_contents {
  width: 100%;
  padding-top: 2.5rem;
}
.login_contents .logn_titl1 {
  margin-bottom: 1.6rem;
}
.login_contents .logn_titl1 h4 {
  margin-bottom: 1.7rem;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  text-align: center;
}
.login_contents .logn_titl1 p.login_p1 span {
  display: block;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.1rem;
}
.login_contents .logn_titl1 p {
  display: block;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.1rem;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.login_contents .login_inpbox p {
  padding-left: 5%;
  padding-right: 5%;
  width: 90%;
}
.login_contents .login_inpbox p.login_inpt1 {
  margin-bottom: 0.7rem;
}
.login_contents .login_inpbox p input {
  border: solid 1px #ddd;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 0.8rem;
  padding-left: 5px;
}
.login_contents .login_inpbox p input:focus {
  border: solid 1px #000;
  background: #fbfbfb;
}
.pass_inpt1 {
  margin-bottom: 1rem;
}
.chk_id {
  margin-bottom: 1.8rem;
  font-size: 1.1rem;
  color: #232323;
  margin-left: 5%;
}
.login_btn1 {
  display: block;
  width: 100%;
  padding: 1.1rem 0px;
  color: #fff;
  margin-bottom: 2.1rem;
  border-radius: 5px;
  background: #2a2a2a;
  text-align: center;
  font-size: 1rem;
}
.login_btns1 {
  display: block;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 1.1rem 0px;
  color: #fff;
  margin-bottom: 2.1rem;
  border-radius: 5px;
  background: #2a2a2a;
  text-align: center;
  font-size: 1rem;
}
.member_btnlist {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
}
.member_btnlist p {
  display: flex;
  justify-content: space-between;
  border: solid 1px #707070;
  width: 50%;
  height: 35px;
  position: relative;
}
/* .member_btnlist p:after {position:absolute; top:10px; right:5px; content:''; background:url(../img/common/lgin_arrow.jpg) left 50% no-repeat; width:7px; height:11px;} */
.member_btnlist p a {
  padding-left: 0.3rem;
  height: 35px;
  line-height: 35px;
  display: block;
  font-size: 1.1rem;
}
.member_btnlist p.id_search {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.cael {
  color: #ff7713;
  margin-bottom: 5px;
}

.mem_id {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  padding-bottom: 1.4rem;
  margin-bottom: 2.1rem;
  border-bottom: solid 3px #000000;
}
.mem_sch1 {
  padding-top: 50px;
  padding-bottom: 3.5rem;
  background: #f5f5f5;
}
.mem_sch1 dl dt {
  text-align: center;
}
.mem_sch1 dl dd {
  text-align: center;
  width: 190px;
  font-size: 1.1rem;
  line-height: 1.7rem;
  margin: 1.4rem auto;
}
.mem_sch1 dl dd span {
  display: block;
}
.mem_sch1 dl dd strong {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #333333;
}
.sch_list {
  margin-top: 2.1rem;
  padding-top: 2.1rem;
  border-top: solid 1px #dddddd;
}

.login_scharea {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.login_scharea button {
  text-align: center;
  display: block;
  width: 48%;
  margin: 0 2%;
  color: #999999;
  border-bottom: solid 3px #b2b2b2;
  padding: 0.9rem 0px;
  outline: 0px;
  background: #fff;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}
.login_scharea button.active {
  font-weight: bold;
  color: #232323;
  border-bottom: solid 3px #232323;
}
.login_contents .tabsub1 .tabssl1 {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}
.login_contents .tabsub1 .tabssl1 {
  display: none;
}
.login_contents .tabsub1 .tabssl1.active {
  display: block;
}
.login_contents .tabsub1 .tabssl1.active input {
  height: 30px;
  line-height: 30px;
  font-size: 0.8rem;
  padding-left: 1%;
  width: 96%;
}
.login_contents .tabsub1 .tabssl1 p input {
  width: 98%;
  padding: 0px;
  padding-left: 2%;
}
.login_contents .tabsub1 .tabssl1 p.name_inpt1 {
  margin-bottom: 10px;
}
.login_contents .tabsub1 .tabssl1 p.mail_inpt1 input {
  margin-right: 0.3rem;
}
.login_contents .tabsub1 .tabssl1 p.mail_inpt1 select.select_type3 {
  min-width: 99px;
}
.login_contents .tabsub1 .tabssl1 .name_inpt2 {
  margin-bottom: 10px;
}
.login_contents .tabsub1 .tabssl1 p.mail_inpt2 {
  margin-bottom: 1rem;
}
.login_contents .tabsub1 .tabssl1 p.mail_inpt1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.name_inpt2 {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.name_inpt2 input.input_type03 {
  width: 98%;
  padding: 0px;
  padding-left: 2%;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}
.name_inpt3 {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}
.name_inpt3 input.input_type03 {
  width: 95%;
  padding: 0px;
  padding-left: 5%;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

.pop_area {
  background: #fff;
  padding-bottom: 1rem;
}
.pop_line {
  width: 90%;
  padding: 0 5%;
  margin: 5% auto;
  padding-bottom: 0.9rem;
  border-bottom: solid 1px #ececec;
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: bold;
}
dl.pw_box1 {
  overflow: hidden;
  width: 90%;
  padding: 0 5%;
  margin: 0 auto;
}
dl.pw_box1 dd input {
  width: 100%;
  padding: 0px;
  height: 30px;
  line-height: 30px;
}
dl.pw_box1.mb20 {
  margin-bottom: 1.4rem;
}
dl.pw_box1 dt {
  font-size: 1.1rem;
  line-height: 1.8rem;
  text-align: left;
}
dl.pw_box1 dd {
  width: 100%;
}
.btn-layerClose1 {
  position: absolute;
  top: 0px;
  right: 0px;
}
.chk_num {
  text-align: right;
  margin-top: 5px;
  font-size: 0.9rem;
}
.chk_num a {
  text-decoration: underline;
  display: inline-block;
}

ul.rd_check1 li {
  text-align: left;
  padding-left: 1%;
  width: 100%;
  margin: 0 auto;
}
ul.rd_check1 li:first-child {
  margin-bottom: 1rem;
}
ul.rd_check1 li label {
  font-size: 1.1rem;
  line-height: 24px;
}
ul.rd_check1 li label p.br_p1 {
  font-size: 1.1rem;
  line-height: 24px;
}
.login_btn2 {
  width: 120px;
  height: 35px;
  line-height: 35px;
  background: #333333;
  color: #fff;
  border-radius: 3px;
  display: block;
  margin: 0 auto 10px auto;
}
.login_btn3 {
  width: 40%;
  height: 37px;
  line-height: 37px;
  background: #333333;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  margin: 0 auto 10px auto;
}
.login_btn4 {
  width: 40%;
  height: 35px;
  line-height: 35px;
  background: #fff;
  color: #333;
  border: solid 1px #333;
  border-radius: 3px;
  display: inline-block;
  margin: 0 auto 10px auto;
}

/* 회원가입 */

.mem_cont {
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.mem_cont dl {
  margin-bottom: 1.8rem;
}
.mem_cont dl dt {
  margin-bottom: 1rem;
}
.mem_cont dl dt h4 {
  font-size: 1.571rem;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.mem_cont dl dd {
  text-align: center;
}
.mem_cont dl dd ul {
  overflow: hidden;
}
/* .mem_cont dl dd ul li {float:left; width:30%; font-size:1rem; position:relative; background:url(../img/sub/local_off_arrow.jpg) right 4px no-repeat; padding-right:.4rem; margin-right:.4rem; letter-spacing:-1px;} */
.mem_cont dl dd ul li:last-child {
  padding-right: 0px;
  margin-right: 0px;
  background: none;
}
.mem_cont dl dd ul li:last-child.on:after {
  background: none;
}
.mem_cont dl dd ul li.on {
  color: #232323;
  background: none;
  font-weight: bold;
}
/* .mem_cont dl dd ul li.on:after {position:absolute; top:0px; right:0px; content:''; width:7px; height:17px; background:url(../img/sub/local_on_arrow.jpg) right 5px no-repeat; z-index:99;} */

.line_h4 {
  padding-bottom: 10px;
  font-size: 1.2rem;
  border-bottom: solid 3px #000000;
  font-weight: bold;
  margin-bottom: 1.8rem;
  margin-left: 5%;
  margin-right: 5%;
}
.chk_box1 {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 1.6rem;
  font-weight: bold;
  margin-left: 5%;
}
.chk_box1 span {
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: normal;
}
.chs_box1 {
  margin-bottom: 30px;
}
.chs_box1 .txt_area {
  margin-left: 5%;
  margin-right: 5%;
  width: 80%;
  padding: 20px 5%;
  overflow-y: scroll;
  height: 100px;
  border: solid 1px #cccccc;
}
.chs_box1.last .txt_area {
  height: 250px;
}
.chk_boxlist {
  overflow: hidden;
  margin-bottom: 10px;
}
.chk_boxlist .chk_titl1 {
  float: left;
  font-weight: bold;
  margin-left: 5%;
}
.chk_boxlist .all_view {
  float: right;
  margin-right: 2%;
  display: none;
}

.input_area {
  overflow: hidden;
  padding-bottom: 0.8rem;
}
.input_area h5 {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
  margin-left: 5%;
}
.input_area p.org1 {
  font-size: 1rem;
  line-height: 1rem;
  color: #ff6b13;
  margin-left: 5%;
}
.mem_inpt {
  border-top: solid 3px #000000;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 1.4rem;
}
.mem_inpt table {
  width: 100%;
}
.mem_inpt table tbody tr th {
  padding: 0.8rem 0px;
  text-align: left;
  padding-left: 5px;
  font-size: 1rem;
  font-weight: bold;
  border-bottom: solid 1px #dddddd;
}
.mem_inpt table tbody tr th span.org2 {
  color: #ff9252;
  font-size: 1.28rem;
}
.mem_inpt table tbody tr td {
  padding: 0.8rem 0px;
  border-bottom: solid 1px #dddddd;
}
.mem_inpt table tbody tr td input.input_type01 {
  height: 30px;
  line-height: 30px;
  width: 50%;
  margin: 0 0 0 10%;
}
.mem_inpt table tbody tr td input.input_type03 {
  height: 30px;
  line-height: 30px;
  width: 80%;
  margin: 0 0 0 10%;
}
.mem_inpt table tbody tr td select.select_type3 {
  padding: 0.3rem 1.8rem 0.3rem 1rem;
  margin-left: 10%;
  margin-top: 2%;
}
.mem_inpt table tbody tr.last th {
  padding: 1rem 0px;
  text-align: left;
  padding-left: 5px;
  border-bottom: solid 1px #000;
}
.mem_inpt table tbody tr:last-child td {
  padding: 1rem 0px;
  border-bottom: solid 1px #000;
}
.mem_inpt table tbody tr td .id_check1 {
  display: inline-block;
  background: #2a2a2a;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-left: 10%;
  margin-top: 2%;
}
.mem_inpt table tbody tr td .chk_wrap1 {
  margin-top: 0.7rem;
  margin-left: 10%;
}
.mem_inpt table tbody tr td .addr_btn {
  border: solid 1px #232323;
  color: #232323;
  padding: 0.5rem 1rem;
  display: inline-block;
  margin-left: 10%;
  margin-top: 2%;
}

.mem_clear {
  border-top: solid 3px #000000;
  border-bottom: solid 1px #dddddd;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  margin-bottom: 2.1rem;
  margin-left: 5%;
  margin-right: 5%;
}
.mem_clear h5 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 1.4rem;
  font-weight: bold;
}
.mem_clear dl.mem_end {
  margin-bottom: 0px;
}
.mem_clear dl dt {
  text-align: center;
}
.mem_clear dl dd p {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
}
.mem_clear dl dd strong {
  font-size: 1.2rem;
  line-height: 2rem;
}

.member_btnlist p a {
  width: 100%;
}
